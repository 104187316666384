/* Компонент раздела "Финансы" мобильной версии */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IAbonement } from '../../interfaces/abonement';
import {
    IPay,
    IPayment,
    IPaySystem,
    IPaySystemInfo,
    IServicePayment,
} from '../../interfaces/payment.interface';
import { IPatient } from '../../interfaces/patient';
import { AbonementService } from '../../services/abonement.service';
import { PaymentsService } from '../../services/payments.service';
import { PatientService } from '../../services/patient.service';
import { AlertService } from 'src/lib/ariadna/alert';

import { LoadService } from '../../services/application/load.service';
import { Title } from '@angular/platform-browser';
import { IPeriod } from 'src/app/interfaces/period.interface';
import * as moment from 'moment';
import {
    onIfAnimation,
    topButtonAnimation,
} from '../../animations/show-left-right.animation';
import {
    expandedAnimation,
    heightAnimation,
} from '../../animations/height.animation';
import {
    getStrEnd,
    getTekDay,
    getNameDay,
    getTime,
    strToDate,
    getDayWithYear,
} from '../application/global.function';
import { MatDialog } from '@angular/material/dialog';
import { PayDetailsDialogComponent } from './dialogs/pays-details-dialog/pay-details-dialog.component';
import { PayPeriodDialogComponent } from './dialogs/pay-period-dialog/pay-period-dialog.component';
import { IDateRange } from 'src/app/interfaces/date-range.interface';
import { RefundComponent } from 'src/app/modals/refund/refund.component';
import { RecordService } from 'src/app/services/record.service';
import { lastValueFrom } from 'rxjs';
import { Store } from 'src/app/services/store.service';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss'],
    animations: [
        topButtonAnimation,
        heightAnimation,
        expandedAnimation,
        onIfAnimation,
    ],
})
export class PaymentsComponent implements OnInit {
    @ViewChild('inputFocus') inputFocusRef!: ElementRef;
    @ViewChild('inputFocus2') inputFocusRef2!: ElementRef;
    @ViewChild('mymyselect') mymyselect!: any;
    animState = 'on';
    animStateAbonement = 'on';
    payAvansAnimeStatus = 0; // закрыта форма оплаты баланса
    payAbonementAnimeStatus = 0; // закрыта форма оплаты Абонемента
    dtBegin: Date | null = null; // Дата начала
    dtEnd: Date | null = null; // Дата ококнчания
    dtBegins: Date | null = null;
    dtEnds: Date | null = null;
    balans: number = 0; // Текущий баланс
    loadingPayAvans = false; // Оплата аванса
    isLoading = false;
    //  paymentsList: IService[] | null = null; // Список платежей
    abonList: IAbonement[] | null = null; // Список абонементов
    paymentsList: IPayment[] | null = null; // Список платежей
    animateStateServices: string[] = []; // Статус анимации для услуг
    mdAbonPay!: IPay; // Оплата абонемента
    mdBalansPay!: IPay; // Оплата баланса
    private _patient!: IPatient;
    paymentsListLength: number = 0; // Сколько всего записей
    countRecToPage = 15; // Количетсво записей на странице mobile
    paySystem: IPaySystem | null = null; // Тип платежной системы
    paySystemField: string = '';
    private _setting: any;
    date!: IDateRange;
    //новые переменные
    periodText = '';
    loadingMoreContent = false;
    // orderUP = true; // С начала последние
    // orderUPText: string = 'Сначала последние';
    payPages: Array<IPayment[]> = [];
    countOfPages = 0;
    loadedPages = 0;
    viewPage = 1;
    servicesListLength: number = 0;
    showMyContainer: boolean = false;
    showMyPay: boolean = false;
    //значения для запроса getPayments
    // pStart: number = 1;
    // pEnd: number = this.countRecToPage;
    dt_begin = this.dtBegin;
    dt_end = this.dtEnd;
    //scroll и меню шапки        private rs: RecordService,
    visibill: boolean = false;
    scrolled: boolean = false;

    priceMapper = {
        '=0': 'рублей',
        '=1': 'рубль',
        '=2': 'рубля',
        other: 'рублей',
    };
    servicesMapper = {
        '=0': 'услуг',
        '=1': 'услуга',
        '=2': 'услуги',
        other: 'услуг',
    };
    //pc version
    page = 1;
    loadingPaymentsList = false; // Загрузка
    countRectoPage = 5; // Количетсво записей на странице
    isShowAbonement = false;
    isShowAvans = false;
    WEB_LK_FIN_BALANS_TXT = 'Баланс';
    // showBalansAndAbon = true; // Показывать баланс и абонементы
    showBalance: boolean = false;
    showAbonement: boolean = false;
    showDeposit: boolean = false;
    /* Инициализация компонента */
    constructor(
        private abonServices: AbonementService,
        private paymentsService: PaymentsService,
        private patientService: PatientService,
        private alert: AlertService,
        private load: LoadService,
        private dialog: MatDialog,
        private title: Title,
        private rs: RecordService
    ) {
        this._setting = this.load.configData.pages.payments;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }
        this.date = { dt_begin: new Date(), dt_end: new Date() };
        if (this._setting.WEB_LK_FIN_BALANS_TXT) {
            this.WEB_LK_FIN_BALANS_TXT = this._setting.WEB_LK_FIN_BALANS_TXT;
        }

        if (this.load.configData.pages.payments.WEB_LK_FIN_PAY)
            this.showBalance = true;

        this.showAbonement = Boolean(this.load.configData.pages.showSostav);
        this.showDeposit = Boolean(this.load.configData.pages.Pay.showbalans);
    }

    /** Действия при инициализации компонента */
    ngOnInit(): void {
        moment.locale('ru');
        this.initFrm(); // Инициализация формы
        this.paymentsService.getBalanse().subscribe(
            (bal) => {
                if (bal && bal.amount) {
                    this.balans = Number(bal.amount);
                }
            },
            (error) => console.error(error)
        );
        const token = Store.getTokenFromCookie();
        Store.getDataString('ParentCookieName')
            ? this.getPatientInfo(true, token!)
            : this.getPatientInfo();

        this.getAbonList(); // Получить список Абонементов
        this.date.dt_begin?.setDate(this.date.dt_begin.getDate() - 365);

        this.isLoading = true;
        this.changePeriod({
            dt_begin: this.date.dt_begin,
            dt_end: this.date.dt_end,
        }).then(() => (this.isLoading = false));

        this.formatDateToString(this.date);

        this.dtBegins = new Date();
        this.dtBegins.setDate(this.dtBegins.getDate() - 365);
        this.dtEnds = new Date();
        this.initData();
    }
    getPatientInfo(isFamilyAccount = false, tokenValue?: string) {
        const result = lastValueFrom(
            this.patientService.getServerPatientInfo$(
                tokenValue,
                isFamilyAccount
            )
        );
        result
            .then((info) => {
                // console.log('getPatientInfo', info);
                this._patient = info;
                this.mdAbonPay.email = this._patient.email || '';
                this.mdBalansPay.email = this._patient.email || '';
                // this.mdBalansPay.email = this._patient.email;
                this.mdAbonPay.phone = this.numberOnlyReplace(
                    this._patient.phone || this._patient.cellular || ''
                ).toString();
                this.mdBalansPay.phone = this.numberOnlyReplace(
                    this._patient.phone || this._patient.cellular || ''
                ).toString();
            })
            .catch((error) => console.error('getPatientInfo', error));
    }

    /** Получение типа платежной системы (телефон или почта) */
    async getPaySystem() {
        this.paySystem = null;
        this.paySystemField = '';

        try {
            const info = await lastValueFrom(
                this.paymentsService.getPaySystem()
            );
            this.paySystem = info.paysystems[0];
            this.paySystemField = this.paySystem.requiredfieldcodes[0];

            switch (this.paySystemField) {
                case 'phone': {
                    this.mdBalansPay.email = null;
                    this.mdAbonPay.email = null;
                    break;
                }
                case 'email': {
                    this.mdBalansPay.phone = null;
                    this.mdAbonPay.phone = null;
                    break;
                }
            }
        } catch (error) {
            this.alert.error('Ошибка во время определения платёжной системы');
        }
    }

    //computed boolean function для возвратов
    isNotRefundPayment(payInfo: IPayment): boolean {
        return (payInfo.all_amount || 1) > 0;
    }

    /** получить список абонементов (активные)*/
    getAbonList() {
        this.abonList = [];
        this.abonServices.getAbonementList({ type: 1 }).subscribe(
            (res) => {
                if (res && res.length > 0) {
                    this.abonList = res;
                }
            },
            (err) => {
                console.error('get Abon list error:', err);
            }
        );
    }

    /** Показ блока с платёжной информацией */
    showPay() {
        if (this.animState === 'on') {
            this.animState = 'off';
            this.animStateAbonement = 'on';
        } else {
            this.animState = 'on';
        }
    }

    /** Оплата, абонемент toggle */
    ShowTogglePopup(): void {
        this.showMyContainer = !this.showMyContainer;
        this.animStateAbonement = 'on';
        if (this.showMyPay === false) {
            setTimeout(() => {
                this.mymyselect.open();
            }, 0);
        }
    }

    /** Скрытие/показ платёжного попапа */
    ShowTogglePopupPay(): void {
        this.showMyContainer = !this.showMyContainer;

        if (this.showMyContainer === true) {
            setTimeout(() => {
                this.inputFocusRef.nativeElement.focus();
            }, 0);
        }
    }
    /** Показ оплаты по абонементу */
    showPayAbonement() {
        if (this.animStateAbonement === 'on') {
            this.animStateAbonement = 'off';
            this.animState = 'on';
        } else {
            this.animStateAbonement = 'on';
        }
    }

    /** Завершение анимации показа оплаты по абонементу */
    whenAnimateAbonement(event: any) {
        if (this.animStateAbonement === 'off') {
            this.payAbonementAnimeStatus = 1;
        } else {
            this.payAbonementAnimeStatus = 0;
        }
    }

    /** Завершение анимации показа оплаты авансом */
    whenAnimateAvans(event: any) {
        if (this.animState === 'off') {
            this.payAvansAnimeStatus = 1;
        } else {
            this.payAvansAnimeStatus = 0;
        }
    }

    /** Инициаоизация формы оплаты */
    private initFrm() {
        this.mdAbonPay = { sum: null, phone: '', email: '', abon_id: null };
        this.mdBalansPay = { sum: null, phone: '', email: '', abon_id: null };
    }

    /** Оплатить */
    toPayPc(pay: IPayment) {
        if (pay.confirmation_url && pay.confirmation_url.length > 5) {
            window.open(pay.confirmation_url);
        }
    }

    /** Возможна ли оплата */
    isPayPc(pay: IPayment): boolean {
        if (
            pay.confirmation_url &&
            pay.confirmation_url.length > 5 &&
            pay.paystatus == 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    /** Выбран абонемент */
    changeAbonPc(e: any) {
        if (this.animStateAbonement == 'on') {
            this.showPayAbonement();
        }
        this.mdAbonPay.sum = null;
        this.mdAbonPay.abon_id = null;

        this.abonList?.forEach((item) => {
            if (item.id === e.value) {
                if (item && item.plan_amount && item.plan_amount > 1) {
                    this.mdAbonPay.sum = Math.round(item.plan_amount); // Только целое число
                }
                this.mdAbonPay.abon_id = item.id;
            }
        });

        if (this.showMyContainer === true) {
            setTimeout(() => {
                this.inputFocusRef2.nativeElement.focus();
            }, 0);
        }
    }

    /* Оплата */
    async goPayPc(p: IPay) {
        this.loadingPayAvans = true;
        try {
            const pay = await lastValueFrom(
                this.paymentsService.getPayTemp(
                    22,
                    p.sum,
                    p.email,
                    p.phone,
                    null
                )
            );
            if (pay.identity) {
                const paymentOrder = await lastValueFrom(
                    this.paymentsService.getPayOrder(pay.identity)
                );
                if (paymentOrder.confirmationurl) {
                    window.location.href = paymentOrder.confirmationurl;
                } else {
                    throw new Error('Отсутствует confirmationurl');
                }
            } else {
                throw new Error('Отсутствует identity');
            }
        } catch (error) {
            this.alert.warn(
                'Ошибка создания платежа в платежной системе. Проверьте данные и попробуйте снова.',
                10000
            );
        } finally {
            this.loadingPayAvans = false;
        }
    }

    // Todo: Убрать эти анимации. Или просто заменить их на более короткие.
    /** Показать/скрыть услуги */
    public showHideListservicesPc(index: number) {
        if (this.animateStateServices) {
            if (this.animateStateServices[index] === 'off') {
                this.animateStateServices[index] = 'on';
            } else {
                this.animateStateServices[index] = 'off';
            }
        }
    }

    /** Нажатие Enter на поле суммы оплаты по абонементу */
    canselFocused(abon: any) {
        this.inputFocusRef2.nativeElement.blur();
        this.goPay(abon);
    }
    /** Нажатие Enter на поле суммы оплаты по абонементу */
    canselFocused2(abon: any) {
        this.inputFocusRef.nativeElement.blur();
        this.goPay(abon);
    }

    /** Получение количества услуг */
    async getServicesSize() {
        this.paymentsList = null;
        this.loadingMoreContent = true;

        try {
            const res = await lastValueFrom(
                this.paymentsService.getPaymentsSize(
                    this.date.dt_begin,
                    this.date.dt_end
                )
            );

            if (res.size) {
                this.servicesListLength = res.size;
            } else {
                this.servicesListLength = 0;
            }

            this.setCountOfPages();
        } catch (error) {
            this.alert.error('Ошибка при загрузке записей');
        } finally {
            // console.log('ffff');
            this.loadingMoreContent = false;
            console.log(this.loadingMoreContent);
        }
    }

    /** Изменение периода для вывода записей*/
    async changePeriod(dateRange: IDateRange) {
        this.date = dateRange;

        this.payPages = [];
        this.paymentsList;

        await this.getServicesSize();
        this.formatDateToString(this.date);
        this.periodText = this.getPeriodText(this.date);
        this.loadedPages = 0;
        this.viewPage = 1;
    }

    /** Подгрузка дополнительного кал-ва услуг */
    async loadMoreServices(pStart?: number, pEnd?: number) {
        // console.log(this.loadedPages);
        this.loadingMoreContent = true;
        let order: string;

        // if (this.orderUP) {
        //     order = 'desc';
        // } else {
        //     order = 'acs';
        // }

        try {
            // console.log(pStart, pEnd);
            const res = await lastValueFrom(
                this.paymentsService.getPayments(
                    this.date.dt_begin,
                    this.date.dt_end,
                    pStart,
                    pEnd
                    // order
                )
            );
            res.forEach((item) => {
                item.dtSort = new Date(
                    item.pt_dat_str.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')
                );
                item.dtSort = strToDate(item.pt_dat_str);
                item.pt_dat = strToDate(item.pt_dat_str);
            });

            if (res) {
                this.payPages.push(res);
            }
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка платежей');
            console.error(error);
        } finally {
            this.loadingMoreContent = false;
        }
    }

    /** Загрузка следующей страницы с услугами */
    loadNextPage() {
        // if (this.loadedPages > 1) {
        //     this.countRecToPage = 15;
        // }
        // console.log(this.loadedPages);
        this.loadedPages++;
        this.loadMoreServices(
            this.loadedPages * this.countRecToPage - this.countRecToPage + 1,
            this.loadedPages * this.countRecToPage
        );
    }

    /** Вызов диалога с деталями услуги */
    showDetailsDialog(serv: IPayment) {
        const detailsDialog = this.dialog.open(PayDetailsDialogComponent, {
            maxWidth: '100%',
            maxHeight: '100%',
            panelClass: 'payModalDialog',
            data: {
                service: serv,
            },
        });

        detailsDialog.afterClosed().subscribe((result) => {});
    }

    //надо добавить в массив и отсортировать
    /** манипуляции с датой */
    isBlockYeerPc(index: number): boolean {
        let f = false;

        if (this.paymentsList?.[index - 1]) {
            if (
                this.paymentsList[index].dtSort.getFullYear() !==
                this.paymentsList[index - 1].dtSort.getFullYear()
            ) {
                f = true;
            }
        }
        if (index === 0) {
            f = true;
        }

        return f;
    }

    /** Получение года платежа по его порядковому номеру */
    getYeerPc(index: number): string {
        if (this.paymentsList?.[index] && this.paymentsList?.[index].pt_keyid) {
            return this.paymentsList[index].dtSort.getFullYear() + '';
        } else {
            return '';
        }
    }
    /** Установка количества страниц*/
    setCountOfPages() {
        this.countOfPages = Math.ceil(
            this.servicesListLength / this.countRecToPage
        );
    }
    public getDateTimeFuul(dt: Date): string {
        let tmp = '';
        moment.locale('ru');
        tmp =
            moment(dt).format('D MMM YYYY') +
            ' в ' +
            moment(dt).format('HH:mm');
        return tmp;
    }

    /** Получение даты в формате "D MM" */
    getTekDayPc(dt: Date): string {
        return getTekDay(dt);
    }

    /** Получение дня недели */
    getNameDayPc(dt: Date): string {
        return getNameDay(dt);
    }

    /** Получение времени формата "D MM YYYY" */
    getDayWithYear(dtSort: Date) {
        return getDayWithYear(dtSort);
    }

    /** Получение времени формата "HH:mm" */
    getTimePc(dt: Date): string {
        return getTime(dt);
    }

    /** Получение периода текстом в формате "D MMM YYYY"*/
    formatDateToString(dt: IDateRange) {
        if (dt.dt_begin) {
            dt.str_begin = moment(new Date(dt.dt_begin)).format('D MMM YYYY');
        }
        if (dt.dt_end) {
            dt.str_end = moment(new Date(dt.dt_end)).format('D MMM YYYY');
        }
    }

    /** строку в число */
    public numberOnlyReplacePc(str: string): number {
        let resNum = '';
        let i = 0;
        let charCode = 0;
        for (i = 0; i < str.length; i++) {
            charCode = str.charCodeAt(i);
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode != 46
            ) {
            } else {
                resNum = resNum + str[i];
            }
        }
        return Number(resNum);
    }

    /*Для Календаря*/

    /** Вызов диалога для выбора периода */
    showPeriodDialog() {
        const periodDialog = this.dialog.open(PayPeriodDialogComponent, {
            maxWidth: '650px',
            width: '100%',
            height: '100%',
            data: {
                dateRange: {
                    dt_begin: this.date.dt_begin,
                    dt_end: this.date.dt_end,
                } as IDateRange,
            },
        });

        periodDialog.afterClosed().subscribe((result: { date: IDateRange }) => {
            if (result?.date) {
                this.isLoading = true;
                this.changePeriod(result.date).then(
                    () => (this.isLoading = false)
                );
            }
        });
    }

    /** Полученеи текста из периода */
    getPeriodText(dt: IDateRange) {
        let periodText = '';
        let isSelectedOneDay = dt.str_begin == dt.str_end;

        if (isSelectedOneDay) {
            if (dt.dt_begin == null && dt.dt_end == null)
                periodText = 'Платежи за всё время';
            else if (dt.dt_begin?.toDateString() == new Date().toDateString())
                periodText = 'Последние платежи';
            else if (dt.str_begin) periodText = dt.str_begin;
        } else if (dt.dt_begin && dt.dt_end) {
            const yearAgo = moment(dt.dt_end).subtract(1, 'year');
            if (yearAgo.isSame(dt?.dt_begin)) {
                const yearAgo = moment(dt.dt_end).subtract(1, 'year');

                periodText = `За 1 год`;
            } else {
                periodText = `${dt.str_begin} - ${dt.str_end}`;
            }
        } else if (dt.str_begin) {
            periodText = dt.str_begin;
        } else if (dt.str_end) {
            periodText = dt.str_end;
        }
        return periodText;
    }

    /**
     * Фильтрация записей по дате
     * @param {number | null} startOffset Сдвиг даты С
     * @param {number | null} endOffset Сдвиг даты ПО
     */
    btnFilterByDate(
        startOffset: number | null = 0,
        endOffset: number | null = 0
    ) {
        if (startOffset !== null && endOffset !== null) {
            this.date.dt_begin = new Date();
            this.date.dt_begin.setDate(
                this.date.dt_begin.getDate() - startOffset
            );
            this.date.dt_end = new Date();
            this.date.dt_end.setDate(this.date.dt_end.getDate() - endOffset);
            this.isLoading = true;
            this.changePeriod({
                dt_begin: this.date.dt_begin,
                dt_end: this.date.dt_end,
            }).then(() => (this.isLoading = false));
        } else {
            this.date.dt_begin = null;
            this.dt_begin = this.date.dt_begin;
            this.date.dt_end = null;
            this.dt_end = this.date.dt_end;
            this.isLoading = true;
            this.changePeriod({ dt_begin: null, dt_end: null }).then(() => {
                this.isLoading = false;
            });
        }
    }
    //pc version
    async initData() {
        try {
            this.initForm();
            const balans = await lastValueFrom(
                this.paymentsService.getBalanse()
            );

            if (balans && balans.amount) {
                this.balans = Number(balans.amount);
            }

            // const patientInfo = await lastValueFrom(
            //     this.patientService.getServerPatientInfo$()
            // );
            // this._patient = patientInfo;

            // if (this._patient.email) {
            //     this.mdAbonPay.email = this._patient.email;
            //     this.mdBalansPay.email = this._patient.email;
            // }
            // if (this._patient.phone) {
            //     this.mdAbonPay.phone = this.numberOnlyReplace(
            //         this._patient.phone
            //     ).toString();
            //     this.mdBalansPay.phone = this.numberOnlyReplace(
            //         this._patient.phone
            //     ).toString();
            // } else if (this._patient.cellular) {
            //     this.mdAbonPay.phone = this.numberOnlyReplace(
            //         this._patient.cellular
            //     ).toString();
            //     this.mdBalansPay.phone = this.numberOnlyReplace(
            //         this._patient.cellular
            //     ).toString();
            // }

            this.getPaySystem(); // Тип платежной системы
            this.getAbonList(); // Получить список Абонементов

            this.changePeriodPc({ begin: this.dtBegin, end: this.dtEnd });
        } catch (error) {
            this.alert.warn('Ошибка при загрузке страницы.');
        }
    }

    /** Получить тип платежной системы телефон почта !*/
    async getPaySystemPc() {
        this.paySystem = null;
        this.paySystemField = '';

        try {
            const info = await lastValueFrom(
                await this.paymentsService.getPaySystem()
            );

            if (info.paysystems?.length > 0) {
                this.paySystem = info.paysystems[0];
                this.paySystemField = this.paySystem.requiredfieldcodes[0];

                switch (this.paySystemField) {
                    case 'phone': {
                        this.mdBalansPay.email = null;
                        this.mdAbonPay.email = null;
                        break;
                    }
                    case 'email': {
                        this.mdBalansPay.phone = null;
                        this.mdAbonPay.phone = null;
                        break;
                    }
                }
            }
        } catch (error) {
            this.alert.warn(
                'Ошибка при получении информации о платёжной системе'
            );
        }
    }

    /** Получить список абонементов (активныx)*/
    async getAbonListPc() {
        this.abonList = [];

        try {
            const res = await lastValueFrom(
                this.abonServices.getAbonementList({ type: 1 })
            );

            if (res && res.length > 0) {
                this.abonList = res;
            }
        } catch (error) {
            this.alert.warn('Ошибка при получении списка абонементов');
        }
    }

    /** За сегодня */
    btnFiltrTek() {
        this.dtBegin = new Date();
        this.dtEnd = new Date();
        this.changePeriodPc({ begin: this.dtBegin, end: this.dtEnd });
    }

    /** За 3 дня */
    btnFiltr3Day() {
        this.dtBegin = new Date();
        this.dtBegin.setDate(this.dtBegin.getDate() - 3);
        this.dtEnd = new Date();
        this.changePeriodPc({ begin: this.dtBegin, end: this.dtEnd });
    }

    changePeriodPc(dt: IPeriod) {
        if (dt.begin !== null && dt.end !== null) {
            this.dtBegins = dt.begin;
            this.dtEnds = dt.end;
        }
        this.getPaymentsSizePC();
        this.page = 1;
    }

    /** Количество платежей */
    async getPaymentsSizePC() {
        this.paymentsList = null;
        this.loadingPaymentsList = true;
        try {
            const results = await lastValueFrom(
                this.paymentsService.getPaymentsSize(this.dtBegins, this.dtEnds)
            );

            if (results.size) {
                // console.log(results.size);
                this.paymentsListLength = results.size;
                await this.getPayments(1, this.countRectoPage);
                // console.log('WHERE IS THIS SHIT');
            } else {
                this.paymentsListLength = 0;
                this.loadingPaymentsList = false;
                // await this.getPayments();
            }
        } catch (error) {
            this.alert.warn('Ошибка при получении количества записей');
        }
    }

    /** Список платежей */
    async getPayments(pStart?: number, pEnd?: number) {
        this.paymentsList = null;
        this.animateStateServices = [];
        // this.loadingPaymentsList = true;

        try {
            const res = await lastValueFrom(
                this.paymentsService.getPayments(
                    this.dtBegins,
                    this.dtEnds,
                    pStart,
                    pEnd
                )
            );

            res.forEach((item) => {
                item.dtSort = strToDate(item.pt_dat_str);
                item.pt_dat = strToDate(item.pt_dat_str);

                item.services.forEach((itemS) => {
                    if (itemS.dtstr && itemS.dtstr.length > 10) {
                        itemS.dt = strToDate(itemS.dtstr);
                    }
                });
            });

            this.paymentsList = res;
            this.animateStateServices.push('off');
            // console.log('subscribe xyeta');
        } catch (error) {
            this.alert.warn('Ошибка при получении списка платежей');
        } finally {
            this.loadingPaymentsList = false;
            // this.loadingMoreContent = false;
            // this.isLoading = false;
        }
    }

    isBlockYeer(index: number): boolean {
        return (
            (this.paymentsList &&
                this.paymentsList[index] &&
                this.paymentsList[index - 1] &&
                this.paymentsList[index].dtSort.getFullYear() !==
                    this.paymentsList[index - 1].dtSort.getFullYear()) ||
            index === 0
        );
    }

    getYeer(index: number): string {
        if (this.paymentsList && this.paymentsList[index]) {
            return this.paymentsList[index].dtSort.getFullYear() + '';
        } else {
            return '';
        }
    }

    getFullDateTime(dt: Date): string {
        moment.locale('ru');
        return (
            moment(dt).format('D MMM YYYY') + ' в ' + moment(dt).format('HH:mm')
        );
    }

    getTekDay(dt: Date | null): string {
        return dt ? getTekDay(dt) : '';
    }

    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    getTime(dt: Date | null): string {
        return dt ? getTime(dt) : '';
    }

    showPayPc() {
        if (this.isShowAvans) {
            this.isShowAvans = false;
        } else {
            this.isShowAvans = true;
            this.isShowAbonement = false;
        }
    }

    showPayAbonementPc() {
        if (this.isShowAbonement) {
            this.isShowAbonement = false;
        } else {
            this.isShowAbonement = true;
            this.isShowAvans = false;
        }
    }

    initForm() {
        this.mdAbonPay = { sum: null, phone: '', email: '', abon_id: null };
        this.mdBalansPay = { sum: null, phone: '', email: '', abon_id: null };
    }

    actionClick(pay: IPayment) {
        if (this.isPay(pay)) {
            this.toPay(pay);
        } else if (this.isRefund(pay)) {
            this.toRefund(pay);
        }
    }

    /** Оплатить*/
    async toPay(pay: IPayment) {
        try {
            if (this.isPay(pay)) {
                if (pay.confirmation_url && pay.confirmation_url.length > 5) {
                    window.open(pay.confirmation_url);
                } else {
                    this.loadingPaymentsList = true;

                    const confirmation_url = await this.createPay(pay);

                    if (confirmation_url) {
                        window.open(confirmation_url);
                    }
                }
            }
        } catch (error) {
            this.alert.error(
                'Ошибка открытия платежа, попробуйте повторить операцию позднее.'
            );
        } finally {
            this.loadingPaymentsList = false;
        }
    }

    async createPay(pay: IPayment) {
        let confirmation_url: string | null = null;

        try {
            const newPay = await lastValueFrom(
                this.rs.getPaymentTemp(
                    pay.pt_keyid,
                    this._patient.email,
                    this._patient.phone
                )
            );

            if (newPay.identity && newPay.identity > 0) {
                const newPayOrder = await lastValueFrom(
                    this.paymentsService.getPayOrder(newPay.identity)
                );

                if (newPayOrder.confirmationurl) {
                    confirmation_url = newPayOrder.confirmationurl;
                } else {
                    throw new Error('В payOrder отсутствует confirmationurl.');
                }
            } else {
                throw new Error(
                    'С платежами что-то пошло не так. err_text: ' +
                        newPay.err_text
                );
            }
        } catch (error) {
            this.alert.error(
                'Ошибка создания платежа, попробуйте повторить операцию позднее.'
            );
        }

        return confirmation_url;
    }

    toRefund(pay: IPayment) {
        this.dialog.open(RefundComponent, {
            data: pay,
        });
    }

    /** Возможна ли оплата */
    isPay(pay: IPayment): boolean {
        return pay.paystatus == 0 && this.paySystem != null;
    }

    /** Возможен ли возврат */
    isRefund(pay: IPayment): boolean {
        return pay.paystatus == 1;
    }

    toPayAbon() {}

    /** Выбран абонемент */
    changeAbon(e: any) {
        if (this.isShowAbonement) {
            this.showPayAbonement();
        }
        this.mdAbonPay.sum = null;
        this.mdAbonPay.abon_id = null;
        this.abonList?.forEach((item) => {
            if (item.id === e.value) {
                if (item && item.plan_amount && item.plan_amount > 1) {
                    this.mdAbonPay.sum = Math.round(item.plan_amount); // Только целое число
                }
                this.mdAbonPay.abon_id = item.id;
            }
        });
    }

    /** Строку в число */
    public numberOnlyReplace(str: string): number {
        let resNum = '';
        let i = 0;
        let charCode = 0;
        for (i = 0; i < str.length; i++) {
            charCode = str.charCodeAt(i);
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode != 46
            ) {
            } else {
                resNum = resNum + str[i];
            }
        }
        return Number(resNum);
    }

    /** Оплата */
    async goPay(p: IPay) {
        this.loadingPayAvans = true;

        try {
            const info = await lastValueFrom(
                this.paymentsService.getPayTemp(
                    22,
                    p.sum,
                    p.email,
                    p.phone,
                    null
                )
            );

            if (!info.identity)
                throw new Error(
                    'Ошибка создания платежа в системе. Проверьте данные и попробуйте снова'
                );

            const payOrder = await lastValueFrom(
                await this.paymentsService.getPayOrder(info.identity)
            );

            if (!payOrder.confirmationurl) {
                throw new Error(
                    'Ошибка создания платежа в системе. Проверьте данные и попробуйте снова'
                );
            }

            window.location.href = payOrder.confirmationurl;
        } catch (error: any) {
            this.alert.warn(
                'Ошибка создания платежа в системе. Проверьте данные и попробуйте снова.',
                10000
            );
        } finally {
            this.loadingPayAvans = false;
        }
    }

    /** Событие выбора страницы */
    async changedPage(page: any) {
        this.loadingPaymentsList = true;

        if (page === 1) {
            await this.getPayments(1, this.countRectoPage);
        } else {
            await this.getPayments(
                page * this.countRectoPage - this.countRectoPage + 1,
                page * this.countRectoPage
            );
        }

        this.page = page;
    }

    showHideListservices(indexPay: number) {
        // Проверка на количество услуг
        if (
            this.paymentsList &&
            this.paymentsList[indexPay] &&
            this.paymentsList[indexPay].services.length > 1
        ) {
            this.animateStateServices.forEach((item, i_item) => {
                // Скрываем все
                if (i_item != indexPay) {
                    this.animateStateServices[indexPay] === 'off';
                } else {
                }
            });

            if (this.animateStateServices[indexPay]) {
                if (this.animateStateServices[indexPay] === 'off') {
                    this.animateStateServices[indexPay] = 'on';
                } else {
                    this.animateStateServices[indexPay] = 'off';
                }
            }
        }
    }

    getShowHideServices(indexPay: number): string {
        let def = 'off';
        def = this.animateStateServices[indexPay];
        return def;
    }

    /*    Дата услуг(и)  */
    getDTSer(d: Date | null): string {
        if (d) {
            return moment(d).format('D MMM YYYY');
        } else {
            return '';
        }
    }

    getPeriodServicesStartEnd(
        services: IServicePayment[],
        isStart = true
    ): string {
        let dt = '';
        moment.locale('ru');
        // Сортировка
        services.sort((a, b) => {
            if (a.dt && b.dt && a.dt > b.dt) {
                return 1;
            } else {
                return -1;
            }
        });
        const len = services.length;

        const i = isStart ? 0 : len - 1;
        if (services[0].dt && services[len - 1].dt) {
            if (
                services[0].dt.getFullYear() ==
                services[len - 1].dt?.getFullYear()
            ) {
                dt = moment(services[i].dt).format('D MMM');
            } else {
                dt = moment(services[i].dt).format('D MMM YYYY');
            }
        } else {
            if (services[i].dt) {
                dt = moment(services[i].dt).format('D MMM YYYY');
            }
        }

        return dt;
    }

    getStrEndServ(count: number): string {
        return getStrEnd(count, 'услуг', ['а', 'и', '']);
    }
}
