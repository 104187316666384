import { Injectable } from '@angular/core';
import { Protocol, ProtocolInfo, ProtocolItem } from '../interfaces/protocol';
import { HttpNewService } from './application/http-new.service';
import { ProtocolBuilder } from '../interfaces/protocol';
import { AuthService } from './auth.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProtocolService {
    constructor(
        private http: HttpNewService,
        private authService: AuthService
    ) {}

    async getProtocol(
        formResultId: number,
        visitID: number
    ): Promise<Protocol> {
        const token = null;
        const data = {
            result_id: formResultId.toString(),
            visit_id: visitID.toString(),
        };
        const params = new URLSearchParams(data);

        const items = await lastValueFrom<ProtocolItem[]>(
            this.http.get('/protocol/template?' + params, token)
        );

        const protocol = ProtocolBuilder(items);

        return protocol;
    }

    async saveProtocol(protocol: Protocol, visitID: number) {
        const token = null;
        const fields = protocol.prepareToSave(visitID);
        return lastValueFrom(
            this.http.post('/protocol/save', { fields }, token)
        );
    }

    /**
     * Создание протокола после подтвержения записи на приём к врачу
     */
    async createProtocol(visitId: number, formId: number) {
        const token = null;
        const data = {
            p_visit_id: visitId,
            p_form_id: formId,
        };

        return lastValueFrom(this.http.post('/protocol/create', data, token));
    }

    /**
     * Проверка на наличе возмжности просмотра и редактирования протокола пациентом
     */
    async checkProtocol(formResultId: number, visitID: number) {
        const token = null;
        const data = {
            p_form_result_id: formResultId.toString(),
            p_visit_id: visitID.toString(),
        };
        const params = new URLSearchParams(data);

        return lastValueFrom<ProtocolInfo[]>(
            this.http.get(`/protocol/check?${params}`, token)
        );
    }
}
