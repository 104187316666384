import {
    Component,
    Inject,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Protocol, ProtocolInfo } from 'src/app/interfaces/protocol';
import { ProtocolService } from 'src/app/services/protocol.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/lib/ariadna/alert';

import { APP_BASE_HREF, Location } from '@angular/common';
import * as moment from 'moment';

const modalOptions = {
    width: 'auto',
    height: 'auto',
    maxHeight: '80vh',
    maxWidth: '70vw',
    closeOnNavigation: true,
};

@Component({
    selector: 'visit-protocol',
    templateUrl: 'visit-protocol.component.html',
    styleUrls: ['./visit-protocol.component.scss'],
})
export class VisitProtocolComponent implements OnInit {
    isLoading = false;
    isRequired = false;
    closeDialogRef?: MatDialogRef<any, any>;
    saveDialogRef?: MatDialogRef<any, any>;
    protocol: Protocol = new Protocol([]);
    formResultId: number;
    visitID: number;
    protocolInfo?: ProtocolInfo;
    protocolStatus = {
        fullText: '',
        time: '',
    };
    @ViewChild('closeModal') closeModalTemplate?: TemplateRef<any>;
    @ViewChild('saveModal') saveModalTemplate?: TemplateRef<any>;

    constructor(
        public protocolService: ProtocolService,
        public dialog: MatDialog,
        public router: Router,
        public route: ActivatedRoute,
        public location: Location,
        public authService: AuthService,
        public alert: AlertService,
        @Inject(APP_BASE_HREF) private baseHref: string
    ) {
        this.formResultId = this.route.snapshot.params.formID;
        this.visitID = this.route.snapshot.params.visitID;
    }

    ngOnInit(): void {
        // console.log(this.authService.patientId, this.formResultId);
        if (this.authService && this.formResultId) {
            this.onLoad(this.visitID, this.formResultId);
        } else {
            this.router.navigate(['/home']);
            this.alert.error(
                'Ошибка открытия анкеты. Попробуйте открыть снова'
            );
            console.error(this.formResultId, this.authService);
        }
    }

    async onLoad(visitID: number, formResultId: number) {
        try {
            this.isLoading = true;
            this.protocolInfo = (
                await this.protocolService.checkProtocol(formResultId, visitID)
            )[0];
            // console.log(this.protocolInfo);
            this.protocolStatus = this.getProtocolStatus(this.protocolInfo);

            if (!this.protocolInfo.is_true_pat)
                throw new Error('Протокол не принадлежит пациенту.');
            this.protocol = await this.protocolService.getProtocol(
                formResultId,
                visitID
            );
        } catch (e) {
            console.log(e);
            this.router.navigate(['/home']);
            this.alert.error('Ошибка доступа к анкете.');
        } finally {
            this.isLoading = false;
        }
    }

    getProtocolStatus(protocolInfo: ProtocolInfo) {
        const protocolStatus = {
            fullText: '',
            time: '',
        };

        const todayDate = Intl.DateTimeFormat('ru-RU').format(new Date());
        const isVisitToday = todayDate === protocolInfo.visit_dat;

        if (isVisitToday) {
            protocolStatus.fullText = 'Доступно до ' + protocolInfo.visit_time;
            protocolStatus.time = protocolInfo.visit_time;
        } else {
            protocolStatus.fullText = 'Доступно до ' + protocolInfo.visit_dat;
            protocolStatus.time = protocolInfo.visit_dat;
        }

        if (protocolInfo?.is_exists) {
            protocolStatus.fullText = 'Сохранено';
        }

        return protocolStatus;
    }

    onSubmit(e: Event) {

        this.save();
        e.preventDefault();
    }

    async save() {
        try {
            this.isLoading = true;
            await this.protocolService.saveProtocol(
                this.protocol,
                this.visitID
            );

            if (this.saveModalTemplate) {
                this.saveDialogRef = this.dialog.open(
                    this.saveModalTemplate,
                    modalOptions
                );
            }
        } catch (e) {
            this.alert.error(
                'Произошла ошибка при сохранении анкеты. Попробуйте ещё раз.',
                2000
            );
        } finally {
            this.isLoading = false;
            setTimeout(() => {
                this.closeDialogRef?.close();
                this.saveDialogRef?.close();
                this.router.navigate(['/home/history']);
            }, 3000);
        }
    }

    onClose() {
        for (const item of this.protocol.controls) {
            if (item.isRequired && !item.item.form_item_val_text) {
                this.isRequired = true;
            }
        }

        if (this.isRequired) {
            if (this.closeModalTemplate) {
                this.closeDialogRef = this.dialog.open(
                    this.closeModalTemplate,
                    modalOptions
                );
            }
        } else this.goBack();
    }

    goMyrec() {
        this.saveDialogRef?.close();
        this.router.navigate([`/home/history`]);
    }

    goBack() {
        this.closeDialogRef?.close();

        this.location.back();
    }

    onCloseModal() {
        if (this.closeDialogRef) this.closeDialogRef.close();
        if (this.saveDialogRef) this.saveDialogRef.close();
    }
}
