<div class="list-upcoming-mobile">
    <app-mobile-feed-page-template
        [count-of-pages]="countOfPages"
        [loaded-pages]="loadedPages"
        [is-loading]="loadingMoreContent"
        [root-url]="'home/history'"
        [view-page]="viewPage"
        [pages]="this.rnumbPages"
        [no-more-content-text]="'Предстоящих посещений больше нет.'"
        *ngIf="!isLoading; else pageLoader"
    >
        <section
            page
            class="services-mobile__page"
            *ngFor="let page of rnumbPages; let pageIndex = index"
        >
            <app-feed-page-anchor
                [is-hidden]="pageIndex === 0"
                (intersected)="viewPage = pageIndex + 1"
            >
                Страница {{ pageIndex + 1 }} из {{ countOfPages }}
            </app-feed-page-anchor>

            <ng-container *ngTemplateOutlet="talon"></ng-container>
        </section>
    </app-mobile-feed-page-template>
</div>

<div class="list-upcoming-desktop">
    <div class="cls-not-rec" *ngIf="!rnumbList.length">Нет посещений</div>

    <div class="list-upcoming__talons">
        <div *ngIf="loadingRnumbList" class="loading">
            <!-- <img src="./assets/loading-rec.gif" /> -->
            <loader type="page"> </loader>
        </div>

        <ng-container *ngTemplateOutlet="talon"></ng-container>
    </div>
</div>

<ng-template #talon>
    <section
        class="talon"
        *ngFor="let rec of rnumbList; let i = index"
        (click)="openTalon(rec)"
    >
        <div class="talon__header">
            <div class="talon__date">
                <!-- Тронешь мем с запятой - я тебя найду -->
                <span>{{ getNameDay(rec.beginDate) }}<b>,&nbsp;</b></span>
                <span>{{ getDate(rec.beginDate) }}</span>
            </div>

            <label
                class="btn btn__upload"
                *ngIf="rec.is_upload_files === 1"
                for="{{ 'upload_' + i }}"
                (click)="stopPropagation($event)"
            >
                <span class="btn__text">Загрузить файлы</span>
                <mat-icon class="icon--upload" svgIcon="attach"></mat-icon>
                <input
                    type="file"
                    multiple
                    id="{{ 'upload_' + i }}"
                    name="files"
                    style="display: none"
                    (click)="stopPropagation($event)"
                    (change)="onChangeInputUpload($event, rec)"
                />
            </label>

            <button
                *ngIf="rec.form_result_id"
                class="visit-protocol-btn success"
                (click)="onVisitProtocolClick(rec, $event)"
            >
                <mat-icon
                    [ngClass]="
                        rec.is_exists
                            ? 'success'
                            : rec.is_mandatory
                            ? 'required'
                            : ''
                    "
                    svgIcon="protocol"
                >
                </mat-icon>
            </button>
        </div>

        <div class="talon__body">
            <div class="talon__time">
                {{ getTime(rec.beginDate) }}
            </div>
            <div class="talon__spec">{{ rec.spec }}</div>

            <div class="cls-div-telemed cls-div-telemed--mob">
                <ng-container *ngIf="rec.is_telemed">
                    <ng-container
                        *ngIf="
                            rec.url_telemed;
                            then showIconTelemed;
                            else showIconNotPayTelemed
                        "
                    >
                    </ng-container>
                </ng-container>
            </div>

            <div class="talon__fio">
                {{ rec.lastname }}
                {{ rec.firstname }}
                {{ rec.secondname }}
            </div>
        </div>

        <div class="talon__footer">
            <div class="talon__address" *ngIf="rec.addr">
                <mat-icon svgIcon="location"></mat-icon>
                {{ rec.addr }}
            </div>
        </div>

        <ng-template #tipContentPay>
            <div
                mat-dialog-content
                class="talon-content modal-teleIn-myRecMobile container-fluid talon-m"
            >
                <div class="cls-blok-res-rec cls-cansel-text addToTele">
                    <p class="title">
                        <b>Подключиться к Телеконференции?</b>
                    </p>
                    <div class="cls-cansel-btn">
                        <button
                            class="btn cls-btn-second cls-btn-filter cls-cansel-btn-no"
                            (click)="JoinAndClose()"
                        >
                            Нет
                        </button>
                        <button
                            class="btn cls-btn-second cls-btn-filter cls-cansel-btn-yes cls-cansel-btn-yes--orange"
                            (click)="goToTelemed(rec, $event)"
                        >
                            Да
                        </button>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #tipContentNotPay let-greeting="greeting">
            <div>Ссылка на Телеконференцию появится только после оплаты.</div>
        </ng-template>

        <ng-template #showIconTelemed>
            <mat-icon
                svgIcon="is-telemed"
                class="cls-icon-is-telemed icon-is-telemed"
                placement="top"
                (click)="open($event, tipContentPay)"
            ></mat-icon>
        </ng-template>

        <ng-template #showIconNotPayTelemed>
            <mat-icon
                svgIcon="is-telemed"
                class="cls-icon-is-telemed icon-is-telemed"
                placement="top"
                [ngbTooltip]="tipContentNotPay"
                tooltipClass="tooltipPanelTeleNotPay"
                container="body"
                (click)="stopPropagation($event)"
            ></mat-icon>
        </ng-template>
    </section>
</ng-template>

<ng-template #pageLoader>
    <div class="page-loader">
        <loader type="page" [isFullscreen]="true"></loader>
    </div>
</ng-template>
