<div class="main-wrapper">
    <div class="second-wrapper">
        <div class="not-found-image">
            <img src="./assets/img/not-found-route.png" alt="404_img" />
        </div>
        <div class="not-found-text">
            <p>Страница не найдена</p>
        </div>
        <div class="home-button">
            <button (click)="goBack()">Назад</button>
        </div>
    </div>
</div>
