<div class="cls-block-header">
    <span>Запись к врачу</span>
    <button
        class="btn cls-btn-second cls-btn-filter"
        *ngFor="let stBTN of staticFilterBTNs"
        (click)="btnStaticFiltr(stBTN)"
        [ngClass]="{
            'cls-btn-filter-active': stBTN.keyid == staticFilterSelected?.keyid
        }"
    >
        {{ stBTN.text }}
    </button>
</div>

<div class="loading-group cls-block-body">
    <div *ngIf="loadingRecList" class="loading">
        <!-- <img src="./assets/loading-rec.gif" /> -->
        <loader type="page"> </loader>
    </div>
    <app-panel [styleStr]="panelSetStyle" class="h-100">
        <div class="doctor-header">
            <div>
                <div *ngIf="loadingSpecList" class="loading">
                    <!-- <img src="./assets/loading-rec.gif" /> -->
                    <loader type="page"> </loader>
                </div>
                <mat-icon
                    svgIcon="search"
                    class="cls-btn-icon icon-search"
                ></mat-icon>
                <input
                    type="text"
                    class="form-control rec-searsh-spec"
                    placeholder="К кому хотите записаться?"
                    #InputBlocSpec
                    [(ngModel)]="searchStr"
                    (click)="searchClick()"
                    (ngModelChange)="changeSearch($event)"
                />
            </div>

            <div
                class="search-list"
                [@expandedAnimation]="searchAmine"
                (@expandedAnimation.done)="whenAnimateSearch($event)"
            >
                <div
                    class="row-spec-list w-100"
                    style="padding: 10px; margin-left: 3px; min-height: 80px"
                >
                    <div
                        class="col-md-4 search-list-item"
                        *ngFor="let sl of specList"
                        (click)="selectSpec(sl.keyid)"
                    >
                        <img
                            [src]="getImgSrcSpec(sl.keyid)"
                            (error)="errorHandlerIMG($event, 'spec')"
                        />
                        <span>{{ sl.text }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="h-100 cls-list-doctors">
            <div *ngIf="loadingListDoc" class="loading">
                <!-- <img src="./assets/loading-rec.gif" /> -->
                <loader type="page"> </loader>
            </div>

            <div
                class="row w-100 doctor-item"
                *ngFor="let doc of recDoctors"
                [ngClass]="{ 'is-select-doc': selectDoc == doc }"
            >
                <div class="col-md-1 doc-img" (click)="selectedDoc(doc)">
                    <userpic
                        [placeholder]="getImgSrcErr()"
                        [image]="getImgSrcDoc(doc.doctorid)"
                    ></userpic>
                    <!-- <img
                        [src]="getImgSrcDoc(doc.doctorid)"
                        (error)="errorHandlerIMG($event, 'doc')"
                    /> -->
                </div>
                <div class="col-md-3 doc-name" (click)="selectedDoc(doc)">
                    <div class="doc-item-fio">{{ getFIODoc(doc) }}</div>
                    <button
                        *ngIf="doc.info !== undefined && doc.info.length !== 0"
                        class="doc-info-btn"
                        (click)="openDocInfoModal(doc, $event)"
                    >
                        <img src="./assets/img/svg/info.svg" />
                    </button>
                    <div class="doc-item-desc">{{ doc.note }}</div>
                </div>

                <div class="col-md-4 doc-services" (click)="selectedDoc(doc)">
                    <ng-container
                        *ngIf="
                            isShowSrvList(doc);
                            then showListService;
                            else showListNotService
                        "
                    ></ng-container>

                    <ng-template #showListService>
                        <mat-select
                            class="form-control select-srv"
                            placeholder="Выберите услугу"
                            *ngIf="isShowSrvList(doc)"
                            [ngClass]="{
                                isInValidSrv: invalidSrv(doc.doctorid)
                            }"
                            [(ngModel)]="selectedSrv"
                            required
                            (selectionChange)="selectedServise(doc)"
                            hideSingleSelectionIndicator
                        >
                            <mat-select-trigger>
                                {{ selectedSrv ? selectedSrv.text : "" }}
                                <mat-icon
                                    *ngIf="selectedSrv?.is_online_pay == 1"
                                    svgIcon="is-online-pay"
                                    class="cls-icon-is-online-pay-selected"
                                ></mat-icon>

                                <!--
                                        <mat-icon *ngIf="selectedSrv?.is_telemed == 1" svgIcon="is-telemed" class="cls-icon-is-telemed"></mat-icon>
                        -->
                            </mat-select-trigger>
                            <mat-option
                                *ngFor="let docSrv of doc.srvlist"
                                [value]="docSrv"
                                class="srv-option"
                            >
                                <span class="srv-option__text"
                                    ><span>{{ docSrv.text }}</span>
                                    <button
                                        *ngIf="
                                            docSrv.description &&
                                            docSrv.description !== null
                                        "
                                        class="doc-info-btn"
                                        (click)="
                                            openServDescModal(docSrv, $event)
                                        "
                                    >
                                        <img src="./assets/img/svg/info.svg" />
                                    </button>
                                    <mat-icon
                                        *ngIf="docSrv.is_online_pay == 1"
                                        svgIcon="is-online-pay"
                                        class="srv-option__price-icon"
                                    ></mat-icon>
                                </span>

                                <span class="srv-option__price"
                                    ><span *ngIf="docSrv.price != 0"
                                        >{{ docSrv.price }} руб.</span
                                    >
                                    <span *ngIf="docSrv.price == 0"
                                        >Бесплатно</span
                                    >
                                </span>
                            </mat-option>
                        </mat-select>
                    </ng-template>

                    <ng-template #showListNotService>
                        <div
                            class="appoinment-without-srv"
                            (click)="selectedDoc(doc)"
                        >
                            Запись без услуг
                        </div>
                    </ng-template>
                </div>
                <div class="col-md-4 doc-rnumb" (click)="selectedDoc(doc)">
                    <span class="doc-item-adr"
                        >{{ doc.adr }}
                        {{ getPeriodStr(doc.rdatemin, doc.rdatemax) }}</span
                    >
                    <span class="doc-item-rcount"
                        >{{ doc.rcount }} номерков
                    </span>
                </div>
            </div>
        </div>

        <div class="cls-date-rnum" [@rightPanelAnimation]="panelRnumbAmine">
            <app-talonpicker
                *ngIf="showCalendar"
                [params]="paramsTalonpicker"
                (talonPickerClosed)="onTalonPickerClosed()"
            ></app-talonpicker>
        </div>
    </app-panel>
</div>
