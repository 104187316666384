<div class="wrapper">
    <div class="loader" *ngIf="isLoadData">
        <div class="loader__animation">
            <!-- <img src="./assets/loading-rec.gif" /> -->
            <loader type="page"> </loader>
        </div>
        <div class="loader__text">Загрузка данных</div>
    </div>
    <div class="main" *ngIf="!isLoadData">
        <div class="title-text-and-exit">
            <p>Кабинет родственника</p>
            <button class="close" type="button">
                <img
                    src="./assets/img/svg/close_family_access.svg"
                    (click)="closeModal()"
                    alt="Закрыть окно семейного доступа"
                />
            </button>
        </div>
        <ng-container *ngIf="familyList.length > 0; else elseBlock">
            <div class="family-list">
                <div class="main-family">
                    <p>Выберите родственника для продолжения</p>
                    <div class="family-list-buttons-block">
                        <div *ngFor="let member of familyList">
                            <button
                                class="family-list-button"
                                [ngClass]="{
                                    'family-list-button-selected':
                                        selectedFamilyMemberId === member.keyid
                                }"
                                (click)="selectedFamilyMemberId = member.keyid"
                            >
                                <div class="family-list-button-items">
                                    <div class="family-list-button-text__fio">
                                        {{ member.fio }}
                                    </div>
                                    <div class="family-list-button-text__age">
                                        {{ member.age }}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="submit-block">
                    <button
                        class="submit-btn"
                        [disabled]="!selectedFamilyMemberId"
                        (click)="submitMember()"
                    >
                        Перейти
                    </button>
                </div>
            </div>
        </ng-container>
        <ng-template #elseBlock>
            <div class="not-found-block">
                <img
                    class="img-not-found"
                    src="./assets/img/svg/notFoundFamily.svg"
                    alt="Не найдено родственников"
                />
                <div class="cls-text-notfound">
                    Для получения доступа в Кабинет родственника обратитесь в
                    регистратуру медицинской организации
                </div>
                <button
                    class="not-found-btn"
                    routerLink="/home"
                    (click)="closeModal()"
                >
                    Вернуться на главную страницу
                </button>
            </div>
        </ng-template>
    </div>
</div>
