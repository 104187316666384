import {
    Component,
    Input,
    Output,    
    ViewChild,
    ViewEncapsulation,
    EventEmitter
} from '@angular/core';
import {
    IDep,
    IRecTalon,
    IRnumbDate,
    ITalonModal,
} from 'src/app/interfaces/record.interface';
import { RecordService } from 'src/app/services/record.service';
import { strToDate } from '../application/global.function';
import { MatCalendar } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { CalendarHeader } from './header/calendar-header.component';
import { AlertService } from 'src/lib/ariadna/alert';
import { NewTalonComponentMobile } from 'src/app/modals/new-talon-mobile/talon.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-talonpicker',
    templateUrl: './talonpicker.component.html',
    styleUrls: ['./talonpicker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TalonpickerComponent {
    @Input() params!: IRnumbDate;
    @Output() talonPickerClosed = new EventEmitter();
    @ViewChild('calendar') calendar!: MatCalendar<Moment>;
    loading: boolean = true;
    activeDays: Moment[] = []; // NOT sorted by date
    selectedDay: Moment | null = moment();
    minDate: Moment = moment();
    maxDate: Moment = moment().add(1, 'month');

    talonListAll: IRecTalon[] = [];
    talonListFiltered: IRecTalon[] = [];
    depList: IDep[] = [];
    selectedDep: number | null = null;
    header = CalendarHeader;

    dateFilter = (date: Moment) => this.isActiveDay(date);

    constructor(
        private rs: RecordService,
        private alertService: AlertService,
        private modal: MatDialog
    ) {}

    async ngOnChanges(changes: any) {
        // console.log(changes);
        if (changes.params) {
            this.loading = true;
            await Promise.all([
                await this.getTalonList(changes.params.currentValue),
                await this.getDepList(changes.params.currentValue),
            ]).catch((e) => {
                this.alertService.error('Не удалось получить список талонов');
                console.error(e);
            });
            this.activeDays = this.filterActiveDays();
            this.selectedDay = this.findFirstActiveDay();
            this.talonListFiltered = this.filterTalons();
            this.updateCalendar();
            this.loading = false;
        }
    }

    private async getTalonList(params: IRnumbDate) {
        // console.log(params);
        this.talonListAll = await lastValueFrom(this.rs.getRnumbListv2(params));
        // console.log(this.talonListAll);
        this.talonListAll.forEach((talon, i) => {
            this.talonListAll[i].dtBegin = strToDate(talon.dat_begin_str);
        });
    }

    private async getDepList(params: IRnumbDate) {
        this.depList = await lastValueFrom(this.rs.getDepListv2(params));
        this.depList = this.depList.filter((dep) => {
            const deps = new Set();
            this.talonListAll.forEach((talon) => deps.add(talon.depid));
            return deps.has(dep.keyid);
        });
    }

    selectDay(day: Moment | null) {
        this.talonListFiltered = this.filterTalons();
    }

    selectDep(dep: IDep | null) {
        this.talonListFiltered = this.filterTalons();
        // console.log(this.talonListFiltered);
    }

    updateCalendar() {
        if (this.calendar) this.calendar.updateTodaysDate();
    }

    private isActiveDay(day: Moment) {
        return this.activeDays.some(
            (activeDay) =>
                day.date() === activeDay.date() &&
                day.month() === activeDay.month() &&
                day.year() === activeDay.year()
        );
    }

    private filterActiveDays() {
        return this.talonListAll
            .map((talon) => moment(strToDate(talon.dat_begin_str)))
            .filter((day, index, self) => self.indexOf(day) === index);
    }

    findFirstActiveDay() {
        let firstActiveDay = this.activeDays[0];
        this.activeDays.forEach((day) => {
            if (firstActiveDay.valueOf() > day.valueOf()) firstActiveDay = day;
        });
        return firstActiveDay;
    }

    filterTalons() {
        return this.talonListAll.filter((talon) => {
            return (
                talon.dtBegin?.getFullYear() === this.selectedDay?.year() &&
                talon.dtBegin?.getMonth() === this.selectedDay?.month() &&
                talon.dtBegin?.getDate() === this.selectedDay?.date() &&
                (this.selectedDep ? talon.depid === this.selectedDep : true)
            );
        });
    }

    async openTalonModal(event: MouseEvent, talon: IRecTalon) {
        event.stopPropagation();

        let data: ITalonModal = {
            rnumbID: null,
            info: {
                docdepID: talon.docdepid,
                beginDate: talon.dtBegin,
            },
            srv: this.params.srv,
            paramCansel: false,
        };
        try {
            // console.log(talon);
            if (
                talon.is_interval &&
                talon.is_interval > 0 &&
                talon.interval_id
            ) {
                const { guid, err_code, err_text } = await lastValueFrom(
                    this.rs.getRnumbCreateInterval(
                        talon.interval_id,
                        talon.dat_begin_str,
                        talon.dat_end_str
                    )
                );
                if (err_code !== 0 || !guid) throw new Error(err_text);

                data.rnumbID = guid;
            } else if (talon.rnumbid) {
                data.rnumbID = talon.rnumbid;
            }
            let dialogRefOptions = {
                width: 'auto',
                height: 'auto',
                maxWidth: 'auto',
                minWidth: 'auto',
                closeOnNavigation: true,
                data,
            };

            // Check screen width and adjust dialog options if needed
            if (window.matchMedia('(max-width: 768px)').matches) {
                dialogRefOptions.width = '100vw';
                dialogRefOptions.maxWidth = '100vw';
                dialogRefOptions.minWidth = '100vw';
            }
            const modalRef = this.modal.open(
                NewTalonComponentMobile,
                dialogRefOptions
            );
        } catch (error: any) {
            console.error(`Не удалось создать интервал: ${error.msg}`);
            this.alertService.error(
                `Не удалось записаться на номерок: ${error.msg}`
            );
        }
    }
   

    closeCalendar() {
        this.talonPickerClosed.emit();
      }

 
}
