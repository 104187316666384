import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as Joi from 'joi';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { I18n } from 'src/app/shared/i18n/models';
import { AlertService } from 'src/lib/ariadna/alert';
import { IDocuments } from '../../interfaces/document.interface';
import {
    IContract,
    IInetuserLog,
    IPatient,
    ITokenAndPatientId,
} from '../../interfaces/patient';
import { ChangePwComponent } from '../../modals/change-pw/change-pw.component';
import { CreatePwComponent } from '../../modals/create-pw/create-pw.component';
import { DocImgComponent } from '../../modals/doc-img/doc-img.component';
import { ConfigService } from '../../services/application/config.service.';
import { LoadService } from '../../services/application/load.service';
import { AuthService } from '../../services/auth.service';
import { FileUploadService } from '../../services/file-upload.service';
import { PatientService } from '../../services/patient.service';
import { Size } from '../../services/size';
import {
    getNameDay,
    getTekDay,
    getTime,
    strToDate,
} from '../application/global.function';
import { Store } from 'src/app/services/store.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
    patient!: IPatient;
    public deviceType: string = '';
    @ViewChild('ContentBlockFioAndDoc') ContentBlockFioAndDocEL:
        | ElementRef
        | undefined;
    @ViewChild('ContentBlockFio') ContentBlockFioEL: ElementRef | undefined;
    @Input() tmpToken: ITokenAndPatientId | null = null; //токен

    //  panelDocSetStyle = {'height': '130px', 'margin-top': '25px'};
    panelDocSetStyle = { height: '130px' };
    contracts!: IContract[];
    documents!: IDocuments[];
    contractsCountRec = 0; // Количество документов
    // contractsPayCountRec = 0
    docCountRec = 0; // Количество документов doc
    patientLogs!: IInetuserLog[]; // Логи пациента
    patientLogsCountRec = 0; // Всего записей
    //  patientLogsCountRecSet = new EventEmitter<number>();     // Всего записей, через события
    patientLogsCountRectoPage = 10; // Количетсво записей на странице
    isFirstPatientLogin = false;
    loadingContracts = false; // Загрузка
    loadingPatient = false; // Загрузка
    loadingLogs = false; // Загрузка
    loadingDocs = false; // Загрузка
    loading = false; // Загрузка

    canChangeEmail = false;

    blockBodystyleHeight = { height: 'auto' };
    //  blockContractStyleHeight = {height:'auto'};
    //  panelSetStyleLog = {'height': '100%'};

    //  public panelSetStyle = {'margin-bottom': '15px'};
    private _setting: any;

    showPanelDocOld = false; // Показывать панель всех документов

    counter = 0;

    modalReference!: NgbModalRef;
    exitModal = false;
    isChangePassword = true;
    mobilePhone = '';

    constructor(
        private ps: PatientService,
        private auth: AuthService,
        private configS: ConfigService,
        private size: Size,
        private dialog: MatDialog,
        private load: LoadService,
        private fu: FileUploadService,
        private title: Title,
        private alertService: AlertService,
        private modalService: NgbModal,
        private httpNew: HttpNewService,
        private _i18n: I18n
    ) {
        this._setting = load.configData.pages.personal;

        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }

        if (
            this._setting &&
            this._setting.SHOW_PANEL_DOC_OLD &&
            this._setting.SHOW_PANEL_DOC_OLD == 1
        ) {
            this.showPanelDocOld = true;
        }
        //    this.showPanelDocOld = true; // TEST

        this.deviceType = this.size.getDeviceType();
        this.size.onResize$().subscribe((val) => {
            let h = val.outletH;

            if (val.blockHeaderH && val.blockHeaderH > 0) {
                h = h - val.blockHeaderH;
            }
            h = h - 20; //отступ от футтера
            this.blockBodystyleHeight.height = h + 'px';
        });
    }

    ngOnInit(): void {
        this.deviceType = this.size.getDeviceType();
        this.getPatientInfo();
        this.getContractList();
        this.getDocumentsList();
        // this.getPayContractList();
        this.isChangePassword = this.isChangePasswordAccess();
        if (this.load.configData.pages.login.CanChangeEmail) {
            this.canChangeEmail = true;
        }

        this.get_log();
    }

    closeModal() {
        this.modalReference.close();
    }
    isChangePasswordAccess(): boolean {
        return Store.getDataString('ParentCookieName') ? false : true;
    }

    get_log() {
        this.ps.getInetuserLogSize$().subscribe(
            (info) => {
                if (info.size) {
                    this.patientLogsCountRec = parseInt(info.size);
                    //
                    //            this.patientLogsCountRecSet.emit(this.patientLogsCountRec);
                    //
                    this.getPatientLog(1, this.patientLogsCountRectoPage);
                }
            },
            (err) => {
                this.patientLogsCountRec = 0;
            }
        );
    }
    // Отслеживание изменений размера
    @HostListener('window:resize', ['$event'])
    private onResize(event: any) {
        this.calcTableH();
        //    setTimeout(() => this.calcTableH(), 1000);
    }

    /* расчет высоты блока относительно */
    private calcTableH() {
        if (this.deviceType == this.size.pc) {
            if (
                this.ContentBlockFioAndDocEL &&
                this.ContentBlockFioEL &&
                this.ContentBlockFioAndDocEL.nativeElement &&
                this.ContentBlockFioEL.nativeElement
            ) {
                /*
              
              
              
        */
                let h =
                    this.ContentBlockFioAndDocEL.nativeElement.offsetHeight -
                    this.ContentBlockFioEL.nativeElement.offsetHeight;
                /*
        
        */
                h = h - 30;
                this.panelDocSetStyle = { height: `${h}px` };
                /*
        
        */
            }
        }
    }

    /**
     * Получение данных из Кэша или сервера в зависимости от наличия
     */
    private getPatientInfo(): void {
        this.loadingPatient = true;
        this.ps.getServerPatientInfo$().subscribe(
            (info) => {
                this.patient = info;
                this.isFirstPatientLogin = this.patient.count_login
                    ? this.patient.count_login === 0
                    : false;
                this.patient.birthdate = null;
                this.mobilePhone = this.patient?.cellular;
                setTimeout(() => this.calcTableH(), 100);
                this.loadingPatient = false;
            },
            (err) => {
                this.loadingPatient = false;
            }
        );
    }

    public getDocumentsList(): void {
        this.loadingDocs = true;
        this.documents = [];
        this.docCountRec = 0;
        this.fu.getDocList().subscribe(
            (info) => {
                this.documents = info;
                this.documents.forEach((item) => {
                    item.dat = strToDate(item.dat_str);
                });

                this.docCountRec = this.documents.length;
                this.loadingDocs = false;
            },
            (err) => {
                this.docCountRec = 0;
                this.loadingDocs = false;
            }
        );
    }

    public getContractList(): void {
        this.loadingContracts = true;
        this.contracts = [];
        this.contractsCountRec = 0;

        this.auth.getContractListToPatient().subscribe(
            (info) => {
                this.contracts = info;
                this.contractsCountRec = this.contracts.length;
                this.loadingContracts = false;
            },
            (err) => {
                this.contractsCountRec = 0;
                this.loadingContracts = false;
            }
        );
    }

    public getPatientLog(pStart?: number, pEnd?: number): void {
        this.loadingLogs = true;
        this.ps.getInetuserLog$(pStart, pEnd).subscribe(
            (info) => {
                this.patientLogs = info;
                this.patientLogs.forEach((item) => {
                    item.date = strToDate(item.dat_str);
                    item.dateNormalized = this.getDateTime(item.date);
                });
                this.loadingLogs = false;
            },
            (err) => {
                this.patientLogs = [];
                this.loadingLogs = false;
            }
        );
    }

    public getDateTime(date: Date): string {
        const minutes = `0${date.getMinutes()}`.slice(-2);
        const hours = date.getHours();

        const dateLocale = date.toLocaleString('ru', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        return `${dateLocale} ${hours}:${minutes}`;
    }

    /* Событие выбора страницы */
    changedPage(page: number) {
        if (page == 1) {
            this.getPatientLog(1, this.patientLogsCountRectoPage);
        } else {
            this.getPatientLog(
                page * this.patientLogsCountRectoPage -
                    this.patientLogsCountRectoPage,
                page * this.patientLogsCountRectoPage
            );
        }
    }

    /*
  patientLogsCountRec = 132;     // Всего записей
  patientLogsCountRectoPage = 10;     // Количетсво записей на странице
*/

    /* открыть контракт */
    openContract(contractId: number) {
        const url = `${this.configS.getValue(
            'hostBackend'
        )}/contract/patient/docpdf?patientId=${null}&contractId=${contractId}`;
        window.open(url, '_blank');
    }

    openDocument(docId: number, fileName: string) {
        const url = `${this.configS.getValue(
            'hostBackend'
        )}/file/img/${null}/${docId}/${fileName}`;
        window.open(url, '_blank');
    }

    /* Смена пароля */
    changePW() {
        const dialogRef = this.dialog.open(ChangePwComponent, {
            width: 'auto',
            height: 'auto',
            closeOnNavigation: true,
            data: {},
        });
    }

    openModal(e: Event, content: any) {
        if (!this.canChangeEmail) {
            return;
        }
        e.stopPropagation();

        this.modalReference = this.modalService.open(content);
    }

    /* Генерация пароля */
    createPW() {
        const dialogRef = this.dialog.open(CreatePwComponent, {
            width: 'auto',
            height: 'auto',
            closeOnNavigation: true,
            data: {},
        });
    }

    // Тест ЛизиЛоад
    /*
  startLoading($event: any) {
    
  }
*/

    public getDocList(): void {
        this.contracts = [];
        this.contractsCountRec = 0;
        this.loadingDocs = true;

        this.auth.getContractListToPatient().subscribe(
            (info) => {
                this.contracts = info;
                this.contractsCountRec = this.contracts.length;
                this.loadingDocs = false;
            },
            (err) => {
                this.contractsCountRec = 0;
                this.loadingDocs = false;
            }
        );
    }

    public addDoc(): void {
        const dialogRef = this.dialog.open(DocImgComponent, {
            width: 'auto',
            height: 'auto',
            closeOnNavigation: true,
            data: null,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.success) {
                // Нормально
                this.alertService.info('Документ добавлен.');
                this.getDocumentsList();
            } else {
                if (result.msg) {
                    this.alertService.error(result.msg);
                }
            }
        });

        // this.alertService.error(errCPbyR);
    }

    @Input() newEmail: string = '';

    updatePatientEmail(): void {
        // const res = this.httpNew.put('/api/update_email')
    }

    saveEmail(): void {
        if (!this.newEmail) {
            return;
        }
        const emailSchema = Joi.string().email({ tlds: { allow: false } });
        const isValid = !emailSchema.validate(this.newEmail).error;
        if (!isValid) {
            this.newEmail = '';
            this.alertService.error(`Неправильный формат почты`, 5000);
            this.closeModal();
            return;
        }
        const data = {
            newEmail: this.newEmail,
            patientId: this.patient!.patient_id,
        };

        const res$ = this.httpNew.put(
            '/api/patient/update_email',
            { patientId: this.patient?.patient_id, newEmail: this.newEmail },
            null
        );

        res$.subscribe(
            (result: { success: boolean; data: object; msg: string }) => {
                if (result) {
                    this.patient!.email = this.newEmail;
                    this.alertService.success('Почта успешно обновлена', 5000);
                    this.newEmail = '';
                    this.closeModal();
                }
            },
            (error) => {
                if (error) {
                    this.alertService.error(`${error.error.msg}`, 5000);
                    this.newEmail = '';
                    this.closeModal();
                }
            }
        );
    }

    getTekDay(dt: Date | undefined): string {
        if (dt) {
            return getTekDay(dt);
        } else {
            return '';
        }
    }

    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    getTime(dt: Date): string {
        return getTime(dt);
    }
}
