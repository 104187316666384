<div
    class="loader-wrapper"
    [ngClass]="{
        fullscreen: isFullscreen,
        'bg-fill': bgFill
    }"
    [ngSwitch]="type"
    [ngStyle]="{ color: color }"
>
    <div *ngSwitchCase="'component'">
        <ng-lottie [options]="lottieOptions" />
    </div>
    <div class="page-loader" *ngSwitchCase="'page'">
        <!-- <img src="./assets/loading-rec.gif" /> -->
        <ng-lottie
            [options]="lottieOptions"
            [ngStyle]="{ width: '350px', height: '350px' }"
        />
        <p class="message" *ngIf="message">{{ message }}</p>
    </div>
</div>
