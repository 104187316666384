import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { showOpacityAnimation } from '../../../animations/show-left-right.animation';
import { AuthService } from '../../../services/auth.service';
import { AlertService } from 'src/lib/ariadna/alert';
import { LoadService } from '../../../services/application/load.service';
import { snilsValidator } from '../../../directives/snils.validator';
import {
    CaptchaService,
    ICaptcha,
} from 'src/app/services/captcha/captcha.service';
import { lastValueFrom } from 'rxjs';
@Component({
    selector: 'app-reg',
    templateUrl: './reg.component.html',
    styleUrls: ['./reg.component.scss'],
    animations: [showOpacityAnimation],
})
export class RegComponent implements OnInit {
    @Output() onChanged = new EventEmitter<number>(); // выбран
    @Output() onError = new EventEmitter<string>(); // Ошибка
    @Output() onSuccess = new EventEmitter<string>(); // Сообщение об успешной регистрации

    animState = 'on';
    loading = false; // Загрузка
    frmReg!: FormGroup;
    notAcceptedCounter = 0; // Number of contracts to accept before registration

    createPatientListParams =
        'lastName*;firstName*;secondName*;snils*;razdel;phone*;email*;'; // Настройки из файла
    createPatientListParamsR: string[] = []; // Массив полей для регистрации
    captchaJpg = ''; // картинка дла КАПЧИ
    maxDate!: string; // MAX дата
    captcha?: ICaptcha;
    listAuth: number = 0;
    private selectedGender: number | null = null;
    constructor(
        // private configS: ConfigService, /если не используется: удалить
        private auth: AuthService,
        private alertService: AlertService,
        private load: LoadService,
        private captchaService: CaptchaService // private router: Router /если не используется: удалить
    ) {
        load.configData.pages.CreatePatientListParams;

        if (load.configData.pages?.login?.CreatePatientListParams) {
            this.listAuth = load.configData.pages?.login.listAuth;
            // this.listAuth = 0;
            this.createPatientListParams =
                load.configData.pages.login.CreatePatientListParams;
        }
    }

    ngOnInit(): void {
        this.maxDate = moment(new Date()).format('YYYY-MM-DD');
        this.initialize();
        this.getCaptcha();
    }

    private initialize(): void {
        this.frmReg = new FormGroup({});
        let validatorOpts = [];

        this.createPatientListParamsR = [];
        this.createPatientListParamsR = this.createPatientListParams.split(';');

        if (this.listAuth === 1) {
            //TODO Временное решение для МИБСа, передлать под динамическую генерацию
            this.notAcceptedCounter = 2; // на текущей момент им нужно два соглашения
        }

        validatorOpts.push(Validators.minLength(4));
        this.frmReg.addControl('captcha', new FormControl('', validatorOpts));

        this.createPatientListParamsR.forEach((item, index) => {
            validatorOpts = [];
            if (item.indexOf('*') >= 0) {
                validatorOpts.push(Validators.required);
            }

            switch (item) {
                case 'lastName':
                case 'lastName*':
                    validatorOpts.push(Validators.minLength(1));
                    this.frmReg.addControl(
                        'lastName',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'firstName':
                case 'firstName*':
                    validatorOpts.push(Validators.minLength(1));
                    this.frmReg.addControl(
                        'firstName',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'secondName':
                case 'secondName*':
                    validatorOpts.push(Validators.minLength(1));
                    this.frmReg.addControl(
                        'secondName',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'birthDate':
                case 'birthDate*':
                    validatorOpts.push(Validators.minLength(1));
                    this.frmReg.addControl(
                        'birthDate',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'email':
                case 'email*':
                    validatorOpts.push(Validators.email);
                    this.frmReg.addControl(
                        'email',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'phone':
                case 'phone*':
                    validatorOpts.push(
                        Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)
                    );
                    this.frmReg.addControl(
                        'phone',
                        new FormControl('', validatorOpts)
                    );
                    break;
                // case 'sex':
                // case 'sex*':
                //     validatorOpts.push(Validators.minLength(1));
                //     this.frmReg.addControl(
                //         'sex',
                //         new FormControl('', validatorOpts)
                //     );
                //     break;
                case 'snils':
                case 'snils*':
                    this.frmReg.addControl(
                        'snils',
                        new FormControl('', [
                            snilsValidator,
                            Validators.minLength(10),
                        ])
                    );
                    break;
                case 'inn':
                case 'inn*':
                    validatorOpts.push(Validators.minLength(10));
                    this.frmReg.addControl(
                        'inn',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'iin':
                case 'iin*':
                    validatorOpts.push(Validators.minLength(10));
                    this.frmReg.addControl(
                        'iin',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'card':
                case 'card*':
                    validatorOpts.push(Validators.minLength(10));
                    this.frmReg.addControl(
                        'card',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'tabNum':
                case 'tabNum*':
                    validatorOpts.push(Validators.minLength(1));
                    this.frmReg.addControl(
                        'tabNum',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'polisNum':
                case 'polisNum*':
                    validatorOpts.push(Validators.minLength(4));
                    this.frmReg.addControl(
                        'polisNum',
                        new FormControl('', validatorOpts)
                    );
                    break;
                case 'polisSer':
                case 'polisSer*':
                    validatorOpts.push(Validators.minLength(2));
                    this.frmReg.addControl(
                        'polisSer',
                        new FormControl('', validatorOpts)
                    );
                    break;
                default:
            }
        });
    }

    async reg() {
        if (this.captcha) {
            const captchaId = this.captcha.id;
            const captchaValue = this.frmReg.controls['captcha'].value;
            this.frmReg.value.lastName = this.frmReg.value.lastName.trim();
            this.frmReg.value.firstName = this.frmReg.value.firstName.trim();
            this.frmReg.value.secondName = this.frmReg.value.secondName.trim();

            //const verify = await this.captchaService.verifyCaptcha(captchaId, captchaValue);
            const patient = JSON.parse(JSON.stringify(this.frmReg.value));
            patient.sex = this.selectedGender;
            patient.captchaId = captchaId;
            patient.captchaValue = captchaValue;
            delete patient.captcha;
            delete patient.captchaSolid;

            this.onError.emit('');

            this.loading = true;
            console.log(patient);
            lastValueFrom(this.auth.reg$(patient))
                .then(
                    (result) => this.onRegSuccessHandler(result),
                    (error) => this.onRegErrorHandler(error)
                )
                .finally(() => (this.loading = false));
        } else {
            this.onError.emit('Ошибка при проверке капчи');
            if (!this.loading) this.refreshCaptcha();
        }
    }

    onRegSuccessHandler(result: {
        id: number;
        err_code: number;
        err_text: string;
    }) {
        console.log(result);
        let error: string | null = null;

        if (result && result.id && result.err_code === 0) {
            this.onSuccess.emit(
                'Доступ в личный кабинет предоставлен. Логин и пароль отправлены вам на почту'
            );
        } else if (result.err_code > 0 && result.err_text) {
            error = result.err_text;

            this.alertService.error(result.err_text, 9000);
        } else {
            error = 'Проверьте введенные данные!';
        }

        if (error) this.onError.emit(error);

        this.loading = false;
        this.refreshCaptcha();
    }

    onRegErrorHandler(error: any) {
        if (error.msg === 'Капча устарела') {
            this.alertService.error(
                'Капча устарела или введена не правильно!',
                9000
            );
        } else {
            this.alertService.error('Проверьте введенные данные!', 9000);
        }

        this.loading = false;
        this.getCaptcha();
    }

    // todo: вынести в pipe
    // Проверка на обязательность заполнения
    public requiredField(fieldName: string): boolean {
        return fieldName.indexOf('*') >= 0;
    }

    public onDateKeydownHandler(event: KeyboardEvent) {
        const target = event.target as HTMLInputElement;
        const date: string[] = target.value.split('-');

        if (date.length === 3) {
            const year = date[0];

            const isEventStop =
                Number(year) > 999 &&
                event.code !== 'Tab' &&
                event.code !== 'Backspace';

            if (isEventStop) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    whenAnimate(event: any) {
        if (event.toState === 'off') {
            this.onChanged.emit(0);
        }
    }

    goAuth(): void {
        this.animState = 'off';
    }

    // Получение капчи
    async getCaptcha() {
        this.loading = true;
        this.captcha = await this.captchaService.fetchCaptcha();
        this.loading = false;
    }

    // Обновление капчи
    public refreshCaptcha() {
        this.captcha = undefined;
        this.frmReg.controls['captcha']?.setValue('');
        this.getCaptcha();
    }

    //TODO Временное решение для МИБСа, передлать под динамическую генерацию
    checkAcception(event: Event): void {
        const element = event.target as HTMLInputElement;
        element.checked ? this.notAcceptedCounter-- : this.notAcceptedCounter++;
    }

    clickAcceptPd(): void {
        const url = './assets/acception_pd.pdf';
        window.open(url, '_blank');
    }
    clickAcceptLs(): void {
        const url = './assets/acception_ls.pdf';
        window.open(url, '_blank');
    }

    stopPropagation(e: MouseEvent) {
        e.stopPropagation();
    }

    gender($e: any, number: number) {
        $e.stopPropagation();
        const activeBtnMan = document.getElementsByClassName('man')[0];
        const activeBtnWoman = document.getElementsByClassName('woman')[0];
        if (activeBtnMan && activeBtnWoman) {
            $e.target.classList.contains('active')
                ? (this.selectedGender = null)
                : (this.selectedGender = number);
            this.selectedGender === 0
                ? activeBtnMan.classList.add('active')
                : activeBtnMan.classList.remove('active');
            this.selectedGender === 1
                ? activeBtnWoman.classList.add('active')
                : activeBtnWoman.classList.remove('active');
            console.log(this.selectedGender);
        }
    }
}
