import { Injectable } from '@angular/core';

/*
Сервис по работе с локальным хранилищем
 */

@Injectable()
export class Store {
    constructor() {}

    private static _appKey = 'pa-web'; // Префикс для каждого проекта свой

    /**
     * Добавить объект в локальное хранилище
     * @param key
     * @param {object} obj
     */
    public static setData(key: string, obj: object): void {
        localStorage.setItem(this._appKey + '_' + key, JSON.stringify(obj));
    }
    public static setStringData(key: string, value: string): void {
        localStorage.setItem(this._appKey + '_' + key, value);
    }

    /**
     * Получить объект из локального хранилища
     * @returns {object}
     */
    public static getData(key: string): Record<string, any> {
        const item = localStorage.getItem(this._appKey + '_' + key);
        if (item) {
            return JSON.parse(item);
        } else {
            return {};
        }
    }
    public static getDataString(key: string): string | null {
        return localStorage.getItem(this._appKey + '_' + key);
    }

    /**
     * Удалить данные по ключу
     */
    public static deleteStoreNameKey(key: string): void {
        localStorage.removeItem(this._appKey + '_' + key);
    }

    /**
     * Полная очистка локального хранилища
     */
    public static clearAllStore(): void {
        //
        localStorage.clear();
    }
    // public static cookieAllClear(): void {
    //     const cookies = document.cookie.split(';');
    //     console.log({ cookies });
    //     for (const cookie of cookies) {
    //         const eqPos = cookie.indexOf('=');
    //         const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //         document.cookie =
    //             name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    //     }
    // }
    // КУСОК НЕРАБОТАЮЩЕГО ДЕР***
    // public static setCookie(
    //     cookieName: string,
    //     cookieValue: string,
    //     baseHref: string
    // ): void {
    //     try {
    //         const date = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    //         const path = baseHref ? baseHref : '/';
    //         const domain = window.location.hostname;
    //         document.cookie = `${cookieName}=${cookieValue}; domain=${domain}; path=${path};expires=${date.toUTCString()};`;
    //         console.log(path, window.location.hostname, baseHref);
    //         // console.log(window.location.hostname);
    //         // console.log(
    //         //     `${cookieName}=${cookieValue}; domain=${
    //         //         window.location.hostname + this.baseHref
    //         //             ? this.baseHref
    //         //             : ''
    //         //     }; path=/; expires=${date.toUTCString()};`
    //         // );
    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    // public static clearAllCookie(): void {
    //     try {
    //         const cookies = document.cookie.split(';');
    //         for (const cookie of cookies) {
    //             const eqPos = cookie.indexOf('=');
    //             const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //             document.cookie =
    //                 name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    //         }
    //     } catch (e) {
    //         console.error(e);
    //         throw e;
    //     }
    // }

    public static getCookieData<T>(): T | null {
        const cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            if (cookie.includes('pa-web_Patient_Local')) {
                const token = cookie
                    .split(';')
                    .find((predicate) =>
                        predicate.trim().startsWith('pa-web_Patient_Local=')
                    )
                    ?.split('=')[1];
                const cookieValue = token?.split('.')[1] || null;
                if (!cookieValue) return null;
                let jwtPayload = JSON.parse(atob(cookieValue));
                Object.assign(jwtPayload, {
                    token: this.getTokenFromCookie(),
                });
                return jwtPayload;
            }
        }
        return null;
    }

    public static getTokenFromCookie(): string | null {
        const cookieName = 'pa-web_Patient_Local=';
        const cookieValue = document.cookie
            .split(';')
            .find((c) => c.trim().startsWith(cookieName));
        if (cookieValue) {
            return cookieValue.split('=')[1] !== 'undefined' &&
                cookieValue.split('=')[1] !== 'null'
                ? cookieValue.split('=')[1]
                : null;
        }
        return null;
    }
}
