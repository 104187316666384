import { Component, Input, OnInit } from '@angular/core';
import { Imenu } from '../../../interfaces/menu.interface';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { LoadService } from '../../../services/application/load.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-menu-left',
    templateUrl: './menu-left.component.html',
    styleUrls: ['./menu-left.component.scss'],
})
export class MenuLeftComponent implements OnInit {
    @Input() patientName: string = '';
    menus: Imenu[] = [];
    isMobileMenuOpen = false;
    lkActive = false; // Активны Личные данные
    private _setting: any;
    private settingMenu: String[];
    public routerDynamicLink: string = '';
    // @Input() dicommode!: boolean;

    constructor(
        private router: Router,
        private auth: AuthService,
        private load: LoadService,
        private modalService: NgbModal
    ) {
        router.events.subscribe((val: any) => {
            if (val instanceof NavigationEnd) {
                // По завершению загрузки
                this.routerDynamicLink = val.url.split('#')[0];
            }
        });

        this._setting = load.configData.pages.login;

        if (
            load.configData.menu.items &&
            load.configData.menu.items.length > 5
        ) {
            this.settingMenu = load.configData.menu.items.split(',');
        } else {
            this.settingMenu = [];
        }

        /* Отловим событие перехода */
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.findMenuActiveURL();
            }
        });
    }

    ngOnInit(): void {
        this.generMenu();
        // По URL делаем активное меню , F5
        this.findMenuActiveURL();
    }

    findMenuActiveURL() {
        this.lkActive = false;
        //
        if (this.router.url === '/home/account') {
            this.lkActive = true;
            this.menus.forEach((item) => {
                item.active = false;
            });
        } else {
            this.menus.forEach((item) => {
                if (item.url === this.router.url) {
                    this.setActiveMenu(item);
                }
            });
        }
    }

    generMenu(): void {
        this.menus = [];
        const authAccessConfig = this.load.getAccessUserConfig;
        if (this._setting.isRolesSystem && authAccessConfig.length < 1) {
            this.onExit();
        }
        this.menus.push({
            name: 'На главную',
            img: 'm_home_new',
            url: '/home',
            order: 0,
            active: true,
        });
        if (this._setting.isRolesSystem) {
            authAccessConfig.forEach((menu) => {
                switch (menu) {
                    case 'lk_access_appointment':
                        this.menus.push({
                            name: 'Запись к врачу',
                            img: 'm_rec_new',
                            url: '/home/rec',
                            order: 1,
                        });
                        break;
                    case 'lk_access_appointment_service':
                        this.menus.push({
                            name: 'Запись на услугу',
                            img: 'm_recService',
                            url: '/home/record-services',
                            order: 2,
                        });
                        break;
                    case 'lk_access_lab':
                        this.menus.push({
                            name: 'Лаборатория',
                            img: 'm_lab_new',
                            url: '/home/labs',
                            order: 4,
                        });
                        break;
                    case 'lk_access_payment':
                        this.menus.push({
                            name: 'Финансы',
                            img: 'm_fin_new',
                            url: '/home/payments',
                            order: 5,
                        });
                        break;
                    case 'lk_access_future_visit':
                    case 'lk_access_history':
                        this.menus.push({
                            name: 'Мои посещения',
                            img: 'm_history_new',
                            url: '/home/history',
                            order: 3,
                        });
                        break;
                    case 'lk_access_see_services':
                        this.menus.push({
                            name: 'Услуги',
                            img: 'm_serv_new',
                            url: '/home/services',
                            order: 7,
                        });
                        break;
                    case 'lk_access_documents':
                        this.menus.push({
                            name: 'Документы',
                            img: 'm_files',
                            url: '/home/patient-files',
                            order: 6,
                        });
                        break;
                }
            });
        } else {
            this.settingMenu.forEach((menu) => {
                switch (menu) {
                    case 'to-doctor':
                        this.menus.push({
                            name: 'Запись к врачу',
                            img: 'm_rec_new',
                            url: '/home/rec',
                            order: 1,
                        });
                        break;
                    case 'record-services':
                        this.menus.push({
                            name: 'Запись на услугу',
                            img: 'm_recService',
                            url: '/home/record-services',
                            order: 2,
                        });
                        break;
                    case 'laboratory':
                        this.menus.push({
                            name: 'Лаборатория',
                            img: 'm_lab_new',
                            url: '/home/labs',
                            order: 4,
                        });
                        break;
                    case 'payments':
                        this.menus.push({
                            name: 'Финансы',
                            img: 'm_fin_new',
                            url: '/home/payments',
                            order: 5,
                        });
                        break;
                    case 'h-doctor':
                    case 'history':
                        this.menus.push({
                            name: 'Мои посещения',
                            img: 'm_history_new',
                            url: '/home/history',
                            order: 3,
                        });
                        break;
                    case 'services':
                        this.menus.push({
                            name: 'Услуги',
                            img: 'm_serv_new',
                            url: '/home/services',
                            order: 7,
                        });
                        break;
                    case 'documents':
                        this.menus.push({
                            name: 'Документы',
                            img: 'm_files',
                            url: '/home/patient-files',
                            order: 6,
                        });
                        break;
                }
            });
        }
        this.menus = this.menus
            .filter(
                (item, index, self) =>
                    self.findIndex((t) => t.name === item.name) === index
            )
            .sort((a, b) => a.order - b.order);
    }

    setActiveMenu(menu: Imenu) {
        this.menus.forEach((item) => {
            item.active = menu.name === item.name;
        });
    }
    changeModalOpenStatus(status: boolean) {
        this.isMobileMenuOpen = status;
    }

    onExit() {
        this.auth.logout();
    }
}
