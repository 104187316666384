import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Store } from '../services/store.service';

@Injectable({
    providedIn: 'root',
})
export class TokenGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const token = Store.getTokenFromCookie();
        if (token) {
            return true;
        } else {
            this.auth.logout();
            return false;
        }
    }
}
