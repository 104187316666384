// authenticated-routes.ts
import { Route } from '@angular/router';

import { AccountComponent } from './components/account/account.component';
import { ServicesComponent } from './components/services/services.component';
import { LabsComponent } from './components/labs/labs.component';
import { RecComponent } from './components/rec/rec.component';
import { HistoryComponent } from './components/history/history.component';
import { VisitProtocolComponent } from './components/visit-protocol/visit-protocol.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { RecServicesComponent } from './components/rec-services/rec-services.component';
import { PatientFilesComponent } from './components/patient-files/patient-files.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { IndexComponent } from './components/home/index/index.component';

import { TokenGuard } from './guards/authGuard';

export const AuthenticatedRoutes: Route[] = [
    {
        path: '',
        component: IndexComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'account',
        component: AccountComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'services',
        component: ServicesComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'labs',
        component: LabsComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'rec',
        component: RecComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'history',
        component: HistoryComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'visit-protocol/:visitID/:formID',
        component: VisitProtocolComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'record-services',
        component: RecServicesComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'patient-files',
        component: PatientFilesComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'feedback',
        component: FeedbackComponent,
        canActivate: [TokenGuard],
    },
    {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [TokenGuard],
    },
];
