import { Component,  Input ,Output,EventEmitter} from '@angular/core';
import { IStaticFilter} from '../../../interfaces/record.interface';
import {
  expandedAnimation,
  rightPanelAnimation,
} from '../../../animations/height.animation';
import { lastValueFrom } from 'rxjs';
import { RecordService } from 'src/app/services/record.service';
import { AlertService } from 'src/lib/ariadna/alert';



@Component({
  selector: 'app-filter-component',
  templateUrl: './filter-component.html',
  styleUrls: ['./filter-component.scss'],
  animations: [expandedAnimation, rightPanelAnimation],

})

export class FilterComponent {   
  @Input() staticFilterSelected: number | null = null;; //выбранный элемент
  @Input() searchAmine: any; //анимация
  @Output() filterChanged = new EventEmitter<{typeEvent : string , data : any}>(); //изменения фильтра
  @Output() closeFilter = new EventEmitter<void>(); //кнопка закрытия фильтра

  filterList: IStaticFilter[] = [];
  filterComponentExpanded = false;
  isFilterVisible = true;  
  selectedCheckbox: number | null = null;
  constructor(
    private recordService : RecordService,
    private alertService: AlertService

  ) { }

  ngOnInit(): void {      
 
    this.getStaticFilterList();
    if (this.staticFilterSelected) {  
      this.onLabelClick(this.staticFilterSelected);
    }

  }

  getStaticFilterList() : void {
    lastValueFrom(this.recordService.getStacfilterList())
    .then((data: IStaticFilter[]) => {
        this.filterList = data        
        this.filterChanged.emit({typeEvent : 'getStacFilters' , data : this.filterList});
    })        
    .catch((error) => {
        this.alertService.error(error.msg || 'Не удалось загрузить список услуг по данному фильтру.')
    })
  }
  closeDropdown() {
    this.toggleDropdown();
  }
  clearFilter() {
    this.staticFilterSelected = null;
    this.filterChanged.emit({typeEvent : 'clearFilter' , data : null});
    this.closeFilter.emit();
    this.isFilterVisible = false; 
    
  }

  updateFilterList() {
    this.filterChanged.emit({ typeEvent: 'filterListUpdated', data: this.filterList });
  }



  toggleDropdown() {

    this.closeFilter.emit();
  }


  searchClick() {
    this.filterChanged.emit({ typeEvent: 'filterListUpdated', data: this.staticFilterSelected });
    this.closeFilter.emit();
  }

  onLabelClick(keyid: any) {
    this.staticFilterSelected = keyid    
}

selectFilter(keyid: number): void {
  this.filterComponentExpanded = !this.filterComponentExpanded;
  this.staticFilterSelected = keyid;
  this.selectedCheckbox = keyid  
}

}
