import { Component } from '@angular/core';
@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
    constructor() {}

    goBack() {
        window.history.back();
    }
}
