import { Component, Input, OnInit } from '@angular/core';
import { TPayContract } from 'src/app/interfaces/patient';
import { IDocuments } from 'src/app/interfaces/document.interface';
import { PatientService } from 'src/app/services/patient.service';
import { ConfigService } from 'src/app/services/application/config.service.';
import { AlertService } from 'src/lib/ariadna/alert';
import { lastValueFrom } from 'rxjs';
// import { IPatient } from 'src/app/interfaces/patient';
@Component({
    selector: 'app-sms',
    templateUrl: './sms.component.html',
    styleUrls: ['./sms.component.scss'],
})
export class SmsComponent implements OnInit {
    @Input() mobilePhone: string = '';
    private contractId: number | null = null;
    private messageId: number | null = null;
    private code: string = '';
    private smsText: string = '';
    countdown: number = 10;
    timer!: ReturnType<typeof setTimeout>;

    payContracts: TPayContract[] = [];
    contractsPayCountRec = 0;
    pin: string[] = ['', '', '', '', '', ''];
    signingDoc: boolean = false;
    loadingDocs = false;
    docCountRec = 0;
    documents: IDocuments[] = [];
    loadingContracts = false;
    loading = false; // Загрузка
    sms: string = '';
    contractName: string | undefined = '';
    isButtonDisabled: boolean = false;
    isSubmitBtnDisabled: boolean = false;
    selectedContract: number | null = null;

    constructor(private ps: PatientService, private configS: ConfigService, private alertService: AlertService) {}

    ngOnInit(): void {
        this.getPayContractList();
        this.contractsPayCountRec = 0;
        this.smsText = this.configS.getValue('sms_text');
    }

    get maskedPhone(): string {
        if (!this.mobilePhone) return '';
        const digitsOnly = this.mobilePhone.replace(/\D/g, '');
        return `+7(${digitsOnly.slice(1, 4)})***-**-${digitsOnly.slice(9, 12)}`;
    }

    private startResendTimer(): void {
        if (this.countdown) {
            const timer = setInterval(() => {
                this.countdown--;

                if (this.countdown <= 0) {
                    this.countdown = 10;
                    clearInterval(timer);
                    this.isButtonDisabled = false;
                }
            }, 1000);
            this.isButtonDisabled = true;
        }
    };
    clearForm(): void {
        this.pin = ['', '', '', '', '', ''];
        for (let i = 0; i < 5; i++) {
            const input = document.getElementById(
                `pin-input-${i}`
            ) as HTMLInputElement;
            if (input) {
                input.value = ''; // Очищаем значение каждого поля
            }
        }
    }
  

    backspace(inputIndex: number): void {
        let inputs: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName('input') as HTMLCollectionOf<HTMLInputElement>;
        if (inputIndex > 0 && inputIndex < inputs.length) {
            const currentInput = inputs[inputIndex];
            if (currentInput.value === '') {
                const previousInput = inputs[inputIndex - 1] as HTMLInputElement; 
                if (previousInput) {
                    previousInput.focus();
                };
            };
        };
    };

    handleKeyDown($event: any): void {
        
        if (!$event.target.value) {
            console.log($event.target)
            $event.target.previousElementSibling.focus();
        }   
    }

    moveToNext(inputIndex: number, e:any): void {
        const input = e.target as HTMLInputElement;
        const regex = /^[0-9]$/;
        if (e.inputType ==  "deleteContentBackward") {
            // console.log(123);
            if (input.value === '') {
                // Возврат фокуса на предыдущий ввод, если текущее поле пустое
                if (inputIndex > 0) {
                    const prevInput = document.getElementById(
                        `pin-input-${inputIndex - 1}`
                    ) as HTMLInputElement;
                    if (prevInput) {
                        prevInput.focus();
                    }
                }
            }
        } else if (regex.test(input.value)) {
            // console.log(event.keyid);

            if (inputIndex < this.pin.length - 1) {
                // Обратите внимание на использование this.pin.length
                const nextInput = document.getElementById(
                    `pin-input-${inputIndex + 1}`
                ) as HTMLInputElement;
                if (nextInput) {
                    nextInput.focus();
                }
            }
        } else {
            // Сброс значения, если ввели недопустимый символ
            input.value = '';
            this.pin[inputIndex] = ''; // Сброс значения массива pin, если это необходимо
        }
    }

    openNotSignContract(contractId: number) {
        this.selectedContract = contractId;
        const url = `${this.configS.getValue(
            'hostBackend'
        )}/contract/preview?patientId=${null}&contractId=${contractId}`;
        window.open(url, '_blank');
    };

    openSignContract(contractId: number) {
        this.selectedContract = contractId;
        const url = `${this.configS.getValue(
            'hostBackend'
        )}/contract/patient/docpdf?contractId=${contractId}`;
        window.open(url, '_blank');
    };

    submitSmsCode(): void {
        const pinCode = this.pin.join('');
        if (pinCode.length < 6) return;
        this.code = pinCode;

        this.clearForm();
        if (!this.messageId || !this.contractId) {
            this.alertService.error('Произошла ошибка')
            return;
        };
        lastValueFrom(this.ps.getContractConfirm(this.contractId, this.code, this.messageId))
        .then((res) => {
            if (res.statuscode === 500) {
                this.alertService.error(res.message);
                return false;
            };
            this.alertService.success('Договор подписан');
            this.backToList();
            return true;
        })
        .catch((e) => this.alertService.error(e))
        .finally(() => {
            this.getPayContractList();
        })
    };


    private getPayContractList(): void {
        this.ps.getPayContract().subscribe(
            (docs) => {
                this.payContracts = docs;
                this.docCountRec = this.documents.length;
                this.loadingDocs = false;
            },
            (err) => {
                this.docCountRec = 0;
                this.loadingDocs = false;
            }
        );
    };

    public getSms(): void {
        this.startResendTimer();
        this.ps.getSms(this.smsText).subscribe(
            (message) => {
                if (message) {
                    this.alertService.info('СМС отправлен');
                    this.messageId = message.response?.messageId;
                    return;
                };
            },
            (err) => {
                console.log(err);
                this.alertService.error(err.msg);
            }
        );
    };

    public disablePay(id: number): boolean {
        return true;
    };

    signDoc(id: number) {
        this.signingDoc = !this.signingDoc;
        this.contractId = id;
        this.contractName = this.payContracts.find(
            (contract) => contract.template_id === id
        )?.template_name;
        this.getSms();
    };

    backToList(): void {
        this.signingDoc = false;
    };

    ngDoCheck() {
        const pinCheck = this.pin.join('');
        pinCheck.length <= 5 ? this.isSubmitBtnDisabled = false : this.isSubmitBtnDisabled = true;
      };
}
