import { Component, OnInit, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '../../../interfaces/select';
import { boolean } from 'joi';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent)
    }
  ]
})

export class SelectComponent implements OnInit, ControlValueAccessor {
    @Input() options: SelectOption[] = [];
    @Input() value?: SelectOption;
    @Input() placeholder = 'Выберите из списка...';
    @Input() label?: string;
    @Input() required = false;
    @Input() theme: 'primary' | 'flat' = 'primary';

    @Output() onSelectionChange = new EventEmitter<SelectOption>();
    onChange = (value: any) => {}

    constructor() { }
    
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
    writeValue(obj: any): void {}

    ngOnInit() { }

    onSelectionChangeHandler(opt: SelectOption) {
        this.onChange(opt);
        this.onSelectionChange.emit(opt);
    }
}