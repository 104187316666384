// unauthenticated-routes.ts
import { Route } from '@angular/router';

import { LoginComponent } from './components/login/login.component';

export const UnauthenticatedRoutes: Route[] = [
    {
        path: 'recovery',
        component: LoginComponent,
    },
    {
        path: 'recovery/:tokenR',
        component: LoginComponent,
    },
];
