import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Size } from '../../services/size';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    @ViewChild('ContentBloc') contentBlocEL: ElementRef | undefined;

    patientName: string = '';

    onPatientNameChange(name: string) {
        this.patientName = name;
    }

    constructor(private size: Size, private router: Router) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.setH();
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                // По завершению загрузки
                //
                this.setH();
                //        this.findMenuActiveURL();
            }
        });
    }

    // Отслеживание изменений размера
    /*
  @HostListener('window:resize', ['$event'])
  private onResize(event: any) {
    this.size.setW(event.target.innerWidth)
  }
*/

    setH() {
        if (
            this.contentBlocEL &&
            this.contentBlocEL.nativeElement &&
            this.contentBlocEL.nativeElement.querySelectorAll(
                '.cls-block-header'
            )
        ) {
            const elementsHeader =
                this.contentBlocEL.nativeElement.querySelectorAll(
                    '.cls-block-header'
                );
            if (elementsHeader.length > 0) {
                this.size.setH({
                    outletH: this.contentBlocEL.nativeElement.offsetHeight,
                    blockHeaderH: elementsHeader[0].clientHeight,
                });
            } else {
                this.size.setH({
                    outletH: this.contentBlocEL.nativeElement.offsetHeight,
                    blockHeaderH: null,
                });
            }
        } else {
            if (this.contentBlocEL) {
                this.size.setH({
                    outletH: this.contentBlocEL.nativeElement.offsetHeight,
                    blockHeaderH: null,
                });
            }
        }
    }
}
