import { Injectable } from '@angular/core';
import { NgxCookieConsentComponent } from '@localia/ngx-cookie-consent';
import { NgxCookieConsentConfigService } from '@localia/ngx-cookie-consent/lib/config/ngx-cookie-consent-config.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class CookieConsentService {
    constructor(
        private cookieConsent: NgxCookieConsentComponent,
        private cookieService: CookieService
    ) {}
    public acceptAllCookies() {
        this.cookieConsent.acceptAllCookies();
    }

    setCookie(cookieName: string, cookieValue: string, specificPath?: string) {
        this.cookieService.set(cookieName, cookieValue, 7);
    }

    deleteCookie(cookieName: string) {
        this.cookieService.delete(cookieName);
    }

    deleteAll(specificPath?: string) {
        this.cookieService.deleteAll(specificPath);
    }
    getCookie(cookieName: string) {
        this.cookieService.get(cookieName);
    }
    getAllCookies() {
        this.cookieService.getAll();
    }
    checkCookieExists(cookieName: string, specificPath?: string): boolean {
        return this.cookieService.check(cookieName);
    }
}

export const serviceObject: NgxCookieConsentConfigService = {
    showLanguageSwitcher: false,
    customLanguage: {
        languageKey: 'ru',
        languageName: 'Русский',
        customIconPath: './assets/img/ru.png',
        translations: {
            language: 'Русский',
            title: 'Мы ценим вашу конфиденциальность.',
            text: 'Мы используем cookie-файлы для доступа к ресурсам и функциональным возможностям Личного кабинета.',
            right_bottom:
                'Вы можете изменить/отозвать это согласие в любое время, нажав на кнопку "Настройки" в правом нижнем углу страницы.',
            left_bottom:
                'Вы можете изменить/отозвать это согласие в любое время, нажав на кнопку "Настройки" в левом нижнем углу страницы.',
            right_top:
                'Вы можете изменить/отозвать это согласие в любое время, нажав на кнопку "Настройки" в правом верхнем углу страницы.',
            left_top:
                'Вы можете изменить/отозвать это согласие в любое время, нажав на кнопку "Настройки" в левом верхнем углу страницы.',
            privacy_text: 'Политика конфиденциальности',
            imprint_text: 'Информация о сайте',
            privacy_settings_text: 'Настройки конфиденциальности',
            privacy_settings_title: 'Настройки конфиденциальности',
            privacy_settings_title_text:
                'С помощью этого инструмента вы можете выбрать и отключить различные файлы cookie, трекеры и инструменты анализа, используемые на этом сайте.',
            functional_title: 'Функциональные',
            functional_description:
                'Эти файлы cookie позволяют нам анализировать использование нашего сайта, чтобы измерить его производительность и улучшить его.',
            marketing_title: 'Маркетинговые',
            marketing_description:
                'Маркетинговые файлы cookie обычно используются для отображения вам рекламы, которая соответствует вашим интересам. Если вы посетите другой сайт, файл cookie в вашем браузере будет распознан, и вам будет отображаться реклама, основанная на информации, хранящейся в этом файле cookie (ст. 6 пункт 1 часть a) GDPR).',
            essential_title: 'Обязательные',
            essential_description:
                'Эти файлы cookie необходимы для основных функций нашего сайта.',
            other_title: 'Другие',
            other_description:
                'В нашей организации мы используем другие инструменты для обработки данных. Они перечислены здесь для информационных целей. Данные собираются только после определенной взаимодействия, например, если вы согласитесь на демонстрацию. Если вы хотите отказаться от обработки данных этими обработчиками, пожалуйста, обратитесь к нам.',
            save_text: 'Сохранить',
            accept_text: 'Принять и закрыть',
            decline_text: 'Отклонить',
            choose_language_text: 'Выберите язык',
            back_text: 'Назад',
            cookie_name: 'Название',
            cookie_description: 'Описание',
            cookie_duration: 'Срок действия',
        },
    },
    defaultLanguage: 'ru',
    openerPosition: 'left-bottom',
};
