import { LoadService } from 'src/app/services/application/load.service';
import { Imenu } from './../../../interfaces/menu.interface';
import { Component, OnInit } from '@angular/core';
import { Size } from '../../../services/size';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
    private _setting: any;
    private authSetting: any;
    private settingMenu: String[];
    panelsMenu: Imenu[] = []; // Список блоков меню

    /* Создание компонента и загрузка меню */
    constructor(private load: LoadService, private title: Title) {
        this._setting = load.configData.pages.home;
        this.authSetting = load.configData.pages.login;
        if (this._setting && this._setting.title) {
            this.title.setTitle(this._setting.title);
        }

        if (
            load.configData.menu.items &&
            load.configData.menu.items.length > 5
        ) {
            this.settingMenu = load.configData.menu.items.split(',');
        } else {
            this.settingMenu = [];
        }
    }

    ngOnInit(): void {
        this.getMenu();
    }

    /* Получение меню */
    getMenu(): void {
        /* Параметры
    1) Запись к врачу  - to-doctor
    2) Лаборатория     -  laboratory
    3) Финансы             - payments
    4) Мои посещения  -  h-doctor && history
    5) Услуги                - services

     */
        this.panelsMenu = [];
        const authAccessConfig = this.load.getAccessUserConfig;
        if (this.authSetting.isRolesSystem) {
            authAccessConfig.forEach((menu) => {
                switch (menu) {
                    case 'lk_access_appointment':
                        this.panelsMenu.push({
                            name: 'Запись к врачу',
                            img: 'm_rec_panel.png',
                            url: '/home/rec',
                            order: 1,
                        });
                        break;
                    case 'lk_access_appointment_service':
                        this.panelsMenu.push({
                            name: 'Запись на услугу',
                            img: 'registrationServices.svg',
                            url: '/home/record-services',
                            order: 2,
                        });
                        break;
                    case 'lk_access_lab':
                        this.panelsMenu.push({
                            name: 'Лаборатория',
                            img: 'm_lab_panel.png',
                            url: '/home/labs',
                            order: 4,
                        });
                        break;
                    case 'lk_access_payment':
                        this.panelsMenu.push({
                            name: 'Финансы',
                            img: 'm_fin_panel.png',
                            url: '/home/payments',
                            order: 5,
                        });
                        break;
                    case 'lk_access_future_visit':
                    case 'lk_access_history':
                        this.panelsMenu.push({
                            name: 'Мои посещения',
                            img: 'm_history_panel.png',
                            url: '/home/history',
                            order: 3,
                        });
                        break;
                    case 'lk_access_see_services':
                        this.panelsMenu.push({
                            name: 'Услуги',
                            img: 'm_serv_panel.png',
                            url: '/home/services',
                            order: 7,
                        });
                        break;
                    case 'lk_access_documents':
                        this.panelsMenu.push({
                            name: 'Документы',
                            img: 'm_doc_panel.png',
                            url: '/home/patient-files',
                            order: 6,
                        });
                        break;
                }
            });
        } else {
            this.settingMenu.forEach((menu) => {
                switch (menu) {
                    case 'to-doctor':
                        this.panelsMenu.push({
                            name: 'Запись к врачу',
                            img: 'm_rec_panel.png',
                            url: '/home/rec',
                            order: 1,
                        });
                        break;
                    case 'record-services':
                        this.panelsMenu.push({
                            name: 'Запись на услугу',
                            img: 'registrationServices.svg',
                            url: '/home/record-services',
                            order: 2,
                        });
                        break;
                    case 'laboratory':
                        this.panelsMenu.push({
                            name: 'Лаборатория',
                            img: 'm_lab_panel.png',
                            url: '/home/labs',
                            order: 4,
                        });
                        break;
                    case 'payments':
                        this.panelsMenu.push({
                            name: 'Финансы',
                            img: 'm_fin_panel.png',
                            url: '/home/payments',
                            order: 5,
                        });
                        break;
                    case 'h-doctor':
                    case 'history':
                        this.panelsMenu.push({
                            name: 'Мои посещения',
                            img: 'm_history_panel.png',
                            url: '/home/history',
                            order: 3,
                        });
                        break;
                    case 'services':
                        this.panelsMenu.push({
                            name: 'Услуги',
                            img: 'm_serv_panel.png',
                            url: '/home/services',
                            order: 7,
                        });
                        break;
                    case 'documents':
                        this.panelsMenu.push({
                            name: 'Документы',
                            img: 'm_doc_panel.png',
                            url: '/home/patient-files',
                            order: 6,
                        });
                        break;
                }
            });
        }
        this.panelsMenu = this.panelsMenu
            .filter(
                (item, index, self) =>
                    self.findIndex((t) => t.name === item.name) === index
            )
            .sort((a, b) => a.order - b.order);

        //legacy govna(полного)
    }
}
