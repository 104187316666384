<div class="app__mobile-page services-mobile">
    <app-mobile-feed-page-template
        [count-of-pages]="countOfPages"
        [loaded-pages]="loadedPages"
        [is-loading]="loadingMoreContent"
        [root-url]="'home/services'"
        [view-page]="viewPage"
        [pages]="this.servicesPages"
        [no-more-content-text]="'Услуг больше нет.'"
        (load-next-page)="loadNextPage()"
        *ngIf="!isLoading; else pageLoader"
    >
        >
        <div header class="services-mobile__header">
            <span
                class="services-mobile__period-text"
                (click)="showPeriodDialog()"
            >
                {{ periodText }}
            </span>
            <span
                class="services-mobile__button"
                *ngIf="periodText != 'Услуги за всё время'"
                (click)="btnFilterByDay(null)"
            >
                <img src="./assets/img/svg/krest-blue.svg" alt="Очистить" />
            </span>
        </div>

        <section
            page
            [id]="'page' + (pageIndex + 1)"
            class="services-mobile__page"
            *ngFor="let servicesPage of servicesPages; let pageIndex = index"
        >
            <app-feed-page-anchor
                [is-hidden]="pageIndex === 0"
                (intersected)="viewPage = pageIndex + 1"
            >
                Страница {{ pageIndex + 1 }} из {{ countOfPages }}
            </app-feed-page-anchor>

            <section
                class="card"
                *ngFor="let serv of servicesPage; let i = index"
                (click)="showDetailsDialog(serv)"
            >
                <div class="card__head">
                    <span class="card__day">{{
                        getNameDay(serv.dtSort) | titlecase
                    }}</span>
                    <span class="card__date"
                        >{{ getDayWithYear(serv.dtSort) }} в
                        {{ getTime(serv.dtSort) }}</span
                    >
                </div>

                <div class="card__body">
                    <div class="card__content">
                        {{
                            serv.spec_name
                                ? serv.spec_name
                                : "Специалист отсутствует"
                        }}
                    </div>
                </div>

                <div class="card__footer">
                    {{
                        serv.ps_price
                            ? (serv.ps_price | priceSpace : ".2") + " руб"
                            : "Бесплатно"
                    }}
                </div>
            </section>
        </section>

        <div fab class="app__calendar-btn" (click)="showPeriodDialog()">
            <mat-icon svgIcon="calendar"></mat-icon>
        </div>
    </app-mobile-feed-page-template>
</div>

<ng-template #pageLoader>
    <div class="loader">
        <loader
            type="page"
            [isFullscreen]="true"
            message="Загрузка списка услуг"
        >
        </loader>
    </div>
</ng-template>

<div class="services-pc">
    <div class="services-pc__header">
        <span class="services-pc__header-text">Услуги</span>
        <div class="services-pc__header-filters">
            <button
                class="services-pc__btn-filter btn"
                (click)="btnFilterTekPC()"
            >
                за сегодня
            </button>
            <button
                class="services-pc__btn-filter btn"
                (click)="btnFilter3DayPC()"
            >
                за 3 дня
            </button>
            <button
                class="services-pc__btn-filter btn"
                (click)="btnFilterOrderUpPC()"
            >
                {{ orderUPText }}
                <mat-icon
                    *ngIf="orderUP == false"
                    svgIcon="arrow_down"
                    class="cls-btn-icon services-pc__btn-filter-icon-down"
                ></mat-icon>
                <mat-icon
                    *ngIf="orderUP == true"
                    svgIcon="arrow_up"
                    class="cls-btn-icon services-pc__btn-filter-icon-down"
                ></mat-icon>
            </button>
        </div>
    </div>
    <div class="services-pc__body">
        <div *ngIf="loadingServicesList" class="loading">
            <div class="loader-container">           
            <loader type="page"> </loader>
        </div>
            <!-- <img src="./assets/loading-rec.gif" alt="Загрузка..." /> -->
        </div>
        <app-panel-table-pagination
            nameBlock="Список услуг"
            (onChangePeriod)="changePeriodPC($event)"
            [dtBegin]="dtBegin"
            [dtEnd]="dtEnd"
            textItogo="услуг"
            [CountRec]="servicesListLength"
            [CountRecPage]="countRectoPage"
            (onChangedPage)="changedPagePC($event)"
        >
            <ng-container
                *ngIf="servicesListLength > 0; then showTable; else showNotRec"
            ></ng-container>

            <ng-template #showNotRec>
                <div class="cls-not-rec">Нет услуг</div>
            </ng-template>

            <ng-template #showTable>
                <div class="service-table">
                    <span class="service-table__title">Дата услуги</span>
                    <span class="service-table__title"
                        >Наименование услуги</span
                    >
                    <span class="service-table__title">Прием</span>
                    <span class="service-table__title">Сумма</span>
                    <span class="service-table__title"
                        >Специальность врача</span
                    >
                    <span class="service-table__title">ФИО врача</span>
                    <ng-container
                        *ngFor="let serv of servicesList; let i = index"
                    >
                        <span
                            class="service-table__block-year"
                            *ngIf="isBlockYearPC(i)"
                        >
                            {{ getYearPC(i) }}
                        </span>
                        <div class="service-table__text-date">
                            <div class="service-table__text-date-container">
                                <span
                                    class="service-table__text-date-container-day"
                                    >{{ getTekDayPC(serv.dtSort) }}
                                    в&nbsp;
                                </span>
                                <span
                                    class="service-table__text-date-container-time"
                                    >{{ getTimePC(serv.dtSort) }}</span
                                >
                            </div>
                            <span class="service-table__text-date-name">
                                {{ getNameDayPC(serv.dtSort) }}
                            </span>
                        </div>
                        <span class="service-table__name service-table__text">
                            {{ serv.sd_name }}
                        </span>
                        <span class="service-table__place service-table__text">
                            {{ serv.place }}
                        </span>
                        <span class="service-table__price service-table__text">
                            {{ serv.ps_price | priceSpace : ".2" }} руб
                        </span>
                        <span class="service-table__spec service-table__text">
                            {{ serv.spec_name }}
                        </span>
                        <span class="service-table__doc service-table__text">
                            {{ serv.ps_docexec }}
                        </span>
                    </ng-container>
                </div>
            </ng-template>
        </app-panel-table-pagination>
    </div>
</div>
