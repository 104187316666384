<section class="feed">
    <div class="feed__header">
        <ng-content select="[header]"></ng-content>
    </div>

    <ng-container *ngIf="isFeedEmpty">
        <div class="feed__empty">{{ feedEmptyText }}</div>
    </ng-container>

    <scroll-view class="feed__body">
        <ng-content select="[page]"></ng-content>

        <div
            class="no-more-content card card_blue no-more-content__text"
            *ngIf="
                this.pages.length > 0 &&
                this.loadedPages / this.countOfPages === 1 &&
                !this.isLoading
            "
        >
            {{ noMoreContentText }}
        </div>

        <div class="more-loader" *ngIf="loadedPages / countOfPages < 1">
            <div
                id="load-more"
                class="more-loader__observer"
                *ngIf="pages && !isLoading && loadedPages / countOfPages !== 1"
                observeVisibility
                [debounceTime]="100"
                (onVisible)="startLoading($event)"
            ></div>
            <div class="more-loader__loader">
                <!-- <img src="./assets/loading-rec.gif" alt="Загрузка..." /> -->
                <loader type="component"> </loader>
            </div>
        </div>

        <ng-content select="[fab]"></ng-content>
    </scroll-view>
    <div class="feed__footer pagination" *ngIf="countOfPages > 0">
        <a type="button" (click)="prevPage()">
            <div
                class="pagination__btn pagination__btn_left"
                [ngClass]="{ pagination__btn_disabled: viewPage === 1 }"
            >
                <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.64471 0.368328C7.75733 0.484325 7.84669 0.622106 7.90765 0.773786C7.96862 0.925467 8 1.08807 8 1.25228C8 1.41649 7.96862 1.57909 7.90765 1.73077C7.84669 1.88245 7.75733 2.02023 7.64471 2.13623L2.93089 7.00109L7.64471 11.866C7.87187 12.1004 7.99949 12.4184 7.99949 12.7499C7.99949 13.0815 7.87187 13.3994 7.64471 13.6339C7.41755 13.8683 7.10945 14 6.7882 14C6.46695 14 6.15886 13.8683 5.9317 13.6339L0.35529 7.87877C0.242664 7.76278 0.153311 7.625 0.0923452 7.47332C0.0313797 7.32164 0 7.15904 0 6.99482C0 6.83061 0.0313797 6.66801 0.0923452 6.51633C0.153311 6.36465 0.242664 6.22687 0.35529 6.11087L5.9317 0.35579C6.39336 -0.120666 7.1709 -0.120666 7.64471 0.368328Z"
                        fill="#9AACDB"
                    />
                </svg>
            </div>
        </a>
        <div class="pagination__page">
            {{ viewPage }} страница из {{ countOfPages }}
        </div>

        <a type="button" (click)="nextPage()">
            <div
                class="pagination__btn pagination__btn_right"
                [ngClass]="{
                    pagination__btn_disabled: viewPage == countOfPages
                }"
            >
                <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.355291 0.368328C0.242666 0.484325 0.153312 0.622106 0.0923468 0.773786C0.0313812 0.925467 0 1.08807 0 1.25228C0 1.41649 0.0313812 1.57909 0.0923468 1.73077C0.153312 1.88245 0.242666 2.02023 0.355291 2.13623L5.06911 7.00109L0.355291 11.866C0.128132 12.1004 0.000514428 12.4184 0.000514428 12.7499C0.000514428 13.0815 0.128132 13.3994 0.355291 13.6339C0.582451 13.8683 0.890546 14 1.2118 14C1.53305 14 1.84114 13.8683 2.0683 13.6339L7.64471 7.87877C7.75734 7.76278 7.84669 7.625 7.90765 7.47332C7.96862 7.32164 8 7.15904 8 6.99482C8 6.83061 7.96862 6.66801 7.90765 6.51633C7.84669 6.36465 7.75734 6.22687 7.64471 6.11087L2.0683 0.35579C1.60664 -0.120666 0.829104 -0.120666 0.355291 0.368328Z"
                        fill="#9AACDB"
                    />
                </svg>
            </div>
        </a>
    </div>
</section>
