/* Компонент вывода и выбора талонов раздела "Запись к врачу" мобильной версии */
import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    HostListener,
} from '@angular/core';
import {
    IRecTalon,
    IRecTalonInit,
    ITalonModal,
    ITalonsDaySetParams,
} from '../../../../../interfaces/record.interface';
import { RecordService } from '../../../../../services/record.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';

import { NewTalonComponentMobile } from '../../../../../modals/new-talon-mobile/talon.component';
import { CalendarService } from '../../../../../services/calendar.service';
import { AlertService } from 'src/lib/ariadna/alert';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-mobile-talons',
    //  changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './mobile-talons.component.html',
    styleUrls: ['./mobile-talons.component.scss'],
})
export class MobileTalonsComponent implements OnInit {
    //  @Input() talonList: IRecTalon[] = [];     // Массов дней - активный
    @Output() onClickTalon = new EventEmitter<string>(); // талон
    paramsData: IRecTalonInit | null = null; // паарметры инициализации
    talonList: IRecTalon[] = []; // Массов дней - активный
    talonListDay: IRecTalon[] = []; // Массов дней - активный
    selectedDate: Date | null = null; // Выбрана дата
    headerTxt = ''; // Заголовок
    selectedTalon: IRecTalon | null = null; // выбранный талон
    nextDate: Date | null | undefined = null; // Следующая дата
    prevDate: Date | null | undefined = null; // Предыдущая дата
    depId: number | 0 = 0;
    error: string | null = null;

    isNotDate: boolean = false; // Нет данных
    classApplied = false;
    blokCalendaryStyleHeight = { height: 'auto' };
    calendarEnabled: boolean = true;

    /* Создание компонента */
    constructor(
        private rs: RecordService,
        private dialog: MatDialog,
        private alertService: AlertService,
        private cs: CalendarService //             ,private cdREF: ChangeDetectorRef
    ) {
        rs.TalonsOnInit$().subscribe((n) => {
            // console.log(n);
            this.initTalons(n);
        });

        rs.TalonsDayOnInit$().subscribe((n) => {
            // Фильтровать по дню
            this.initDay(n);
        });
    }

    ngOnInit(): void {}

    /* ПОлучение списка талонов */
    initTalons(t: IRecTalonInit) {
        this.paramsData = t;
        this.talonList = this.paramsData.talons;
    }

    /* Получение текущей даты в фильтре */
    initDay(data: ITalonsDaySetParams) {
        // Фильтр по дню
        this.selectedDate = data.dt;
        const depId = data.dep && data.dep.keyid > 0 ? data.dep.keyid : 0;
        this.loadDate(depId);
    }

    /* Фильтрация талонов по дате и филиалу */
    loadDate(depId: number) {
        const dateFilter = Number(moment(this.selectedDate).format('YYYYMMDD'));
        this.headerTxt = `${moment(this.selectedDate).format('DD MMMM')}`;
        // console.log(this.talonList);
        this.talonListDay = this.talonList.filter(
            (item) =>
                item.dtFilter === dateFilter &&
                (item.depid === depId || depId === 0)
        );
        // console.log(this.talonList);
        const prevDateDay = this.talonList.filter(
                (item) =>
                    Number(item.dtFilter) < dateFilter &&
                    (item.depid === depId || depId === 0)
            ),
            nextDateDay = this.talonList.filter(
                (item) =>
                    Number(item.dtFilter) > dateFilter &&
                    (item.depid === depId || depId === 0)
            );

        this.prevDate = prevDateDay.length
            ? prevDateDay[prevDateDay.length - 1].dtBegin
            : null;
        this.nextDate = nextDateDay.length ? nextDateDay[0].dtBegin : null;

        const dates = this.talonList
            .map((item) => item.dtFilter)
            .filter((date, index, self) => {
                return self.indexOf(date) === index;
            });

        const months = this.talonList
            .map((item) => String(item.dtFilter).substr(0, 6))
            .filter((month, index, self) => {
                return self.indexOf(month) === index;
            });

        this.calendarEnabled = dates.length > 1;

        this.cs.setParams({
            year_begin: Number(moment().format('YYYY')),
            mounth_begin: Number(moment().format('MM')),
            count: months.length,
            cdActive: dates.map((date) => ({
                date: date !== undefined ? date : 0,
            })),
            orderByStart: true,
            MinDate: {
                m: Number(moment().format('MM')),
                y: Number(moment().format('YYYY')),
            },
            selectDate: '',
        });
        this.cs.setSelectedDate(moment(this.selectedDate).format('YYYY-MM-DD'));
    }

    /* Форматирование даты талона */
    formatTalon(dt: Date | null | undefined): string {
        if (dt) {
            return moment(dt).format('HH:mm');
        } else {
            return '';
        }
    }

    /* Выбор даты в календаре талонов */
    clickDate(date: Date | null | undefined) {
        // console.log(date);
        if (!date) {
            return;
        }
        this.selectedDate = date;
        this.loadDate(this.depId);
    }

    /* Выбор талона */
    async clickTalon(talon: IRecTalon) {
        // console.log(talon);
        let data: ITalonModal = {
            rnumbID: null,
            info: {
                docdepID: talon.docdepid,
                beginDate: talon.dtBegin,
            },
            srv: this.paramsData!.srv,
            paramCansel: false,
        };
        try {
            // console.log(talon);
            if (
                talon.is_interval &&
                talon.is_interval > 0 &&
                talon.interval_id
            ) {
                const { guid, err_code, err_text } = await lastValueFrom(
                    this.rs.getRnumbCreateInterval(
                        talon.interval_id,
                        talon.dat_begin_str,
                        talon.dat_end_str
                    )
                );
                if (err_code !== 0 || !guid) throw new Error(err_text);

                data.rnumbID = guid;
            } else if (talon.rnumbid) {
                data.rnumbID = talon.rnumbid;
            }

            const dialogRefOptions = {
                width: '100vw',
                height: 'auto',
                maxWidth: '100vw',
                minWidth: '100vw',
                closeOnNavigation: true,
                data,
            };

            const modalRef = this.dialog.open(
                NewTalonComponentMobile,
                dialogRefOptions
            );
        } catch (error: any) {
            console.error(`Не удалось создать интервал: ${error.msg}`);
            this.alertService.error(
                `Не удалось записаться на номерок: ${error.msg}`
            );
        }
    }

    /* Блокировка выбора талона */
    talonDisable(talon: IRecTalon): boolean {
        // console.log(this.paramsData);
        if (!this.paramsData?.srv) return false;

        if (!this.paramsData?.srv?.is_telemed) return false;

        return true;
    }

    /* Выбор даты на календаре */
    calendarClick(dt: string) {
        const date = new Date(dt);
        // console.log(date, this.selectedDate);

        if (this.selectedDate != date) {
            this.selectedDate = date;

            this.selectedDate = date;
            this.cs.setSelectedDate(dt);
            this.loadDate(this.depId);
        }
        this.toggleClass();
    }

    /* Открытие/скрытие календаря */
    toggleClass() {
        if (!this.calendarEnabled) {
            return;
        }
        this.classApplied = !this.classApplied;
        const element =
            document.getElementsByClassName('calendar-container')[0];
        if (this.classApplied === true && element) {
            element.classList.add('calendar-active');
        } else if (element) {
            element.classList.remove('calendar-active');
        }
    }

    /* Вывод ошибки */
    showError(text: string) {
        this.error = text;
    }

    /* Скрытие ошибки */
    hideError() {
        this.error = null;
    }

    //свайп в мл=обиле
    defaultTouch = { x: 0, y: 0, time: 0 };

    @HostListener('touchstart', ['$event'])
    //@HostListener('touchmove', ['$event'])
    @HostListener('touchend', ['$event'])
    @HostListener('touchcancel', ['$event'])
    handleTouch(event: {
        touches: any[];
        changedTouches: any[];
        type: string;
        timeStamp: number;
    }) {
        let touch = event.touches[0] || event.changedTouches[0];

        // check the events
        if (event.type === 'touchstart') {
            this.defaultTouch.x = touch.pageX;
            this.defaultTouch.y = touch.pageY;
            this.defaultTouch.time = event.timeStamp;
        } else if (event.type === 'touchend') {
            let deltaX = touch.pageX - this.defaultTouch.x;
            let deltaY = touch.pageY - this.defaultTouch.y;
            let deltaTime = event.timeStamp - this.defaultTouch.time;

            // simulte a swipe -> less than 500 ms and more than 60 px
            if (deltaTime < 500) {
                // touch movement lasted less than 500 ms
                if (Math.abs(deltaX) > 60) {
                    // delta x is at least 60 pixels
                    if (deltaX > 0) {
                        this.doSwipeRight(event);
                    } else {
                        this.doSwipeLeft(event);
                    }
                }

                if (Math.abs(deltaY) > 60) {
                    // delta y is at least 60 pixels
                    if (deltaY > 0) {
                        this.doSwipeDown(event);
                    } else {
                        this.doSwipeUp(event);
                    }
                }
            }
        }
    }

    doSwipeLeft(event: any) {
        this.clickDate(this.nextDate);
    }

    doSwipeRight(event: any) {
        this.clickDate(this.prevDate);
    }

    doSwipeUp(event: any) {}

    doSwipeDown(event: any) {}
}
