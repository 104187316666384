<div class="app-header cls-header">
    <div class="app-header__left-side">
        <a routerLink="/" class="app-header__logo">
            <img src="./assets/img/header_logo.png" alt="ariadna-logo" />
        </a>

        <div class="patient__name">
            {{ patientName }}
        </div>
    </div>
    <div class="app-header__title">
        <ng-container *ngIf="routerDynamicLink == '/home/account'"
            >Личная информация</ng-container
        >
        <ng-container *ngIf="routerDynamicLink == '/home/history'"
            >Мои посещения</ng-container
        >
        <ng-container *ngIf="routerDynamicLink == '/home/services'"
            >Услуги</ng-container
        >
        <ng-container *ngIf="routerDynamicLink == '/home/payments'"
            >Финансы</ng-container
        >

        <ng-container *ngIf="routerDynamicLink == '/home/labs'"
            >Лаборатория</ng-container
        >

        <ng-container *ngIf="routerDynamicLink == '/home/rec'"
            >Запись</ng-container
        >
        <ng-container *ngIf="routerDynamicLink == '/home/record-services'"
            >Запись на услугу</ng-container
        >
        <ng-container *ngIf="routerDynamicLink == '/home/patient-files'">
            Мои документы
        </ng-container>
    </div>
    <div class="app-header__right-side">
        <button
            *ngIf="pricelistExternalUrl"
            (click)="showPricelist()"
            class="app-header__pricelist"
        >
            <a>Стоимость услуги</a>
            <img src="./assets/img/svg/pricelist.svg" alt="Услуги" />
        </button>

        <a
            class="app-header__help"
            href="./assets/manual.pdf"
            target="_blank"
            alt="Помощь"
        >
            <img src="./assets/img/svg/help.svg" />
        </a>

        <div
            ngbDropdown
            class="app-header__dropdown dropdown"
            #btnDropDown
            (openChange)="openChange($event)"
        >
            <button
                class="btn dropdown__toggle"
                [ngClass]="btnDropUP ? 'dropdown__toggle--active' : ''"
                id="dropdownBasic"
                ngbDropdownToggle
            >
                {{ patient?.firstname }}
            </button>
            <div
                ngbDropdownMenu
                aria-labelledby="dropdownBasic"
                class="cls-block-menu-list"
            >
                <button
                    *ngIf="isWorkPersonalData"
                    ngbDropdownItem
                    routerLink="/home/account"
                >
                    <div class="menu-container">
                        <p>Личные&nbsp;данные</p>
                        <mat-icon
                            svgIcon="lk"
                            class="cls-icon-menu-item"
                        ></mat-icon>
                    </div>
                </button>
                <button
                    ngbDropdownItem
                    (click)="showStatement()"
                    *ngIf="AccessStatement"
                >
                    <div class="menu-container">
                        <p>Справка&nbsp;в&nbsp;налоговую</p>
                        <mat-icon
                            class="cls-icon-menu-item"
                            svgIcon="nalog"
                        ></mat-icon>
                    </div>
                </button>

                <button
                    ngbDropdownItem
                    (click)="exitFamilyAccount()"
                    *ngIf="withFamilyAccess && !isMainAccount"
                >
                    <div class="menu-container">
                        <p>Возврат&nbsp;в&nbsp;свой&nbsp;кабинет</p>
                        <mat-icon
                            class="cls-icon-menu-item"
                            svgIcon="family"
                        ></mat-icon>
                    </div>
                </button>
                <button
                    ngbDropdownItem
                    (click)="showFamilyList()"
                    *ngIf="withFamilyAccess && isMainAccount"
                >
                    <div class="menu-container">
                        <p>Кабинет&nbsp;родственника</p>
                        <mat-icon
                            class="cls-icon-menu-item"
                            svgIcon="family"
                        ></mat-icon>
                    </div>
                </button>

                <button
                    ngbDropdownItem
                    routerLink="/home/feedback"
                    *ngIf="withFeedback"
                >
                    <div class="menu-container">
                        <p>Обратная&nbsp;связь</p>
                        <mat-icon
                            class="cls-icon-menu-item"
                            svgIcon="feedback"
                        ></mat-icon>
                    </div>
                </button>
                <button
                    ngbDropdownItem
                    *ngIf="pricelistExternalUrl"
                    (click)="showPricelist()"
                    class="pricelist-button"
                >
                    <div class="menu-container">
                        <a>Стоимость услуги</a>
                        <img
                            src="./assets/img/svg/pricelist.svg"
                            alt="Услуги"
                        />
                    </div>
                </button>
                <button ngbDropdownItem (click)="onExit()">
                    <div class="menu-container">
                        <p>Выход&nbsp;из&nbsp;кабинета</p>
                        <mat-icon
                            class="cls-icon-menu-item"
                            svgIcon="exit"
                        ></mat-icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
