import { Injectable } from '@angular/core';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { IRnumbList } from '../interfaces/rnumb.interface';
import { IServ } from '../interfaces/record.interface';

@Injectable({
    providedIn: 'root',
})
export class RnumbService {
    constructor(private httpNew: HttpNewService, private auth: AuthService) {}

    /**
     * Список талонов
     * @param parameters
     */
    public getRnumbList(): Observable<IRnumbList[]> {
        let url = '/rnumb/list';
        return this.httpNew.get(url, null);
    }

    /**
     * Услуги на талон талонов
     * @param parameters
     */
    public getRnumbSrv(rnumbID: number): Observable<IServ> {
        let url = '/record/rnumb/serv';
        url = url + `?rnumbID=${rnumbID}`;
        return this.httpNew.get(url, null);
    }
}
