<section class="modal">
    <div class="header modal__header">
        <img
            class="header__img"
            src="{{ getDocImg(doc.doctorid) }}"
            (error)="getDocImgDefault($event)"
        />
        <div class="info header__info">
            <h5 class="info__name">
                {{ doc.l_name | titlecase }} {{ doc.f_name | titlecase }}
                {{ doc.s_name | titlecase }} 
            </h5>
            <span class="info__spec">{{ spec }}</span>
        </div>
    </div>

    <div class="modal__body">
        <section class="card" *ngFor="let info of doc.info">
            <p class="card__title">{{ info.queston }}</p>
            <ng-container *ngIf="info.answer.indexOf('http') === 0; else text">
                <a class="card__text" href="{{ info.answer }}">{{
                    info.answer
                }}</a>
            </ng-container>
            <ng-template #text>
                <span class="card__text">{{ info.answer }}</span>
            </ng-template>
        </section>
    </div>

    <button class="modal__close" (click)="closeModal($event)"></button>
</section>
