<section class="modal">
    <div class="header modal__header">
      
        <div class="info header__info">
            <h5 class="info__name">
                {{docSrv.text}}
            </h5>
        </div>
    </div>

    <div class="modal__body">
        {{docSrv.description}}
    </div>

    <button class="modal__close" (click)="closeModal($event)"></button>
</section>
