import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    IDogList,
    IDogSignatureRes,
    IPatient,
    ITokenAndPatientId,
} from '../../../interfaces/patient';
import { AuthService } from '../../../services/auth.service';
import { ConfigService } from '../../../services/application/config.service.';
import { PatientService } from '../../../services/patient.service';
import { Router } from '@angular/router';
import { CookieConsentService } from 'src/app/services/cookie-consent-service';

@Component({
    selector: 'app-dog-list',
    templateUrl: './dog-list.component.html',
    styleUrls: ['./dog-list.component.scss'],
})
export class DogListComponent implements OnInit {
    
    /*
        @Input() patient: IPatient | null = null; //Текущий пациент
    */
    @Input() tmpToken: ITokenAndPatientId | null = null; //токен
    @Input() tokens: string | null = null;
    @Output() onError = new EventEmitter<string>(); // Ошибка
    @Output() onEvent = new EventEmitter<string>(); // Ошибка
    @Input() dogPREVIEW_REQUIRED: boolean = false; // Обязательность предпросмотра

    public patient: IPatient | null = null; //Текущий пациент
    public error = '';

    public dogList!: IDogList[]; // Список договоров
    //    private dogPREVIEW_REQUIRED: boolean = false; // Обязательность предпросмотра
    //    public showDoglist = false;
    public dogHoverId: number = 0; // Наведенный договор, если нет то = 0
    loading = false; // Загрузка

    constructor(
        private auth: AuthService,
        private configS: ConfigService,
        private ps: PatientService,
        private router: Router,
        private cookieService: CookieConsentService
    ) {}

    ngOnInit(): void {
        this.dogHoverId = 0;
        if (this.tmpToken) {
            this.loginDoc();
        }
    }

    /* Получение списка договоров */
    private loginDoc(): void {

        this.loading = true;
        this.dogList = [];
        this.auth.getContractList(this.tmpToken!).subscribe(
            (response) => {
                if (response && response.length > 0) {
                    response.forEach((item) => {
                        // Получаем информацию о пациенте
                        this.ps.getServerPatientInfo$().subscribe(
                            (info) => {
                                this.patient = info;
                                this.patient.birthdate = null;
                            },
                            (err) => {
                                this.patient = null;
                            }
                        );

                        this.dogList.push({
                            id: item.template_id,
                            text: item.template_name,
                            Сh: false,
                            Url: `/contract/preview?patientId=${
                                this.tmpToken!.patientId
                            }&contractId=${item.template_id}`,
                        });
                    });
                    this.error = '';
                } else {
                    // Если нет договоров то продолжаем
                    this.loginOK();
                }
                this.loading = false;
            },
            (error) => {
                this.loading = false;
                console.error('error=', error);
            }
        );
    }

    /* Назад */
    logout(): void {
        this.onEvent.emit('LOGOUT');
    }

    public disableDog(id: number): boolean {
        let f = false;
        if (this.dogPREVIEW_REQUIRED) {
            this.dogList.forEach((item) => {
                if (item.id === id) {
                    // @ts-ignore
                    f = item.Сh;
                }
            });
        } else {
            f = true;
        }
        return f;
    }

    /* Подписание договора */
    public goSignature(templateId: number): void {
        //
        this.loading = true;
        this.auth.postContract(this.tmpToken!, templateId).subscribe(
            (result: IDogSignatureRes[]) => {
                this.loading = false;
                if (result[0] && result[0].id) {
                    this.dogList.forEach((item, i) => {
                        item.id === templateId && this.dogList.splice(i, 1);
                    });
                } else {
                    if (result[0].err_text) {
                        this.error = 'Что то пошло не так';
                    } else {
                        this.error = result[0].err_text;
                    }
                    this.onError.emit(this.error);
                }
                if (
                    !this.dogList ||
                    (this.dogList && this.dogList.length === 0)
                ) {
                    this.loginOK();
                }
            },
            (error) => {
                console.error('SIG ERR=', error);
                //          if (error.error && error.error.data && error.error.data.errorMsg) {
                if (error) {
                    this.error = error;
                } else {
                    this.error = 'Что то пошло не так при подписании!';
                }

                this.onError.emit(this.error);

                this.loading = false;
            }
        );
    }

    public onHoverDog(id: number) {
        // on-mouseover
        //
        this.dogHoverId = id;
    }

    public onMouseout(id: number) {
        // on-mouseleave on-mouseout (одинаково работают)
        //
        this.dogHoverId = 0;
    }

    /* просмотр договора */
    public clickDog(id: number): void {
        let url = '';
        this.dogList.forEach((item) => {
            if (item.id === id) {
                item.Сh = true;
                url = item.Url!;
            }
        });
        window.open(`${this.configS.getValue('hostBackend')}${url}`, '_blank');
    }

    public loginOK(): void {
        this.setCookies(this.tokens!);
        this.onEvent.emit('LOGINOK');
    }

    private setCookies(token: string): void {
        if (!document.cookie.includes('cookieconsent_status')) {
            this.cookieService.setCookie('cookieconsent_status', 'true');
        }
        this.cookieService.setCookie('pa-web_Patient_Local', token);
    }
}
