<button
    mat-icon-button
    (click)="changeModalOpenStatus(true)"
    *ngIf="!isMobileMenuOpen"
    aria-label="icon-button with a menu"
    class="cls-btn-menu"
>
    <div class="svg-menu" *ngIf="!isMobileMenuOpen">
        <svg
            width="27"
            height="17"
            viewBox="0 0 27 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                y1="1.75"
                x2="27"
                y2="1.75"
                stroke="#B7C9DF"
                stroke-width="2.5"
            />
            <line
                y1="8.75"
                x2="27"
                y2="8.75"
                stroke="#B7C9DF"
                stroke-width="2.5"
            />
            <line
                y1="15.75"
                x2="27"
                y2="15.75"
                stroke="#B7C9DF"
                stroke-width="2.5"
            />
        </svg>
    </div>
</button>
<!-- Мобилка -->
<div class="cls-menu-mobile" *ngIf="isMobileMenuOpen">
    <div class="menu-mobile-header">
        <button
            class="close-mobile-button"
            *ngIf="isMobileMenuOpen"
            (click)="changeModalOpenStatus(false)"
        >
            <div class="svg-menu-close">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15 18L9 12L15 6"
                        stroke="#B7C9DF"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </button>
        <div class="patient__name">{{ patientName }}</div>
    </div>
    <div class="menu-mobile">
        <div>
            <button
                mat-menu-item
                *ngFor="let menu of menus"
                class="menu-button-mobile"
                [routerLink]="menu.url"
                (click)="changeModalOpenStatus(false)"
            >
                <mat-icon class="custom-icon" [svgIcon]="menu.img"></mat-icon>
                <span>{{ menu.name }}</span>
            </button>
        </div>
        <div>
            <a
                class="mobile-help-button"
                href="./assets/manual.pdf"
                target="_blank"
                alt="Помощь"
            >
                <div class="mobile-help-container">
                    <img src="./assets/img/svg/help.svg" />
                    <span>Инструкция</span>
                </div>
            </a>
        </div>
    </div>
</div>

<!-- Комп -->
<div class="flex-column flex-shrink-0 text-white cls-menu cls-dev-comp">
    <ul class="nav nav-pills flex-column mb-auto cls-menu-ul">
        <li
            class="cls-menu-li"
            *ngFor="let menu of menus"
            [ngClass]="{
                active: menu.active
            }"
            [routerLink]="menu.url"
            (click)="setActiveMenu(menu)"
        >
            <mat-icon class="custom-icon" [svgIcon]="menu.img"></mat-icon>
            <span>{{ menu.name }}</span>
        </li>
    </ul>
    <div class="exit-button-menu">
        <ul class="nav nav-pills flex-column mb-auto cls-menu-ul">
            <li class="cls-menu-li" (click)="onExit()">
                <mat-icon class="custom-icon" svgIcon="exit"></mat-icon>
                <span>Выход</span>
            </li>
        </ul>
    </div>
</div>
