
<div class="filters white record"  [@expandedAnimation]="searchAmine">
    <div class="record-filter">
        <div class="record-filter__wrapper">
            <div class="record-filter__top record-top">
                <div class="record-top__left">
                    <div class="record-top__title record-title">
                        Фильтры
                    </div>
                </div>
                <button
                    class="record-top__cloce list-cloce reset"
                    (click)="toggleDropdown()"
                >
                    <img
                        src="./assets/img/svg/cloce.svg"
                        alt="Закрыть фильтр"
                    />
                </button>
            </div>
            <div class="record-filter__box">
              
                <div
                    class="checkbox"
                    *ngFor="let stBTN of filterList"
                >
                
                <ng-template [ngIf]="stBTN.keyid == staticFilterSelected">
                    <input
                        id="consultation_{{ stBTN.keyid }}"
                        type="radio"
                        name="consultation"
                        class="checkbox__input"
                        [checked]="stBTN.keyid === staticFilterSelected"
                        (click)="selectFilter(stBTN.keyid)"
                    />
                </ng-template>
                <ng-template [ngIf]="stBTN.keyid != staticFilterSelected">
                    <input
                        id="consultation_{{ stBTN.keyid }}"
                        type="radio"
                        name="consultation"
                        class="checkbox__input"
                        [checked]="stBTN.keyid === staticFilterSelected"
                        (click)="selectFilter(stBTN.keyid)"
                    />
                </ng-template>
                    <label
                        for="consultation_{{ stBTN.keyid }}"
                        class="checkbox__label"
                        (click)="onLabelClick(stBTN.keyid)"
                        
                    >
                        {{ stBTN.text }}
                    </label>
                </div>
                
            </div>
            <div class="buttons">
                <button
                    class="record-filter__lose bottom-apply__btn lose reset"
                    (click)="clearFilter()"
                >
                    Сбросить
                </button>
                <button
                    class="record-filter__use bottom-apply__btn use reset"
                    (click)="searchClick()"
                >
                    Применить
                </button>
            </div>
        </div>
    </div>
</div>