import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Size } from '../../services/size';
import { LoadService } from '../../services/application/load.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    @ViewChild('ContentBlocAuth') contentBlocAuthEL: ElementRef | undefined;

    selectM = 0; // 0-Авторизация, 1- Регистрация, 2- Восстановление 3-Регистрация через телефон
    selectMenuStr = 'auth'; // auth-Авторизация, reg- Регистрация, recovery - Восстановление
    errText: string | null = null; // Текст ошибки
    private _setting: any;
    AccessReg = false; // Доступ к регистрации
    AuthFormText = 'Личный кабинет'; //
    message?: string;

    constructor(
        private size: Size,
        private title: Title,
        private load: LoadService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this._setting = load.configData.pages.login;
        if (this._setting?.CreatePatient) {
            this.AccessReg = this._setting.CreatePatient == 1 ? true : false;
        }
        if (this._setting?.Auth_form_text) {
            this.AuthFormText = this._setting.Auth_form_text;
        }

        if (this._setting?.title) {
            this.title.setTitle(this._setting.title);
        } else {
            this.title.setTitle('Авторизация');
        }
    }

    redirectTo(uri: string) {
        this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
                this.router.navigate([uri]);
            });
    }
    ngOnInit(): void {
        if (location.pathname.indexOf('/recovery') > 0) {
            this.onChangeM(2);
        }
        if (!this.load.configLoaded) {
            this.redirectTo('/503');
        }
        this.errText = '';
    }

    ngAfterViewInit() {}

    onChangeM(event: number) {
        this.errText = '';
        this.message = undefined;
        this.selectM = event;
        switch (event) {
            case 0: {
                this.selectMenuStr = 'auth';
                break;
            }
            case 1: {
                this.selectMenuStr = 'reg';
                break;
            }
            case 2: {
                this.selectMenuStr = 'recovery';
                break;
            }
            default: {
                this.selectMenuStr = 'auth';
                break;
            }
        }
    }

    // Обработка ошибки
    onError(event: string) {
        this.errText = event;
    }

    // Обработка успоешной регистрации
    onRegSuccess(event: string) {
        this.message = event;
        this.selectMenuStr = 'auth';
        this.selectM = 0;
    }

    // Обработка успоешной авторизации
    onLoginSuccess(event: string) {
        this.message = event;
    }
}
