import { Component, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { Size } from './services/size';
import { Meta } from '@angular/platform-browser';
import packageJson from 'package.json';
const { version } = packageJson;
import { SocketService } from './services/socket/socket.service';
import { EventService } from './services/event/event.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'pa-app';
    constructor(
        private router: Router,
        private size: Size,
        private meta: Meta,
        private socketService: SocketService,
        private eventService: EventService
    ) {
        // Установка типа девайса
        size.setW(window.innerWidth);
        const token = document.cookie
            .split(';')
            .find((c) => c.trim().startsWith('pa-web_Patient_Local='));
        if (token && token.split('=')[1]) {
            this.eventService.emit('auth:login');
            const talonBlock = localStorage.getItem('talonBlock');
            const { rnumbID, f_appoitment } = JSON.parse(talonBlock!) || {};
            if (rnumbID && !f_appoitment) {
                setTimeout(() => {
                    socketService.unlockTalon(Number(rnumbID));
                }, 200);
            }
            localStorage.removeItem('talonBlock');
        }
    }

    ngAfterViewInit(): void {
        this.updateVhUnits();
    }

    ngOnInit(): void {
        this.meta.updateTag({
            name: 'product_version',
            value: version,
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.updateVhUnits();
    }

    updateVhUnits() {
        const vh = window.innerHeight * 0.01;
        document.body.style.setProperty('--vh', `${vh}px`);
    }
}
