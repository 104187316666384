import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { IDateRange } from 'src/app/interfaces/date-range.interface';
import {
    EHistoryEventsFiltersMode,
    IHistoryEvents,
    IHistoryEventsFilterParams,
    IHistoryFilter,
} from 'src/app/interfaces/history.interface';
import { LoadService } from 'src/app/services/application/load.service';
import { HistoryService } from 'src/app/services/history.service';
import { AlertService } from 'src/lib/ariadna/alert';
import {
    dateToText,
    getDayWithYear,
    getNameDay,
    getTekDay,
    getTekDayNorm,
    getTime,
    strToDate,
} from '../../application/global.function';
import { PayPeriodDialogComponent } from '../../payments/dialogs/pay-period-dialog/pay-period-dialog.component';
import { HistoryItemComponentMobile } from 'src/app/modals/history-item-mobile/history-item.component';
import { ICalendarDateActive } from 'src/app/interfaces/calendar';
import { ConfigService } from 'src/app/services/application/config.service.';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { LocationStrategy } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
@Component({
    selector: 'app-list-completed',
    templateUrl: './list-completed.component.html',
    styleUrls: ['./list-completed.component.scss'],
})
export class ListCompletedComponent implements OnInit {
    HistoryEvents: IHistoryEvents[] = [];
    @Output() onDateGet = new EventEmitter<ICalendarDateActive[]>(); // получил данные
    loading = false; // Загрузка талонов
    showFilials = true; // Показ филиалов в таблице/фильтре. Параметр из АРМ
    gridTemplateColumns = '9.5rem 1fr 9.5rem 1fr 1fr 9rem 1fr';

    _selectDate: string = ''; // выбранная дата

    @Input() CountRecPage: number = 4;
    CountRec: number = 0; // Всего записей
    CurentPage: number = 0; // Текущая сраница

    historyPages: Array<IHistoryEvents[]> = [];
    date: IDateRange = { dt_begin: null, dt_end: null };

    loadedPages = 1;
    viewPage = 1;
    periodText = '';
    countOfPages = 1;
    historyListLength: number = 0; // Количество записей
    countRecToPage = 15; // Количетсво записей на странице
    loadingMoreContent = false;
    pdfLoader = false;

    filters: Record<'spec' | 'doc' | 'dep', IHistoryFilter> = {
        spec: {
            list: [],
            mode: EHistoryEventsFiltersMode.SPEC,
            selectedID: null,
            searchText: '',
        },
        doc: {
            list: [],
            mode: EHistoryEventsFiltersMode.DOC,
            selectedID: null,
            searchText: '',
        },
        dep: {
            list: [],
            mode: EHistoryEventsFiltersMode.DEP,
            selectedID: null,
            searchText: '',
        },
    };
    constructor(
        private router: Router,
        private hs: HistoryService,
        private dialog: MatDialog,
        private configS: ConfigService,
        private load: LoadService,
        private historyService: HistoryService,
        private alert: AlertService,
        private http: HttpNewService,
        private locationStrategy: LocationStrategy,
        private modalService: NgbModal
    ) {
        // this.date = { dt_begin: new Date(), dt_end: new Date() };
        Promise.all([this.getHistoryFilters, this.getHistorySize]).then(() =>
            this.changedPage(1)
        );

        this.showFilials = Boolean(load.configData.pages.history.showFilials);
        if (!this.showFilials)
            this.gridTemplateColumns = '9.5rem 1fr 9.5rem 1fr 9rem 1fr';
    }

    ngOnInit(): void {
        moment.locale('ru');
        // this.date.dt_begin?.setDate(this.date.dt_begin.getDate() - 31);

        this.loading = true;
        this.changePeriod({
            dt_begin: this.date.dt_begin,
            dt_end: this.date.dt_end,
        }).then(() => (this.loading = false));

        this.formatDateToString(this.date);
    }

    getListHistory(pStart: number, pEnd: number) {
        this.loading = true;
        let dtHistory: Date | null = null;
        if (this._selectDate && this._selectDate.length > 4) {
            // Есть дата
            dtHistory = moment(this._selectDate, 'YYYY-MM-DD').toDate();
        }

        this.hs
            .getHistoryEvents(
                dtHistory,
                dtHistory,
                pStart,
                pEnd,
                this.filters.spec.selectedID,
                this.filters.doc.selectedID,
                this.filters.dep.selectedID
            )
            .subscribe(
                (res) => {
                    //
                    this.HistoryEvents = res;
                    let dt: ICalendarDateActive[] = [];

                    this.HistoryEvents.forEach((item) => {
                        item.dtSort = strToDate(item.dat);

                        dt.push({
                            date: Number(
                                moment(item.dtSort).format('YYYYMMDD')
                            ),
                        });
                    });
                    if (!dtHistory) {
                        this.onDateGet.emit(dt);
                    }
                    this.loading = false;
                },
                (err) => {
                    this.loading = false;
                }
            );
    }

    isBlockYeer(index: number): boolean {
        if (index === 0) return true;

        return (
            this.HistoryEvents.length > index + 1 &&
            this.HistoryEvents[index].dtSort.getFullYear() !==
                this.HistoryEvents[index - 1].dtSort.getFullYear()
        );
    }

    getYeer(index: number): string {
        if (this.HistoryEvents && this.HistoryEvents[index]) {
            return this.HistoryEvents[index].dtSort.getFullYear() + '';
        } else {
            return '';
        }
    }

    getTekDay(dt: Date): string {
        return getTekDay(dt);
    }

    getNameDay(dt: Date): string {
        return getNameDay(dt);
    }

    getTime(dt: Date): string {
        return getTime(dt);
    }

    /* Смена страницы */
    changedPage(page: number) {
        //      this.CountRecPage = 10;
        this.CurentPage = page;
        // console.log(this.CountRec, this.CurentPage, this.CountRecPage);
        if (page === 1) {
            this.getListHistory(1, this.CountRecPage);
        } else {
            this.getListHistory(
                page * this.CountRecPage - this.CountRecPage + 1,
                page * this.CountRecPage
            );
        }
    }

    // Formats
    getTextDoc(doc: string) {
        const arr = doc.trim().split(' ');
        const [lastname, firstname, ...patronymParts] = doc.trim().split(' ');
        return `${lastname} ${firstname?.length ? firstname[0] + '.' : ''} ${
            patronymParts.join('').length ? patronymParts.join('')[0] + '' : ''
        }`;
    }

    loadNextPage() {
        if (
            this.loadedPages / this.countOfPages !== 1 &&
            !this.loadingMoreContent
        ) {
            this.loadedPages++;
            this.loadMoreHistory(
                this.loadedPages * this.countRecToPage -
                    this.countRecToPage +
                    1,
                this.loadedPages * this.countRecToPage
            );
        }
    }

    /** Изменение периода для вывода записей*/
    async changePeriod(dt: IDateRange) {
        this.loadingMoreContent = true;
        this.date = dt;
        this.historyPages = [];
        await this.getHistorySize();
        await this.getHistoryFilters();
        this.formatDateToString(this.date);
        this.periodText = this.getPeriodText(this.date);
        this.loadedPages = 1;
        this.viewPage = 1;
        this.loadingMoreContent = false;
    }

    async getHistoryFilters() {
        const items = Object.values(this.filters);
        const promises = [];

        for (const item of items) {
            const params: IHistoryEventsFilterParams = {
                begin_dat: null,
                end_dat: null,
                mode: item.mode,
            };

            promises.push(
                await lastValueFrom(this.hs.getHistoryEventsFilter(params))
            );
        }

        try {
            const filterData = await Promise.all(promises);

            filterData.forEach((data, i) => {
                switch (i + 1) {
                    case EHistoryEventsFiltersMode.SPEC:
                        this.filters.spec.list = data;
                        break;
                    case EHistoryEventsFiltersMode.DOC:
                        this.filters.doc.list = data;
                        break;
                    case EHistoryEventsFiltersMode.DEP:
                        this.filters.dep.list = data;
                        break;
                }
            });
        } catch (error) {
            this.alert.error('Ошибка загрузки списка фильтров', 3000);
        }
    }

    getSizeHistory(dt: Date | null) {
        this.loading = true;

        this.hs
            .getHistoryEventsSize({
                beginDate: dt,
                endDate: dt,
                specID: this.filters.spec.selectedID,
                docID: this.filters.doc.selectedID,
                depID: this.filters.dep.selectedID,
            })
            .subscribe(
                (res) => {
                    if (res && res.size > 0) {
                        this.CountRec = res.size;
                        this.changedPage(1);
                    } else {
                        this.onDateGet.emit([]);
                        this.loading = false;
                    }
                },
                (err) => {
                    this.loading = false;
                }
            );
    }

    /** Получение количества услуг */
    async getHistorySize() {
        try {
            const [resHistory] = await Promise.all([
                // await lastValueFrom(
                //     this.historyService.getServicesSize(
                //         this.date.dt_begin,
                //         this.date.dt_end
                //     )
                // ),
                await lastValueFrom(
                    this.hs.getHistoryEventsSize({
                        beginDate: this.date.dt_begin,
                        endDate: this.date.dt_end,
                        specID: this.filters.spec.selectedID,
                        docID: this.filters.doc.selectedID,
                        depID: this.filters.dep.selectedID,
                    })
                ),
            ]);

            // if (resSrv.size) {
            // this.historyListLength = resHistory.size;
            await this.loadMoreHistory(1, this.countRecToPage);
            this.CountRec = resHistory.size;
            this.setCountOfPagesMobile();
            // } else {
            //     this.historyListLength = 0;
            // }
            // console.log(resHistory, resHistory.size);
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка услуг');
        }
    }

    /** Подгрузка дополнительного кал-ва услуг */
    async loadMoreHistory(pStart?: number, pEnd?: number) {
        try {
            const res = await lastValueFrom(
                this.historyService.getHistoryEvents(
                    this.date.dt_begin,
                    this.date.dt_end,
                    pStart,
                    pEnd,
                    this.filters.spec.selectedID,
                    this.filters.doc.selectedID,
                    this.filters.dep.selectedID
                )
            );

            res.forEach((item) => {
                item.dtSort = new Date(
                    item.dat.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1')
                );
            });

            if (res.length !== 0) {
                this.historyPages.push(res);
            }
        } catch (error) {
            this.alert.error('Ошибка при загрузке списка посещений');
        }
    }

    /**
     * Фильтрация записей по дате
     * @param {number | null} startOffset Сдвиг даты С
     * @param {number | null} endOffset Сдвиг даты ПО
     */
    btnFilterByDay(
        startOffset: number | null = 0,
        endOffset: number | null = 0
    ) {
        if (startOffset !== null && endOffset !== null) {
            this.date.dt_begin = new Date();
            this.date.dt_begin.setDate(
                this.date.dt_begin.getDate() - startOffset
            );
            this.date.dt_end = new Date();
            this.date.dt_end.setDate(this.date.dt_end.getDate() - endOffset);
            this.changePeriod({
                dt_begin: this.date.dt_begin,
                dt_end: this.date.dt_end,
            });
        } else {
            this.changePeriod({ dt_begin: null, dt_end: null });
        }
    }

    // /** Получение дня недели */
    // getNameDay(dt: Date): string {
    //     return getNameDay(dt);
    // }

    // getTekDay(dt: Date): string {
    //     return getTekDay(dt);
    // }

    getTekDayNorm(dt: Date): string {
        return getTekDayNorm(dt);
    }

    /** Получение времени формата "D MM YYYY" */
    getDayWithYear(dtSort: Date) {
        return getDayWithYear(dtSort);
    }

    // /** Получение времени формата "HH:mm" */
    // getTime(dt: Date): string {
    //     return getTime(dt);
    // }

    /** Получение периода текстом в формате "D MMM YYYY"*/
    formatDateToString(dt: IDateRange) {
        if (dt.dt_begin) {
            dt.str_begin = moment(new Date(dt.dt_begin)).format('D MMM YYYY');
        }
        if (dt.dt_end) {
            dt.str_end = moment(new Date(dt.dt_end)).format('D MMM YYYY');
        }
    }

    /** Установка количества страниц*/
    setCountOfPagesMobile() {
        // console.log(this.CountRec, this.countRecToPage);
        this.countOfPages = Math.ceil(this.CountRec / this.countRecToPage);
    }

    /** Вызов диалога для выбора периода */
    showPeriodDialog() {
        const periodDialog = this.dialog.open(PayPeriodDialogComponent, {
            maxWidth: '650px',
            width: '100%',
            height: '100%',
            data: {
                dateRange: {
                    dt_begin: this.date.dt_begin,
                    dt_end: this.date.dt_end,
                } as IDateRange,
            },
        });

        periodDialog.afterClosed().subscribe((result: any) => {
            if (result?.date) {
                this.loading = true;
                this.changePeriod(result.date).then(
                    () => (this.loading = false)
                );
            }
        });
    }

    /** Вызов диалога с деталями услуги */
    showDetailsDialog(history: IHistoryEvents) {
        const dialogRef = this.dialog.open(HistoryItemComponentMobile, {
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
            closeOnNavigation: true,
            data: history,
        });

        dialogRef.afterOpened().subscribe(() => {
            const dialogContent = document.querySelector(
                '.cdk-global-overlay-wrapper'
            ) as HTMLElement;
            if (dialogContent) {
                dialogContent.style.alignItems = 'unset';
            }
            // dialogContent.style;
            // dialogContent.style.overflowY = 'auto';
            // dialogContent.style.maxHeight = 'calc(100vh - 120px)'; // Adjust the value according to your needs
        });
    }

    /** Полученеи текста из периода */
    getPeriodText(dt: IDateRange) {
        let periodText = '';
        let isSelectedOneDay = dt.str_begin === dt.str_end;

        if (isSelectedOneDay) {
            if (dt.dt_begin == null && dt.dt_end == null)
                periodText = 'Посещений за всё время';
            else if (dt.dt_begin?.toDateString() === new Date().toDateString())
                periodText = 'Последние посещения';
            else if (dt.str_begin) periodText = dt.str_begin;
        } else if (dt.str_begin && dt.str_end) {
            periodText = `${dt.str_begin} - ${dt.str_end}`;
        } else if (dt.str_begin) {
            periodText = dt.str_begin;
        } else if (dt.str_end) {
            periodText = dt.str_end;
        }

        return periodText;
    }

    /**
     * Фильтрация записей по дате
     * @param {number | null} startOffset Сдвиг даты С
     * @param {number | null} endOffset Сдвиг даты ПО
     */
    btnFilterByDate(
        startOffset: number | null = 0,
        endOffset: number | null = 0
    ) {
        if (startOffset !== null && endOffset !== null) {
            this.date.dt_begin = new Date();
            this.date.dt_begin.setDate(
                this.date.dt_begin.getDate() - startOffset
            );
            this.date.dt_end = new Date();
            this.date.dt_end.setDate(this.date.dt_end.getDate() - endOffset);
            this.loading = true;
            this.changePeriod({
                dt_begin: this.date.dt_begin,
                dt_end: this.date.dt_end,
            }).then(() => (this.loading = false));
        } else {
            this.date.dt_begin = null;
            this.date.dt_end = null;
            this.loading = true;
            this.changePeriod({ dt_begin: null, dt_end: null }).then(() => {
                this.loading = false;
            });
        }
    }

    async onChangeFilter(id: number | null, mode: EHistoryEventsFiltersMode) {
        switch (mode) {
            case EHistoryEventsFiltersMode.SPEC:
                this.filters.spec.selectedID = id;
                break;
            case EHistoryEventsFiltersMode.DOC:
                this.filters.doc.selectedID = id;
                break;
            case EHistoryEventsFiltersMode.DEP:
                this.filters.dep.selectedID = id;
                break;
            default:
                break;
        }

        this.loading = true;
        this.historyPages.length = 0;
        await this.getHistorySize();
        this.changedPage(1);
        this.changePeriod(this.date);
    }

    // Fires by press "ENTER" or click cross(clear)
    async onSearchFilter(mode: EHistoryEventsFiltersMode, event: Event) {
        event.stopPropagation();
        const elem = event.target as HTMLInputElement;
        if (elem.value) return; // Skip "ENTER"

        await this.onChangeFilter(null, mode);
    }
}
