<!-- statement.component.html -->
<h1
    mat-dialog-title
    style="
        min-height: 61px;
        min-width: 300px;
        box-shadow: 0px 7px 7px 0px rgb(173 189 209);
        padding-top: 8px;
        font-size: 16px;
        margin: 0px;
        z-index: 1;
    "
    class="cls-modal-block-header"
>
    Заявление для налоговой справки
    <span class="cls-icon-krest">
        <mat-icon svgIcon="krest" [mat-dialog-close]="true"></mat-icon>
    </span>
</h1>
<loader *ngIf="loading || !loadingFrm" type="page"> </loader>

<div mat-dialog-content>
    <form
        class="cls-statement-form"
        [formGroup]="frmStatement"
        *ngIf="loadingFrm && !showFormOk"
        [ngClass]="{ loader: loading }"
    >
        <!-- Налогоплательщик -->
        <div class="cls-block-header-stat">Налогоплательщик</div>
        <mat-form-field class="w-100 form-statement-field">
            <mat-label>ФИО</mat-label>
            <input
                [type]="'text'"
                matInput
                formControlName="nalog_fio"
                required
                [readonly]="loading"
            />
        </mat-form-field>

        <mat-form-field class="w-100 form-statement-field">
            <mat-label>ИНН</mat-label>
            <input
                type="text"
                matInput
                formControlName="nalog_inn"
                required
                maxlength="12"
                (keypress)="numberOnly($event)"
                [readonly]="loading"
                (keydown.space)="$event.preventDefault()"
            />
        </mat-form-field>

        <!-- Пациент -->
        <div class="cls-block-header-stat">Пациент</div>
        <mat-form-field class="w-100 form-statement-field">
            <mat-label>ФИО</mat-label>
            <input
                [type]="'text'"
                matInput
                formControlName="patient_fio"
                required
                [readonly]="loading"
            />
        </mat-form-field>

        <mat-form-field class="w-100 form-statement-field">
            <mat-label>Дата рождения</mat-label>
            <input
                type="date"
                matInput
                formControlName="patient_dr"
                required
                [max]="maxDate"
                [readonly]="loading"
                (keydown.space)="$event.preventDefault()"
                (input)="validateDate($event)"
            />
            <mat-error
                *ngIf="frmStatement.get('patient_dr')?.hasError('invalidDate')"
                >Выбрана неверная дата</mat-error
            >
        </mat-form-field>
        <!-- Добавить пациента -->
        <button
            class="btn btn-lg cls-btn-second cls-add-patient"
            type="submit"
            [disabled]="disableAddPatient()"
            (click)="addPatient()"
            *ngIf="patient1 === false"
        >
            + Добавить пациента
        </button>

        <!-- Второй пациент (показывается только при наличии второго пациента) -->
        <div *ngIf="patient1">
            <span class="cls-icon-krest">
                <mat-icon
                    svgIcon="krest"
                    class="cls-icon-color"
                    (click)="delPatient(1)"
                >
                </mat-icon>
            </span>
            <mat-form-field class="w-100 form-statement-field">
                <mat-label>ФИО второго пациента</mat-label>
                <input
                    [type]="'text'"
                    matInput
                    formControlName="patient_fio1"
                    required
                    [readonly]="loading"
                />
            </mat-form-field>

            <mat-form-field class="w-100 form-statement-field">
                <mat-label>Дата рождения второго пациента</mat-label>
                <input
                    type="date"
                    matInput
                    formControlName="patient_dr1"
                    required
                    [max]="maxDate"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    (input)="validateDate($event)"
                />
                <mat-error
                    *ngIf="
                        frmStatement.get('patient_dr1')?.hasError('invalidDate')
                    "
                    >Выбрана неверная дата</mat-error
                >
            </mat-form-field>
        </div>

        <!-- Год -->
        <div class="cls-block-header-stat" style="margin-top: 50px">
            За какой год нужна справка?*
        </div>
        <div class="row cls-block-btn-year">
            <button
                class="btn cls-btn-second col"
                type="button"
                *ngFor="let btnY of btnYearTxt; let i = index"
                [disabled]="loading"
                (click)="clicBtn(i)"
                [class.active]="selectedIndexYear === i"
            >
                {{ btnYearTxt[i] }}
            </button>
        </div>

        <!-- Требуется ли восстановление -->
        <div class="cls-block-header-stat">
            Требуется ли восстановление договора?
            <mat-checkbox
                class="cls-ch-recovery"
                color="primary"
                formControlName="is_recovery"
            ></mat-checkbox>
        </div>

        <mat-form-field class="w-100 form-statement-field">
            <mat-label>ФИО доверенного лица</mat-label>
            <input
                [type]="'text'"
                matInput
                formControlName="dov_fio"
                required
                [readonly]="loading"
            />
        </mat-form-field>

        <mat-form-field class="w-100 form-statement-field">
            <mat-label>Контактный телефон</mat-label>
            <span matPrefix>+7 &nbsp;</span>
            <input
                [type]="'text'"
                matInput
                placeholder="(999) 999-99-99"
                phoneMask
                [preValue]="frmStatement.get('dov_phone')!.value"
                formControlName="dov_phone"
                required
                maxlength="14"
                [readonly]="loading"
                (keydown.space)="$event.preventDefault()"
            />
        </mat-form-field>
        <!-- Отправить -->
        <button
            class="btn btn-lg w-100 cls-btn-second"
            [ngClass]="{
                'cls-btn-send': isFormValid(),
                'cls-btn-send-disable': !isFormValid()
            }"
            type="button"
            [disabled]="loading || !isFormValid()"
            (click)="save()"
            style="margin-bottom: 20px; margin-top: 50px"
        >
            Отправить
        </button>
    </form>

    <div *ngIf="showFormOk">
        <h1
            class="tab-success"
            style="font-size: 29px; margin-bottom: 20px; margin-top: 20px"
        >
            Ваше заявление отправлено на рассмотрение.
        </h1>
        <h3
            class="tab-success"
            style="font-size: 19px"
            *ngIf="checkValue(INFO_HEADER_STATEMENT)"
        >
            {{ INFO_HEADER_STATEMENT }}
        </h3>
        <p
            class="tab-success"
            style="font-size: 19px"
            *ngIf="checkValue(INFO_BODY_STATEMENT)"
        >
            {{ INFO_BODY_STATEMENT }}
        </p>
    </div>
</div>
