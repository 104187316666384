import { Injectable } from '@angular/core';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './application/config.service.';
import {
    IdocType,
    IDocuments,
    IFilelistFromDB,
} from '../interfaces/document.interface';
import { IApiResponse } from '../interfaces/response';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private configS: ConfigService,
        private httpNew: HttpNewService
    ) {}

    async uploadFile(
        file: File,
        tag: number,
        link_id: number
    ): Promise<{ id: number; storage_path: string }> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tag', `${tag}`);
        formData.append('link_id', `${link_id}`);
        return await lastValueFrom(
            this.httpNew.post('/file/upload', formData, null, true, true)
        );
    }

    async getFilelist(
        tag: number,
        link_id: number
    ): Promise<IFilelistFromDB[]> {
        const params = {
            tag,
            link_id,
        };
        return await lastValueFrom(
            this.httpNew.get('/file/list', null, params)
        );
    }

    async deleteFileFromDB(path: string | undefined, id: number) {
        return await lastValueFrom(
            this.httpNew.delete('/file/delete', { path, id })
        );
    }

    uploadTmp(file: File): Promise<any> {
        return new Promise((ress, rej) => {
            // const token = null;
            let headerss = new HttpHeaders();
            // if (token && token.length > 10) {
            //     headerss = headerss.set('Authorization', 'TOKEN ' + token);
            // }
            //
            const formData: FormData = new FormData();
            formData.append('file', file);
            //
            //
            const upload$ = this.http.post(
                `${this.configS.getValue('hostBackend')}/file/temp`,
                formData,
                {
                    reportProgress: true,
                    responseType: 'json',
                    headers: headerss,
                }
            );

            upload$.subscribe(
                (result) => {
                    //
                    ress(result);
                },
                (err) => {
                    rej(err);
                }
            );
        });
    }

    getDocTypeList(): Observable<IdocType[]> {
        const url = '/file/list_type';
        return this.httpNew.get(url, null);
    }

    getDocList(): Observable<IDocuments[]> {
        const url = '/file/list_doc';
        return this.httpNew.get(url, null);
    }

    mailToSend(dt: any): Observable<any[]> {
        const url = '/mails/send';
        return this.httpNew.post(url, dt, null, true);
    }

    getDocumentsList() {
        return this.httpNew.get('/file/documents', null);
    }

    getDocument(id: number, file_name: string) {
        return this.httpNew.get(
            '/file/documents',
            null,
            { fileId: id, fileName: file_name },
            false
        );
    }
}
