<!-- <ngx-cookie-consent> </ngx-cookie-consent> -->
<div class="cls-login-form" #ContentBlocAuth>
    <div
        class=""
        [ngClass]="{
            'form-signin-recovery': selectM == 2,
            'form-signin-reg': selectM == 1 || selectM == 3,
            'form-signin': selectM == 0
        }"
    >
        <img src="./assets/img/Logo_horiz_mini.png" alt="" width="100%" />
        <h1 class="h3 mb-3 fw-normal" class="cls-auth-name">
            {{ AuthFormText }}
        </h1>
        <div *ngIf="selectM == 0" class="cls-txt-auth">Вход для пациента</div>
        <div *ngIf="selectM == 1" class="cls-txt-auth">Регистрация</div>
        <div *ngIf="selectM == 2" class="cls-txt-auth">
            Восстановление пароля
        </div>
        <div *ngIf="selectM == 3" class="cls-txt-auth">Вход для пациента</div>

        <div *ngIf="message" class="message">{{ message }}</div>

        <div *ngIf="errText" class="cls-error-block">{{ errText }}</div>

        <app-auth
            *ngIf="selectM == 0"
            (onChanged)="onChangeM($event)"
            (onError)="onError($event)"
            (onSuccess)="onLoginSuccess($event)"
            [AccessReg]="AccessReg"
        ></app-auth>

        <app-reg
            *ngIf="selectM == 1"
            (onChanged)="onChangeM($event)"
            (onError)="onError($event)"
            (onSuccess)="onRegSuccess($event)"
        ></app-reg>

        <app-recovery
            *ngIf="selectM == 2"
            (onChanged)="onChangeM($event)"
            (onError)="onError($event)"
            [AccessReg]="AccessReg"
        ></app-recovery>
        <app-auth-phone
            *ngIf="selectM == 3"
            (onChanged)="onChangeM($event)"
            (onError)="onError($event)"
        ></app-auth-phone>
    </div>
</div>
