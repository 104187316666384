<!-- Recovery Component HTML -->

<div
    [@showOpacityAnimation]="animState"
    (@showOpacityAnimation.done)="whenAnimate($event)"
>
    <form
        class="reg-form"
        [formGroup]="newPasswordForm"
        *ngIf="!isTokenRecovery"
    >
        <div class="loading-group">
            <div *ngIf="loading" class="loading">
                <!-- <img src="./assets/loading-rec.gif" /> -->
                <loader type="page"> </loader>
            </div>

            <mat-form-field class="w-100">
                <input
                    [type]="'text'"
                    matInput
                    formControlName="newPW"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    required="true"
                />
                <mat-label>Новый пароль</mat-label>
            </mat-form-field>

            <mat-form-field class="w-100">
                <input
                    [type]="'text'"
                    matInput
                    formControlName="newPW2"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    required="true"
                />
                <mat-label>Повтор нового пароля</mat-label>
            </mat-form-field>

            <button
                class="w-100 btn btn-lg cls-btn cls-btn-reg"
                type="submit"
                [disabled]="
                    loading || !newPasswordForm.valid || !isCopyPassword()
                "
                (click)="goRecoveryNewPW()"
            >
                Сменить
            </button>

            <div class="cls-txt-center cls-footer1">Есть аккаунт?</div>
            <div
                class="cls-txt-center cls-footer2"
                (click)="goAuth()"
                *ngIf="AccessReg"
                placeholder="Регистрация"
            >
                Войти
            </div>
        </div>
    </form>

    <form class="reg-form" [formGroup]="recoveryForm" *ngIf="isTokenRecovery">
        <div class="loading-group">
            <div *ngIf="loading" class="loading">
                <!-- <img src="./assets/loading-rec.gif" /> -->
                <loader type="page"> </loader>
            </div>

            <mat-form-field class="w-100">
                <input
                    [type]="'text'"
                    matInput
                    formControlName="lastName"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    required="true"
                />
                <mat-label>Фамилия</mat-label>
            </mat-form-field>

            <mat-form-field class="w-100">
                <input
                    [type]="'text'"
                    matInput
                    formControlName="firstName"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    required="true"
                />
                <mat-label>Имя</mat-label>
            </mat-form-field>

            <mat-form-field class="w-100">
                <input
                    [type]="'text'"
                    matInput
                    formControlName="secondName"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    required="false"
                />
                <mat-label>Отчество</mat-label>
            </mat-form-field>

            <mat-form-field class="w-100">
                <input
                    type="date"
                    matInput
                    formControlName="birthDate"
                    required="true"
                    [max]="maxDate"
                    [readonly]="loading"
                    (keypress)="formatDate($event)"
                />
                <mat-label>Дата рождения</mat-label>
            </mat-form-field>

            <mat-form-field class="w-100">
                <mat-label>Почта</mat-label>
                <input
                    [type]="'text'"
                    matInput
                    formControlName="email"
                    placeholder="guest@mail.ru"
                    [readonly]="loading"
                    (keydown.space)="$event.preventDefault()"
                    required="true"
                />
            </mat-form-field>

            <div class="cls-blockCaptcha">
                <div>
                    <img [src]="captchaImage" />
                    <mat-icon
                        svgIcon="refresh"
                        (click)="refreshCaptcha()"
                    ></mat-icon>
                </div>

                <mat-form-field class="w-100">
                    <input
                        [type]="'text'"
                        id="regcaptha"
                        matInput
                        formControlName="captcha"
                        [readonly]="loading"
                        (keydown.space)="$event.preventDefault()"
                        required
                    />
                    <mat-label>Введите код с картинки</mat-label>
                </mat-form-field>
            </div>

            <button
                class="w-100 btn btn-lg cls-btn cls-btn-reg"
                type="submit"
                [disabled]="loading || !recoveryForm.valid"
                (click)="goRecovery()"
            >
                Восстановить
            </button>

            <div class="cls-txt-center cls-footer1">Есть аккаунт?</div>
            <div
                class="cls-txt-center cls-footer2"
                (click)="goAuth()"
                placeholder="Регистрация"
            >
                Войти
            </div>
        </div>
    </form>
</div>
