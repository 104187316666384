import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { PatientService } from '../../../services/patient.service';
import { IPatient } from '../../../interfaces/patient';
import { MatDialog } from '@angular/material/dialog';
import { StatementComponent } from '../../../modals/statement/statement.component';
import { Size } from '../../../services/size';
import { NavigationEnd, Router } from '@angular/router';
import { LoadService } from '../../../services/application/load.service';
import { FamilyAccessComponent } from 'src/app/modals/family-access/family-access.component';
import { HttpNewService } from 'src/app/services/application/http-new.service';
import { lastValueFrom } from 'rxjs';
import { CookieConsentService } from 'src/app/services/cookie-consent-service';
import { Store } from 'src/app/services/store.service';
import { log } from 'console';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Output() patientNameChange = new EventEmitter<string>();
    btnDropUP = false; // Статус кнопки
    public patient!: IPatient | undefined;
    public periodTest: string = '';
    public deviceType: string = '';
    //отловить сслыку
    public routerDynamicLink: string = '';
    public strFirst: string = '';
    private _setting: any;
    public AccessStatement = false; // Доступ к стрпавке в налоговую
    public withFeedback: boolean = false;
    public pricelistExternalUrl: string = '';
    public isWorkPersonalData = true;
    withFamilyAccess: boolean = false;
    isMainAccount: boolean = true;
    patientName: string = '';

    constructor(
        private router: Router,
        private cookieService: CookieConsentService,
        private auth: AuthService,
        private patientService: PatientService,
        private httpService: HttpNewService,
        private dialog: MatDialog,
        public size: Size,
        private load: LoadService
    ) {
        router.events.subscribe((val: any) => {
            if (val instanceof NavigationEnd) {
                // По завершению загрузки
                this.routerDynamicLink = val.url.split('#')[0];
            }
        });
        this.isMainAccount = this.isFamilyAccount();
        this.deviceType = this.size.getDeviceType();

        this._setting = load.configData.pages.personal;
        const loginSetting = load.configData.pages.login;
        if (loginSetting.isRolesSystem) {
            const authConfigSetting = this.load.getAccessUserConfig;
            this.isWorkPersonalData = authConfigSetting.includes(
                'lk_access_patient_data'
            );
        }
        if (this._setting && this._setting.WEB_LK_ACCESS_STATEMENT) {
            if (this._setting.WEB_LK_ACCESS_STATEMENT == 1) {
                this.AccessStatement = true;
            } else {
                this.AccessStatement = false;
            }
        }

        if (load.configData.pages.feedback.length !== 0)
            this.withFeedback = true;

        if (load.configData.other.familyAccess) this.withFamilyAccess = true;

        if (
            load.configData.links.pricelist &&
            load.configData.links.pricelist.match(/http(s)?:\/\//)
        )
            this.pricelistExternalUrl = load.configData.links.pricelist;
    }

    ngOnInit(): void {
        this.deviceType = this.size.getDeviceType();
        this.patientService.getServerPatientInfo$().subscribe((info) => {
            this.patient = info;
            this.strFirst = this.patient.firstname.substring(0, 1);

            this.patientName =
                this.patient.lastname +
                ' ' +
                this.patient.firstname +
                ' ' +
                this.patient.secondname;
            this.patientNameChange.emit(this.patientName);
            this.patientService.setPatientName(this.patientName);
        });
    }

    openChange(event: any) {
        this.btnDropUP = event;
    }
    isFamilyAccount(): boolean {
        const parentCookieName = Store.getDataString('ParentCookieName');
        if (parentCookieName) {
            return false;
        }
        return true;
    }
    async exitFamilyAccount() {
        try {
            const cookieId = Store.getDataString('ParentCookieName');
            // console.log(cookieId);
            await lastValueFrom(
                this.httpService.get('/family/set/cookie', null, {
                    cookieId: cookieId,
                    isSetParentCookie: 'true',
                })
            );
        } catch (e) {
            console.error(`Error exit family account: ${e}`);
        } finally {
            Store.deleteStoreNameKey('ParentCookieName');
            window.location.reload();
        }
    }

    onExit() {
        this.auth.logout();
    }

    showStatement() {
        if (this.AccessStatement) {
            const dialogRef = this.dialog.open(StatementComponent, {
                width: 'auto',
                height: 'auto',
                closeOnNavigation: true,
                data: {},
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    // Отмена номерка
                }
            });
        }
    }
    showFamilyList() {
        if (this.withFamilyAccess) {
            this.dialog.open(FamilyAccessComponent, {
                maxWidth: '100vw',
                // width: '100%',
                closeOnNavigation: true,
                data: {},
            });
        }
    }

    showPricelist() {
        if (this.pricelistExternalUrl) {
            window.open(this.pricelistExternalUrl, '_blank');
        }
    }

    /*
/!**************************************************** *!/
  test(ev: any){  //формат строки


//    ev = '';
//    this.periodTest = this.periodTest + ev.key;
//    newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    if (this.periodTest.length == 2 || this.periodTest.length == 5){
      this.periodTest = this.periodTest + '.';
    }
//    ev.returnValue = false;
  }

  /!* только числа *!/
  public numberOnly(event: any): boolean {
//    const charCode = (event.which) ? event.which : event.keyCode;


    const charCode = event.keyCode;
/!*
    if (charCode === 48 && event.srcElement.value.length === 0) { // Проверка на первый 0
      return false;
    } else {
*!/
      // Проверка на ввод числа
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      let testD: number | null = null;  // День
      let testM: number | null = null;  // месяц
      let testY: number | null = null;  // год

      // Проверка на день
      let str = this.periodTest + event.key;
//      let t = Number(str);
//
      if (str.length >1){
        let d = Number(str.substr(0,2));
        

      }
/!*
    }
*!/
    return true;
  }
*/
}
