import { ICalendarDateActive } from 'src/app/interfaces/calendar';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    ElementRef,
} from '@angular/core';
import * as moment from 'moment';
import {
    ModalDismissReasons,
    NgbModal,
    NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { RnumbService } from 'src/app/services/rnumb.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigService } from 'src/app/services/application/config.service.';
import { AuthService } from 'src/app/services/auth.service';
import { getNameDay, strToDate } from '../../application/global.function';
import { ITalonModal } from 'src/app/interfaces/record.interface';
import { Router } from '@angular/router';
import { IRnumbList } from 'src/app/interfaces/rnumb.interface';
import { CalendarService } from 'src/app/services/calendar.service';
import { NewTalonComponentMobile } from 'src/app/modals/new-talon-mobile/talon.component';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-list-upcoming',
    templateUrl: './list-upcoming.component.html',
    styleUrls: ['./list-upcoming.component.scss'],
})
export class ListUpcomingComponent implements OnInit {
    @Input() rNumbs: IRnumbList[] = [];

    @Output() onDateGet = new EventEmitter<ICalendarDateActive[]>();
    @Input()
    set selectDate(value: string) {
        this._selectDate = value;
        this.setFilterDate(this._selectDate);
    }

    _selectDate: string = ''; // выбранная дата
    _rNumbList: IRnumbList[] = [];
    rNumbList: IRnumbList[] = [];
    @Input()
    set selectedDate(value: string) {
        this._selectedDate = value;
        this.setFilterDate(this._selectedDate);
        this.selectedDateInCalenadr = moment(value).format('DD MMMM');
    }
    get selectedDate() {
        return this._selectedDate;
    }
    _selectedDate: string = ''; // выбранная дата

    loadingRnumbList = false; // Загрузка талонов
    selectedDateInCalenadr: string = '';
    isLoading = false;
    loadedPages = 1;
    viewPage = 1;
    countOfPages = 1;
    rnumbPages: IRnumbList[][] = [];
    rnumbListLength: number = 0; // Количество записей
    loadingMoreContent = false;

    rnumbList: IRnumbList[] = [];
    filteredRnumbList: IRnumbList[] = [];
    closeResult: string = '';
    modalReference!: NgbModalRef;

    constructor(
        private rs: RnumbService,
        private dialog: MatDialog,
        private modalService: NgbModal,
        private configS: ConfigService,
        private auth: AuthService,
        private router: Router,
        private cs: CalendarService,
        private uploadService: FileUploadService
    ) {}

    ngOnInit(): void {
        //this.isLoading = true;
        this.getRnumbList().then(() => {
            this.isLoading = false;
        });
    }
    //бут модалка для теле мои посещения
    open(e: Event, content: any) {
        e.stopPropagation();

        this.modalReference = this.modalService.open(content, {
            windowClass: 'modal-window',
        });
        this.modalReference.result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    JoinAndClose() {
        this.modalReference.close();
    }

    // Переход на телевидеоКонференцию

    async getRnumbList() {
        let dt: ICalendarDateActive[] = [];
        this.rnumbList = [];
        this.filteredRnumbList = [];
        this.loadingRnumbList = true;
        try {
            // todo: нужно сначала переделать процедуру, чтобы она получала входные даты для фильтрации и пагинации. Как сделано на остальных страницах
            const res = await lastValueFrom(this.rs.getRnumbList());
            this.rnumbList = res;

            this.rnumbList.forEach((item) => {
                item.beginDate = strToDate(item.dat_bgn);
                item.endDate = strToDate(item.dat_end);

                dt.push({
                    date: Number(moment(item.beginDate).format('YYYYMMDD')),
                });
            });

            this.rnumbList.sort((a, b) => {
                return a.beginDate?.getTime()! - b.beginDate?.getTime()!;
            });

            // todo: избавиться от filteredRnumbList
            this.filteredRnumbList = this.rnumbList;
            this.rnumbPages = [this.filteredRnumbList];
            this.onDateGet.emit(dt);
        } catch (error) {
            dt.push({ date: Number(moment().format('YYYYMMDD')) });
            this.onDateGet.emit(dt);
        } finally {
            this.loadingRnumbList = false;
        }
    }

    getTime(dt: Date | undefined): string {
        if (dt && moment(dt).hour() > 3) {
            return moment(dt).format('HH:mm');
        } else {
            return '';
        }
    }

    getDate(dt: Date | undefined): string {
        if (dt) {
            return moment(dt).format('D MMMM YYYY');
        } else {
            return '';
        }
    }

    getTekDay(dt: Date | undefined): string {
        if (dt) {
            return moment(dt).format('D MMMM YYYY');
        } else {
            return '';
        }
    }

    getNameDay(dt: Date | undefined): string {
        if (dt) {
            let day = '';
            let d = 0;
            moment.locale('ru');
            d = moment().startOf('day').diff(moment(dt).startOf('day'), 'days');
            switch (d) {
                case 0: {
                    day = 'Сегодня';
                    break;
                }
                case 1: {
                    day = 'Завтра';
                    break;
                }
                default: {
                    day = getNameDay(dt);
                    break;
                }
            }
            return day;
        } else {
            return '';
        }
    }

    setFilterDate(dt: string) {
        if (dt.length == 0) {
            this.filteredRnumbList = this.rnumbList;
        } else {
            this.filteredRnumbList = this.rnumbList.filter(
                (item) => moment(item.beginDate).format('YYYY-MM-DD') === dt
            );
        }
    }

    // clikTalon(talon: IRnumbList) {
    //     if (talon.type_r == 'visit') {
    //         window.open(
    //             `${this.configS.getValue('hostBackend')}/history/visit/pdf/${
    //                 null
    //             }/${talon.rnumb_id}.pdf`,
    //             '_blank'
    //         );
    //     } else {
    //         if (talon.is_telemed) {
    //             this.goToTelemed(talon);
    //         } else {
    //             this.openTalon(talon);
    //         }
    //     }
    // }

    returnAllEvents() {
        this.getRnumbList();
    }

    openTalon(talon: IRnumbList, files?: FileList) {
        let dt: ITalonModal = {
            rnumbID: talon.rnumb_id,
            paramCansel: true,
            withUpload: Boolean(talon.is_upload_files),
            tag: talon.tag,
            link_id: talon.link_id,
            srv_text: talon.srv_text!,
            srv_description: talon.srv_description!,
        };

        if (files) dt.files = files;

        let dialogRefOptions = {
            width: 'auto',
            height: 'auto',
            maxWidth: 'auto',
            minWidth: 'auto',
            closeOnNavigation: true,
            data: dt,
        };

        // Check screen width and adjust dialog options if needed
        if (window.matchMedia('(max-width: 768px)').matches) {
            dialogRefOptions.width = '100vw';
            dialogRefOptions.maxWidth = '100vw';
            dialogRefOptions.minWidth = '100vw';
        }

        const dialogRef = this.dialog.open(
            NewTalonComponentMobile,
            dialogRefOptions
        );

        dialogRef.afterClosed().subscribe((result) => {
            // Отмена номерка
            if (result && result.rnumbID && result.canselRes) {
                this.getRnumbList(); // Обновляем все
            }
        });
    }

    stopPropagation(e: Event) {
        e.stopPropagation();
    }

    // открыть в новом окне ТЕЛЕМЕДИЦИНУ
    goToTelemed(rec: IRnumbList, e: MouseEvent) {
        e.stopPropagation();

        if (rec.url_telemed && rec.url_telemed.length > 5) {
            window.open(rec.url_telemed, '_blank');
        }
        // else {
        //     this.openTalon(rec);
        // }
    }

    onVisitProtocolClick(talon: IRnumbList, e: MouseEvent) {
        e.stopPropagation();

        this.router.navigate([
            `home/visit-protocol/${talon.visit_id}/${talon.form_result_id}`,
        ]);
    }

    onChangeInputUpload(event: Event, talon: IRnumbList) {
        const target = event.target as HTMLInputElement;
        const files = target.files;
        // console.log(target, files);
        if (!files) {
            console.log('NO FILES IN INPUT');
            return;
        }
        // console.log(files);
        this.openTalon(talon, files);
        target.value = '';
        // console.log(this.fileInput);
    }
}
