<div class="page-content">
    <div class="header__row">  
        <span class="header__label">Сервис</span>
        <button
          class="btn cls-btn-second cls-btn-filter"
          *ngFor="let fl of filterList" 
          (click)="selectFilter(fl)"     
          [ngClass]="fl.keyid == staticFilterSelected ? 'cls-btn-filter-active' : ''"
          [disabled]="loadingServices"
          
        >
          <span class="no-wrap">{{ fl.text }}</span> 
        </button>
      </div>
    
    <!-- //меню для телефона -->
      <div class="mobile-dropdown" *ngIf="!loadingServices"> 
        <button class='mobile-filter__button' (click)="toggleDropdown()" [ngClass]="{'border-active': staticFilterSelected}"> 
             <img src="./assets/img/svg/filter.svg" alt="filter" [ngClass]="{'border-active': staticFilterSelected}"/>
            </button> 
        <div *ngIf="isDropdownOpen" class="dropdown-menu">
            <app-filter-component
            (filterChanged)="onFilterChanged($event)"
            (closeFilter)="isDropdownOpen = false"
            [staticFilterSelected]="staticFilterSelected"
            [searchAmine]="searchAmine"
    
    ></app-filter-component>
    
        </div>
    </div>
    
    
    
    <div class="loader-container" *ngIf="loadingServices">
        <div *ngIf="loadingServices">
            <loader type="page"> </loader>
            <!-- <img src="./assets/loading-rec.gif" /> -->
        </div>
    </div>
    <div class="container" *ngIf="!loadingServices">
        <div class="filterbar" (click)="this.talonPickerAnime = 'off'">
            <div class="filter-wrapper">
                <input
                    type="search"
                    matInput
                    [(ngModel)]="filterSearchText"
                    placeholder="Выберите услугу"
                    [matAutocomplete]="filterSrvRef"
                    class="filter filterbar__filter"
                />
               
                <mat-autocomplete
                    #filterSrvRef
                    appendTo=".filterbar"
                    class="filter__dropdown custom-scrollbar"
                >
                    <mat-option
                        *ngFor="
                            let serv of services | searchFilter : filterSearchText
                        "
                        [value]="serv.text"
                        class="filter__option"
                        (click)="pickService(serv)"
                    >
                        <div class="filter__option-container">
                            <div class="filter__option-text">
                                <span>{{ serv.text }}</span>
                                <button
                                    *ngIf="serv.description || serv.srv_description"
                                    class="info-btn"
                                    (click)="openServModal(serv, $event)"
                                >
                                    <img src="./assets/img/svg/info.svg" />
                                </button>
                            </div>
                            <div
                                class="filter__option-icon"
                                *ngIf="serv.is_online_pay"
                            >
                                <img src="./assets/img/svg/rub.svg" alt="rub_svg" />
                            </div>
    
                            <div class="filter__option-price">
                                <span *ngIf="serv.price != 0"
                                    >{{ serv.price }} руб</span
                                >
                                <span *ngIf="serv.price == 0">Бесплатно</span>
                            </div>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>
        <div *ngIf="!loadingServices && services.length === 0"
        class="services__empty">Список пуст</div>
        <div class="doctors" *ngIf="showDoctors">
            <ng-container *ngFor="let doctor of doctors">
                <button class="doctors__item" (click)="pickDoctor(doctor)">
                    <div class="doctors__item-container">
                        <img
                            class="doctors__img"
                            src="{{ getDocImg(doctor.docid) }}"
                            (error)="getDocImgDefault($event)"
                            alt="doc"
                            width="68"
                            height="68"
                        />
                        <div class="doctors__item-info">
                            <div class="doctors__name">
                                {{ getShortName(doctor.text) }} 
                            </div>
                            <div class="doctors__spec">
                                <span>{{ doctor.specid_1 }} (специальность)</span> 
                            </div>
                        </div>
                    </div>
                </button>
            </ng-container>
        </div>
    
        <div
            *ngIf="showTalonPicker"
            class="cls-date-rnum"
            [@rightPanelAnimation]="talonPickerAnime"
            
        >
      
            <app-talonpicker [params]="paramsTalonPicker" (talonPickerClosed)="onTalonPickerClosed()"></app-talonpicker>
        </div>
    </div>
    </div>