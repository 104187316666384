import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
    FormControl,
    FormGroup,
    Validators,
    AbstractControl,
} from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { IStatementParam } from '../../interfaces/patient';
import { LoadService } from '../../services/application/load.service';
import { showOpacityDisplayAnimation } from '../../animations/show-left-right.animation';
import * as moment from 'moment';
import { AlertService } from 'src/lib/ariadna/alert';

@Component({
    selector: 'app-statement',
    templateUrl: './statement.component.html',
    styleUrls: ['./statement.component.scss'],
    animations: [showOpacityDisplayAnimation],
})
export class StatementComponent implements OnInit {
    public frmStatement!: FormGroup;
    public loading = false;
    public loadingFrm = false;
    public btn: number[] = [1, 0, 0, 0];
    public btnYearTxt: string[] = [];
    public patient1: boolean = false;
    public selectedYear: number | null = null;
    public selectedIndexYear: number | null = null;
    public maxDate = moment(new Date()).format('YYYY-MM-DD');
    public showFormOk = false;
    public INFO_HEADER_STATEMENT =
        'Информацию о готовности справки вы можете получить по телефону: 8 800 888 88 88';
    public INFO_BODY_STATEMENT = '';
    private _setting: any;
    public params: IStatementParam[] | null = null;

    constructor(
        public dialogRef: MatDialogRef<StatementComponent>,
        private patientS: PatientService,
        private load: LoadService,
        private alertService: AlertService
    ) {
        this._setting = load.configData.pages.personal;
        this.INFO_HEADER_STATEMENT =
            this._setting.WEB_LK_INFO_HEADER_STATEMENT ||
            this.INFO_HEADER_STATEMENT;
        this.INFO_BODY_STATEMENT =
            this._setting.WEB_LK_INFO_BODY_STATEMENT ||
            this.INFO_BODY_STATEMENT;
    }

    ngOnInit() {
        this.initialize();
    }

    private initialize(): void {
        this.btnYearTxt = [
            moment(new Date()).format('YYYY'),
            moment(new Date()).subtract(1, 'years').format('YYYY'),
            moment(new Date()).subtract(2, 'years').format('YYYY'),
            moment(new Date()).subtract(3, 'years').format('YYYY'),
        ];

        this.patientS.getStatementType$().subscribe(
            (info) => {
                this.params = info;
                this.initializeFrm();
            },
            (err) => {
                this.params = null;
            }
        );
    }

    private validateFIO(
        control: AbstractControl
    ): { [key: string]: any } | null {
        const fio = control.value;
        const firstCharIsValid = /^[a-zA-Zа-яА-Я']/.test(fio);
        const validChars = /^[a-zA-Zа-яА-Я'\-\s]+$/.test(fio);

        if (!firstCharIsValid || !validChars) {
            return { invalidFIO: true };
        }

        return null;
    }

    private initializeFrm(): void {
        this.frmStatement = new FormGroup({
            nalog_fio: new FormControl('', [
                Validators.minLength(3),
                this.validateFIO,
            ]),
            nalog_inn: new FormControl('', [Validators.minLength(12)]),
            patient_fio: new FormControl('', [
                Validators.minLength(3),
                this.validateFIO,
            ]),
            patient_dr: new FormControl('', [Validators.minLength(5)]),
            patient_fio1: new FormControl('', {
                validators: [Validators.minLength(3), this.validateFIO],
                updateOn: 'change',
            }),
            patient_dr1: new FormControl('', {
                validators: [Validators.minLength(5)],
                updateOn: 'change',
            }),
            is_recovery: new FormControl(false),
            dov_fio: new FormControl('', [
                Validators.minLength(3),
                this.validateFIO,
                Validators.required,
            ]),
            dov_phone: new FormControl('', [
                Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/),
                Validators.required,
            ]),
        });

        this.frmStatement
            .get('patient_fio1')
            ?.valueChanges.subscribe((value) => {
                if (value && value.trim().length > 0) {
                    this.frmStatement
                        .get('patient_fio1')
                        ?.setValidators([
                            Validators.minLength(3),
                            this.validateFIO,
                        ]);
                    this.frmStatement
                        .get('patient_fio1')
                        ?.updateValueAndValidity();
                } else {
                    this.frmStatement.get('patient_fio1')?.clearValidators();
                    // this.frmStatement
                    //     .get('patient_fio1')
                    //     ?.updateValueAndValidity();
                }
            });

        this.frmStatement
            .get('patient_dr1')
            ?.valueChanges.subscribe((value) => {
                if (value && value.trim().length > 0) {
                    this.frmStatement
                        .get('patient_dr1')
                        ?.setValidators([Validators.minLength(5)]);
                    this.frmStatement
                        .get('patient_dr1')
                        ?.updateValueAndValidity();
                } else {
                    this.frmStatement.get('patient_dr1')?.clearValidators();
                    // this.frmStatement
                    //     .get('patient_dr1')
                    //     ?.updateValueAndValidity();
                }
            });

        // Условие для отключения валидации для полей patient_dr1 и patient_fio1
        if (!this.patient1) {
            this.frmStatement.get('patient_fio1')?.clearValidators();
            this.frmStatement.get('patient_fio1')?.updateValueAndValidity();
            this.frmStatement.get('patient_dr1')?.clearValidators();
            this.frmStatement.get('patient_dr1')?.updateValueAndValidity();
        }

        this.loadingFrm = true;
    }

    public checkValue(value: string | null): boolean {
        if (value === null || value === 'null' || !value) return false;
        return true;
    }

    public addPatient(): void {
        this.patient1 = true;
    }

    public clicBtn(index: number): void {
        this.selectedIndexYear = index;
        this.btn = this.btn.map(() => 0);
        this.btn[index] = 1;
        this.selectedYear = +this.btnYearTxt[index];
        this.frmStatement.get('selectedYear')?.setValue(this.selectedYear);
    }

    public delPatient(n: number): void {
        this.patient1 = false;
    }

    public disableAddPatient(): boolean {
        return !(
            this.frmStatement.get('patient_fio')!.valid &&
            this.frmStatement.get('patient_dr')!.valid
        );
    }

    private generXml(): string {
        let r = '<root>';
        const xmlDate = Object.keys(this.frmStatement.value).map((key) => ({
            code: key.toUpperCase(),
            text: this.frmStatement.value[key],
        }));
        xmlDate.forEach((item) => {
            if (item.code === 'IS_RECOVERY') {
                item.text = item.text ? 'Да' : 'Нет';
            }
            r = `${r}<item><name>${item.code}</name><value>${item.text}</value></item>`;
        });

        const yearStr = this.btn
            .map((item, i) => (item ? this.btnYearTxt[i] : ''))
            .filter((year) => !!year)
            .join(';');

        if (yearStr.length > 2) {
            r = `${r}<item><name>YEARE</name><value>${yearStr}</value></item>`;
        }

        r = `${r}</root>`;
        return r;
    }

    counter = 0;

    public isFormValid(): boolean {
        return this.frmStatement.valid && this.selectedYear !== null;
    }

    public save(): void {
        if (this.isFormValid()) {
            this.loading = true;
            this.patientS.getStatementSave$(1, this.generXml()).subscribe(
                (info) => {
                    this.showFormOk = true;
                    this.loading = false;
                },
                (err) => {
                    this.loading = false;
                    this.alertService.error(
                        'Что-то пошло не так, попробуйте позже.',
                        5000
                    );
                }
            );
        }
    }

    public whenAnimate(event: any): void {}

    public numberOnly(event: any): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode === 48 && event.srcElement.value.length === 0) {
            return false;
        } else {
            return !(charCode > 31 && (charCode < 48 || charCode > 57));
        }
    }

    public validateDate(event: any): void {
        const inputDate = event.target.value;
        const currentDate = new Date();
        const selectedDate = new Date(inputDate);

        if (selectedDate > currentDate) {
            this.frmStatement
                .get('patient_dr')
                ?.setErrors({ invalidDate: true });
        } else {
            this.frmStatement.get('patient_dr')?.setErrors(null);
        }
    }
}
