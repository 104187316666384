import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITalonInfo } from '../interfaces/record.interface';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { IDocAboutInfo } from '../interfaces/doctor.interface';

@Injectable({
    providedIn: 'root',
})
export class DoctorService {
    constructor(private httpNew: HttpNewService, private auth: AuthService) {}

    /* Информация о враче */
    public getDocAboutInfo(doctorID: number): Observable<IDocAboutInfo[]> {
        let url = '/doctor/info';
        if (doctorID) {
            url = url + `?doctor_id=${doctorID}`;
        }
        return this.httpNew.get(url, null);
    }
}
