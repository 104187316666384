<ng-container *ngIf="signingDoc === false; then list; else sign"></ng-container>

<ng-template #sign>
    <div class="sign-contracts panel">
        <div class="pin">
            <h6>{{ contractName }}</h6>
            <div>
                Код подтверждения отправлен на номер
                <div>{{ maskedPhone }}</div>
            </div>
            <div #input_pin>
                <input
                    autocomplete="off"
                    type="tel"
                    name="pin-0"
                    id="pin-input-0"
                    maxlength="1"
                    [(ngModel)]="pin[0]"
                    (input)="moveToNext(0, $event)"
                    placeholder="*"
                    class="input"
                />
                <input
                    type="tel"
                    autocomplete="off"
                    name="pin-1"
                    id="pin-input-1"
                    ng-keydown="changeInput($event)"
                    maxlength="1"
                    [(ngModel)]="pin[1]"
                    (input)="moveToNext(1, $event)"
                    placeholder="*"
                    class="input"
                />
                <input
                    type="tel"
                    autocomplete="off"
                    name="pin-2"
                    id="pin-input-2"
                    maxlength="1"
                    [(ngModel)]="pin[2]"
                    (input)="moveToNext(2, $event)"
                    placeholder="*"
                    class="input"
                />
                <input
                    type="tel"
                    autocomplete="off"
                    name="pin-3"
                    id="pin-input-3"
                    maxlength="1"
                    [(ngModel)]="pin[3]"
                    (input)="moveToNext(3, $event)"
                    placeholder="*"
                    class="input"
                />
                <input
                    type="tel"
                    autocomplete="off"
                    name="pin-4"
                    id="pin-input-4"
                    maxlength="1"
                    [(ngModel)]="pin[4]"
                    (input)="moveToNext(4, $event)"
                    placeholder="*"
                    class="input"
                />
                <input
                    type="tel"
                    autocomplete="off"
                    name="pin-5"
                    id="pin-input-5"
                    maxlength="1"
                    [(ngModel)]="pin[5]"
                    (input)="moveToNext(5, $event)"
                    placeholder="*"
                    class="input"
                />
            </div>

            <div class="sign__buttons">
                <button
                    type="submit"
                    [style.pointerEvents]="
                        !isSubmitBtnDisabled ? 'none' : 'auto'
                    "
                    [style.opacity]="!isSubmitBtnDisabled ? '0.5' : '1'"
                    (click)="submitSmsCode()"
                >
                    Подтвердить
                </button>
                <button
                    [style.pointerEvents]="isButtonDisabled ? 'none' : 'auto'"
                    [style.opacity]="isButtonDisabled ? '0.5' : '1'"
                    type="button"
                    class="sign"
                    (click)="getSms()"
                >
                    {{
                        isButtonDisabled
                            ? "Повторить через " + countdown + " сек"
                            : "Отправить повторно"
                    }}
                </button>

                <button
                    type="button"
                    class="back-button"
                    (click)="backToList()"
                >
                    Отменить
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #list>
    <div class="contract__list">
        <div
            class="sign-contracts__container"
            *ngFor="let contract of payContracts"
        >
            <ng-container
                *ngIf="!!contract.text === true; then signed; else signing"
            ></ng-container>

            <ng-template #signed>
                <p>Подписан</p>
                <div class="contract__item">
                    <div class="contract__main">
                        <div class="contract__icon">
                            <img
                                [src]="'./assets/img/svg/account/dogovor.svg'"
                                alt="dogovor"
                            />
                        </div>
                        <div
                            class="contract__title"
                            (click)="openSignContract(contract.id)"
                        >
                            {{ contract.text }}
                        </div>
                        <div class="contract_dat">
                            {{ contract.dat }}
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #signing>
                <div class="contract__item">
                    <div class="contract__main">
                        <div class="contract__icon">
                            <img
                                [src]="'./assets/img/svg/account/dogovor.svg'"
                                alt="dogovor"
                            />
                        </div>
                        <div
                            class="contract__title"
                            (click)="openNotSignContract(contract.template_id)"
                        >
                            {{
                                contract.text
                                    ? contract.text
                                    : contract.template_name
                            }}
                        </div>
                    </div>

                    <div class="contract__button">
                        <button
                            class="sign"
                            [disabled]="
                                loading ||
                                !disablePay(
                                    contract.template_id || contract.id
                                ) ||
                                selectedContract !==
                                    (contract.template_id || contract.id)
                            "
                            (click)="signDoc(contract.template_id)"
                        >
                            Подписать
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-template>
