import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ServiceUnavailableComponent } from './service-unavailable/service-unavailable.component';

import { AuthenticatedRoutes } from './authenticated-routes';
import { UnauthenticatedRoutes } from './unauthenticated-routes';
import { loginGuard } from './guards/loginGuard';
import { NotFoundComponent } from './components/not-found/not-found.component';

const routes: Route[] = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [loginGuard],
        children: UnauthenticatedRoutes,
    },
    {
        path: 'home',
        component: HomeComponent,
        children: AuthenticatedRoutes,
    },
    {
        path: '503',
        component: ServiceUnavailableComponent,
    },
    { 
        path: '404', 
        component: NotFoundComponent 
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
