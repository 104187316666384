<div *ngIf="loading" class="loading">
    <loader type="page"> </loader>
    <!-- <img src="./assets/loading-rec.gif" /> -->
</div>

<div *ngIf="!loading" class="talonpicker">
    <mat-select
        [(value)]="selectedDep"
        placeholder="Выберите адрес"
        class="dep__select talonpicker__dep-select"
    >
        <mat-option
            [value]="null"
            (click)="selectDep(null)"
            class="dep-select__option"
            >Все</mat-option
        >
        <mat-option
            *ngFor="let dep of depList"
            [value]="dep.keyid"
            (click)="selectDep(dep)"
            class="dep-select__option"
        >
            {{ dep.text }}
        </mat-option>
    </mat-select>

    <mat-calendar
        *ngIf="talonListAll.length !== 0"
        class="calendar talonpicker__calendar"
        #calendar
        [dateFilter]="dateFilter"
        [(selected)]="selectedDay"
        (selectedChange)="selectDay($event)"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [headerComponent]="header"
    ></mat-calendar>

    <div class="record talonpicker__record">
        <div class="record-empty" *ngIf="talonListFiltered.length === 0">
            Нет доступных номерков
        </div>
        <h5 class="record__title" *ngIf="talonListFiltered.length !== 0">
            Вы записываетесь на {{ selectedDay?.format("DD MMMM") }}
        </h5>
        <div
            class="record-on-day"
            *ngIf="
                talonListFiltered.length !== 0 &&
                talonListFiltered[0].rnumbid === null &&
                talonListFiltered[0].interval_id === null
            "
        >
            <button
                class="record-on-day__btn"
                mat-flat-button
                (click)="openTalonModal($event, talonListFiltered[0])"
            >
                Продолжить
            </button>
        </div>

        <div
            class="talonlist record__talonlist"
            *ngIf="
                (talonListFiltered.length !== 0 &&
                    talonListFiltered[0].interval_id !== null) ||
                talonListFiltered[0].rnumbid !== null
            "
        >
            <div
                class="talon talonlist__item"
                *ngFor="let talon of talonListFiltered"
                (click)="openTalonModal($event, talon)"
            >
                {{ talon.dtBegin | date : "HH:mm" }}
            </div>
        </div>

        <button class="btn btn-dialog-close" (click)="closeCalendar()">
            Закрыть календарь
        </button>
    </div>
</div>
