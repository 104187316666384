import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IDoctor, IServ } from 'src/app/interfaces/record.interface';
import { ConfigService } from 'src/app/services/application/config.service.';

@Component({
    selector: 'app-service-modal',
    templateUrl: './service-modal.component.html',
    styleUrls: ['./service-modal.component.scss'],
})
export class ServModalComponent {
    serv!: IServ;

    constructor(
        private config: ConfigService,
        private activeModal: NgbActiveModal
    ) {}

    closeModal(e: Event) {
        e.stopPropagation();
        this.activeModal.close();
    }
}
