<div class="personal-data">
    <div class="personal-info panel" *ngIf="patient">
        <div *ngIf="loadingPatient" class="loading">
            <img src="./assets/loading-rec.gif" />
        </div>

        <div class="personal-info__header">
            <p class="personal-info__header-title">Личная информация</p>

            <button
                *ngIf="!isFirstPatientLogin && isChangePassword"
                class="personal-info__header-btn"
                type="submit"
                (click)="changePW()"
            >
                Сменить пароль
            </button>
            <button
                *ngIf="isFirstPatientLogin && isChangePassword"
                class="personal-info__header-btn"
                type="submit"
                (click)="createPW()"
            >
                Создать пароль
            </button>
        </div>

        <div class="personal-info__name">
            {{ patient.lastname }} {{ patient.firstname }}
            {{ patient.secondname }}
        </div>
        <div class="personal-info__address">
            <div class="personal-info__address-title">Адрес проживания</div>
            <div class="personal-info__address-text">
                {{ patient.address_proj | isNotDate }}
            </div>
        </div>
        <div class="personal-info__contacts">
            <div
                class="personal-info__email"
                (click)="openModal($event, EmailModal)"
            >
                <div class="personal-info__email-title">Email</div>
                <div
                    class="personal-info__email-text"
                    [class.link]="canChangeEmail"
                >
                    {{ patient.email | isNotDate }}
                </div>
            </div>
            <div class="personal-info__phone">
                <div class="personal-info__phone-title">Номер телефона</div>
                <div class="personal-info__phone-text">
                    {{ patient.cellular | isNotDate }}
                </div>
            </div>
        </div>

        <div class="personal-info__additional">
            <div class="personal-info__birthdate">
                <div class="personal-info__birthdate-title">Дата рождения</div>
                <div class="personal-info__birthdate-text">
                    {{ patient.birthdatestr | isNotDate }}
                </div>
            </div>
            <div class="personal-info__snils">
                <div class="personal-info__snils-title">СНИЛС</div>
                <div class="personal-info__snils-text">
                    {{ patient.snils | isNotDate }}
                </div>
            </div>
            <div class="personal-info__card-numb">
                <div class="personal-info__card-numb-title">
                    Номер карты ЛПУ
                </div>
                <div>{{ patient.num | isNotDate }}</div>
            </div>
        </div>

        <div class="personal-info__barcode">
            <barcode value="{{ patient.num }}"></barcode>
        </div>

        <div class="personal-info__footer">
            * Для изменения личной информации обратитесь в регистратуру
        </div>
    </div>

    <div class="patient-logs panel">
        <div *ngIf="loadingContracts" class="loading">
            <img src="./assets/loading-rec.gif" />
        </div>
        <div class="patient-logs__header">
            <div class="patient-logs__header-title">
                Активность пользователей
            </div>
            <div class="patient-logs__header-icon">
                <mat-icon
                    svgIcon="refresh"
                    class="cls-icon-refresh"
                    (click)="getPatientLog(1, patientLogsCountRectoPage)"
                ></mat-icon>
            </div>
        </div>
        <div class="patient-logs__body">
            <ng-container
                *ngIf="patientLogsCountRec > 0; then showLogs; else showNotRec"
            ></ng-container>

            <ng-template #showNotRec>
                <div class="cls-not-rec">Нет событий</div>
            </ng-template>
            <ng-template #showLogs>
                <div class="logs-table">
                    <p class="logs-table__date-title">Дата и время</p>
                    <p class="logs-table__action-title">Событие</p>

                    <ng-container *ngFor="let log of patientLogs">
                        <p class="logs__date">{{ log.dateNormalized }}</p>
                        <p class="logs__action">{{ log.text }}</p>
                        <ng-template #tipContent let-greeting="greeting"
                            >OS: {{ log.os }} Браузер: {{ log.browser }} IP
                            адрес:{{ log.ip_address }}</ng-template
                        >
                        <div class="logs__icon">
                            <mat-icon
                                svgIcon="logInfo"
                                placement="left"
                                [ngbTooltip]="tipContent"
                                tooltipClass="tooltipPanel"
                            ></mat-icon>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </div>

        <div class="patient-logs__footer">
            <app-pagination
                textItogo="активностей"
                [currentPage]="1"
                [countRecPage]="patientLogsCountRectoPage"
                [countRec]="patientLogsCountRec"
                (onChangedPage)="changedPage($event)"
            ></app-pagination>
        </div>
    </div>

    <div class="sign-contracts panel">
        <div *ngIf="loadingContracts" class="loading">
            <img src="./assets/loading-rec.gif" />
        </div>
        <div class="sign-contracts__header">
            <div class="sign-contracts__header-title">
                Подписанные документы
            </div>
            <div class="sign-contracts__header-icon">
                <mat-icon
                    svgIcon="refresh"
                    class="cls-icon-refresh"
                    (click)="getContractList()"
                ></mat-icon>
            </div>
        </div>
        <ng-container
            *ngIf="
                contractsCountRec > 0;
                then showTableContract;
                else showNotRecContract
            "
        ></ng-container>

        <ng-template #showNotRecContract>
            <div>Нет документов</div>
        </ng-template>

        <ng-template #showTableContract>
            <div
                class="sign-contracts__container"
                *ngFor="let contract of contracts"
                (click)="openContract(contract.id)"
            >
                <div class="contract">
                    <div class="contract__main">
                        <div class="contract__icon">
                            <mat-icon svgIcon="contract"></mat-icon>
                        </div>
                        <div class="contract__title">
                            {{ contract.text }}
                        </div>
                    </div>

                    <div class="contract__date">
                        {{ contract.dat }}
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <div class="panel unsign-contracts">
        <app-sms [mobilePhone]="mobilePhone" class="app-sms"></app-sms>
    </div>
</div>

<ng-template #EmailModal>
    <div mat-dialog-content class="email-modal">
        <button class="email-modal__close-btn" (click)="closeModal()">
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.6739 10L19.6439 17.97C20.1187 18.4447 20.1187 19.1943 19.6439 19.6439C19.1692 20.1187 18.4197 20.1187 17.97 19.6439L10 11.6739L2.03001 19.6439C1.55526 20.1187 0.805733 20.1187 0.35606 19.6439C-0.118687 19.1692 -0.118687 18.4197 0.35606 17.97L8.32605 10L0.35606 2.03001C-0.118687 1.55526 -0.118687 0.805733 0.35606 0.35606C0.830806 -0.118687 1.58034 -0.118687 2.03001 0.35606L10 8.32605L17.97 0.35606C18.4447 -0.118687 19.1943 -0.118687 19.6439 0.35606C20.1187 0.830806 20.1187 1.58034 19.6439 2.03001L11.6739 10Z"
                    fill="#B7C9DF"
                />
            </svg>
        </button>

        <div class="email-modal__title">Email</div>
        <input
            class="email-modal__input"
            type="text"
            placeholder="{{ patient.email }}"
            [(ngModel)]="newEmail"
        />
        <button class="email-modal__btn" (click)="saveEmail()">
            Сохранить
        </button>
    </div>
</ng-template>
