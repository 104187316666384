<div class="calendar__header">
    <button
        class="calendar__header-button"
        mat-icon-button
        (click)="previousClicked('month')"
    >
        <mat-icon svgIcon="arrow_left_old"></mat-icon>
    </button>
    <span class="calendar__header-label">{{periodLabel}}</span>
    <button
        class="calendar__header-button"
        mat-icon-button
        (click)="nextClicked('month')"
    >
        <mat-icon svgIcon="arrow_right_old"></mat-icon>
    </button>
</div>
