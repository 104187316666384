import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpNewService } from './application/http-new.service';
import { AuthService } from './auth.service';
import { dateToText } from '../components/application/global.function';
import { ILabOrder, ILabResearch, ILabSize } from '../interfaces/lab.interface';
import { IResearchFileList } from '../interfaces/laboratory-services.interface';

@Injectable({
    providedIn: 'root',
})
export class LabService {
    constructor(private httpNew: HttpNewService, private auth: AuthService) {}
    /**
     *
     * @param begin
     * @param end
     * @returns {Observable<any>}
     */
    public getLaboratoryOrderSize(
        beginDate: Date | null,
        endDate: Date | null
    ): Observable<ILabSize> {
        const param = new Array();

        if (beginDate) {
            param.push('beginDate=' + dateToText(new Date(beginDate)));
        }

        if (endDate) {
            param.push('endDate=' + dateToText(new Date(endDate)));
        }

        const pr = param.join('&');
        //TomCat    return this.httpNew.get(`/api/patient/${null}/laborder?beginDate=${begin}&endDate=${end}` , null)
        const url = '/labs/orders/size?' + pr;
        return this.httpNew.get(url, null);
    }

    /**
     *
     * @param begin
     * @param end
     * @param orderBy = acs / desc
     * @returns {Observable<any>}
     */
    public getLaboratoryOrderList(
        beginDate: Date | null,
        endDate: Date | null,
        pStart?: number,
        pEnd?: number,
        orderBy: string = 'acs'
    ): Observable<ILabOrder[]> {
        const param = new Array();

        param.push('orderby=' + orderBy);

        if (beginDate) {
            param.push('beginDate=' + dateToText(new Date(beginDate)));
        }

        if (endDate) {
            param.push('endDate=' + dateToText(new Date(endDate)));
        }
        if (pStart && pEnd) {
            param.push('start=' + pStart);
            param.push('end=' + pEnd);
        }

        const pr = param.join('&');
        //TomCat    return this.httpNew.get(`/api/patient/${null}/laborder?beginDate=${begin}&endDate=${end}` , null)
        const url = '/labs/orders?' + pr;
        return this.httpNew.get(url, null);
    }

    public getLaboratoryResearch(
        researchid: number
    ): Observable<ILabResearch[]> {
        const param = new Array();
        param.push('researchid=' + researchid);
        const pr = param.join('&');
        const url = '/labs/research?' + pr;
        return this.httpNew.get(url, null);
    }

    public getResearchFileList(
        researchid: number
    ): Observable<IResearchFileList[]> {
        return this.httpNew.get(
            `/labs/research/files?researchid=${researchid}`,
            null
        );
    }
}
