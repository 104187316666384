import { Injectable } from '@angular/core';
import { HttpNewService } from './application/http-new.service';
import { Observable } from 'rxjs';
import { IHttpRequest } from './application/IHttpRes.interface';

@Injectable({
    providedIn: 'root',
})
export class EsiaService {
    constructor(private httpNew: HttpNewService) {}

    /* Получение URLl ГосУслуг для авторизации на ГосУслугах */
    public getInfo$(): Observable<string> {
            return this.httpNew.get('/esia/info', null)
    }

    /* Получение информации по коду с ГосУслуг */
    public getInfoState$(state: string): Observable<IHttpRequest> {
            return this.httpNew.get('/esia/reg?state=' + state, null);
    }
}
