<section class="feedback">
    <h2>Свяжитесь с нами</h2>

    <div class="loading-group">
        <div class="loading" *ngIf="false">
            <!-- <img src="./assets/loading-rec.gif" /> -->
            <loader type="page"> </loader>
            <span>Отправляем обращение...</span>
        </div>

        <form
            (ngSubmit)="this.onSubmit()"
            [formGroup]="this.form"
            *ngIf="true"
            class="form"
        >
            <!-- Почта отправителя -->
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input
                    matInput
                    formControlName="email"
                    value="{{ patientEmail }}"
                />
            </mat-form-field>

            <!-- Тема сообщения -->
            <mat-form-field>
                <mat-select
                    placeholder="Выберите тип обращения"
                    formControlName="subject"
                >
                    <mat-option
                        *ngFor="let subject of subjectOptions"
                        [value]="subject"
                        >{{ subject.title }}</mat-option
                    >
                </mat-select>
            </mat-form-field>

            <!-- Подтема сообщения -->
            <mat-form-field
                *ngIf="
                    form.controls.subject.value &&
                    form.controls.subject.value.subthemes
                "
            >
                <mat-select
                    placeholder="Уточните тему"
                    formControlName="subsubject"
                >
                    <mat-option
                        *ngFor="
                            let subsubject of form.controls.subject.value
                                .subthemes
                        "
                        [value]="subsubject"
                    >
                        {{ subsubject }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <textarea
                    matInput
                    maxlength="1000"
                    #autosize="cdkTextareaAutosize"
                    cdkTextareaAutosize
                    formControlName="message"
                    placeholder="Сообщение"
                ></textarea>
            </mat-form-field>

            <button
                mat-raised-button
                type="submit"
                [disabled]="this.form.invalid"
                class="btn btn-primary"
            >
                <ng-container *ngIf="!loading">Отправить</ng-container>
                <ng-container *ngIf="loading"
                    ><div
                        class="btn-loader"
                        src="./assets/img/svg/loader.svg"
                    ></div
                ></ng-container>
            </button>
        </form>
    </div>
</section>
