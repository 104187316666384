import { Inject, Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '../services/store.service';
import { Observable, throwError } from 'rxjs';
import { CookieConsentService } from '../services/cookie-consent-service';
import { APP_BASE_HREF } from '@angular/common';

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
    constructor(
        @Inject(APP_BASE_HREF) private baseHref: string,
        private router: Router,
        private cookieService: CookieConsentService
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // console.log(request);
        request = request.clone({
            responseType:
                request.responseType === 'arraybuffer'
                    ? request.responseType
                    : 'text',
        });
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorObject = error.error;
                if (typeof errorObject === 'string') {
                    try {
                        errorObject = JSON.parse(error.error);
                    } catch (e) {
                        // console.error('JSON parsing error', e);
                        errorObject = {
                            msg: 'Не поддерживаемый формат ошибки.',
                        };
                    }
                }
                // console.log(error.status);
                if (error.status === 401) {
                    Store.clearAllStore();
                    // console.log(this.baseHref);
                    this.cookieService.deleteAll();
                    this.cookieService.deleteAll(
                        this.baseHref.replace(/\/$/, '')
                    );
                    if (!request.url.includes('logout')) {
                        this.router.navigate(['/login']);
                        // window.location.reload();
                    }
                }
                return throwError(() => errorObject || error);
            }),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const contentTypeHeader = event.headers
                        .get('Content-Type')
                        ?.split(';')[0];
                    const { body } = event;
                    if (
                        body &&
                        typeof body === 'string' &&
                        contentTypeHeader === 'application/json'
                    ) {
                        try {
                            const parsedBody = JSON.parse(body);
                            if (typeof parsedBody === 'object') {
                                return event.clone({
                                    body: parsedBody.data || parsedBody,
                                });
                            }
                        } catch (e) {
                            console.error('JSON parsing error', e);
                            return event;
                        }
                    }
                    return event;
                }
                return event;
            })
        );
    }
}
