<div *ngIf="loading" class="loading">
    <!-- <img src="./assets/loading-rec.gif" /> -->
    <loader type="page"> </loader>
</div>

<ng-container *ngIf="filesSection && !loading">
    <div class="cls-block-header header">
        <h2 class="header__title">Документы</h2>
    </div>
    <div class="wrapper">
        <div class="left h-100">
            <div class="head_file">
                <div>
                    Файл
                    <span class="left__header-icon">
                        <mat-icon
                            svgIcon="refresh"
                            color="#AAB5E1"
                            class="cls-icon-refresh"
                            (click)="getDocumentsList()"
                        ></mat-icon>
                    </span>

                    <!-- <div *ngIf="loading" class="loader">
                        <div class="loader__img"></div>
                    </div> -->
                </div>
                <p class="file-date-header">Дата Файла</p>
            </div>
            <div class="service-table__block-year"></div>
            <div class="table">
                <div *ngFor="let document of documentListOnPage; let i = index">
                    <div
                        href=".right h-100"
                        [ngClass]="{
                            document_card: i !== selectedIndex,
                            document_card_active: i === selectedIndex
                        }"
                        (click)="
                            load_document
                                ? null
                                : getDocument(
                                      $event,
                                      document.id,
                                      document.file_name,
                                      i
                                  )
                        "
                    >
                        <div class="document_title">
                            {{ document.file_name }}
                        </div>

                        <div class="document_date">
                            {{ document.dat_str }}
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="documentsList.length < 1 && !loading"
                    class="documents_not_found"
                >
                    <h3>Документы не найдены.</h3>
                </div>
            </div>
            <app-pagination
                textItogo="активностей"
                [currentPage]="1"
                [countRecPage]="8"
                [countRec]="documentsList.length"
                (onChangedPage)="changedPage($event)"
            ></app-pagination>
        </div>

        <div class="right h-100" *ngIf="documentsList.length > 0 && !loading">
            <div *ngIf="load_document" class="loader">
                <loader type="page"> </loader>
            </div>
            <div class="right_document">
                <img [src]="activeImgInBase64" *ngIf="activeImgInBase64" />
                <img
                    *ngIf="downloadLink && !activeImgInBase64"
                    src="./assets/img/svg/Group 2479.svg"
                />
                <div
                    *ngIf="downloadLink && !activeImgInBase64"
                    class="underSVG"
                >
                    Документ недоступен для предпросмотра
                </div>

                <div
                    *ngIf="downloadLink && !activeImgInBase64"
                    class="download_link"
                >
                    <a
                        [href]="downloadLink"
                        rel="noopener"
                        [download]="fileNameWithExt"
                        >Скачать
                    </a>
                </div>
                <div
                    *ngIf="
                        !activeImgInBase64 && !downloadLink && !load_document
                    "
                    class="notSelectedDocument"
                >
                    <p>Выберите документ.</p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
